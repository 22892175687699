/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useEffect, Suspense, useState } from 'react'
import { css, jsx } from '@emotion/react'

import {
  Link,
  unstable_useBlocker as useBlocker,
  useParams,
  useNavigate,
} from 'react-router-dom'
import countries from '@monorepo/countries/index.js'
import useLoggedInSession from '@monorepo/session/useLoggedInSession.js'
import { toastError } from '@monorepo/shared/AndroidToast.js'
import getCurrencyByCountry from '@monorepo/shared/currencies/getCurrencyByCountry.js'
import preferCapitalized from '@monorepo/shared/preferCapitalized.js'
import addOrUpdate from '@monorepo/tractor/addOrUpdate.js'
import Clinic from '@dododentist/model/Clinic.js'
import { useTranslation } from '@multilocale/react/index.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarButton from '@stiloso/components/AppBarButton.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import ButtonBar from '@stiloso/components/ButtonBar.js'
import CountryInput from '@stiloso/components/CountryInput.js'
import Dropdown from '@stiloso/components/Dropdown.js'
import ErrorBoundary from '@stiloso/components/ErrorBoundary.js'
import Input from '@stiloso/components/Input.js'
import OpeningHours from '@stiloso/components/OpeningHours.js'
import PhoneInput from '@stiloso/components/PhoneInput.js'
import Spinner from '@stiloso/components/Spinner.js'
import colMd6 from '@stiloso/styles/bootstrap/colMd6.js'
import colSm12 from '@stiloso/styles/bootstrap/colSm12.js'
import container from '@stiloso/styles/bootstrap/container.js'
import row from '@stiloso/styles/bootstrap/row.js'
import card from '@stiloso/styles/card.js'
import clickable from '@stiloso/styles/clickable.js'
import label from '@stiloso/styles/label.js'
import ClinicRemindersSection from '../components/ClinicRemindersSection.js'
import Layout from '../components/Layout.js'
import SaveChangesPrompt from '../dialogs/SaveChangesPrompt.js'
import useClinic from '../hooks/useClinic.js'
import useSeats from '../hooks/useSeats.js'

export const paths = ['/clinics/:clinicId']

const ClinicPage = props => {
  const { clinic, seats } = props
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [edited, setEdited] = useState(false)
  const [isLoading, setLoading] = useState(false)
  let [name, setName] = useState('')
  let [phone, setPhone] = useState('')
  let [phoneCopiedFromUser, setPhoneCopiedFromUser] = useState(false)
  let [street, setStreet] = useState('')
  let [postalCode, setPostalCode] = useState('')
  let [city, setCity] = useState('')
  let [state, setState] = useState('')
  let [countryCode, setCountryCode] = useState('')
  let [currency, setCurrency] = useState('')
  let [firstDayOfTheWeek, setFirstDayOfTheWeek] = useState('')
  let [openingHours, setOpeningHours] = useState([])
  let country = countries[countryCode] || {}
  const {
    administrativeDivisionsNames,
    administrativeDivisionsType,
    zipcodePattern,
  } = country

  let firstDayOfTheWeekValues = ['Monday', 'Saturday', 'Sunday']
  let firstDayOfTheWeekLabels = firstDayOfTheWeekValues.map(day => t(day))

  const changeName = event => {
    setEdited(true)
    setName(event.target.value)
  }

  const changePhone = phone => {
    setEdited(true)
    setPhone(phone)
    setPhoneCopiedFromUser(false)
  }

  const changeStreet = event => {
    setEdited(true)
    setStreet(event.target.value)
  }

  const changePostalCode = event => {
    setEdited(true)
    setPostalCode(event.target.value)
  }

  const changeCity = event => {
    setEdited(true)
    setCity(event.target.value)
  }

  const changeState = state => {
    setEdited(true)
    setState(state)
  }

  const changeCountryCode = countryCode => {
    setEdited(true)
    setCountryCode(countryCode)
  }

  const changeFirstDayOfTheWeek = firstDayOfTheWeek => {
    setEdited(true)
    setCountryCode(firstDayOfTheWeek)
  }

  const changeOpeningHours = openingHours => {
    openingHours = openingHours?.map(openingHour => {
      if (openingHour?.demo) {
        openingHour.demo = false
      }

      return openingHour
    })
    setEdited(true)
    setOpeningHours(openingHours)
  }

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      edited && currentLocation.pathname !== nextLocation.pathname,
  )

  useEffect(() => {
    if (!edited) {
      blocker?.proceed?.()
    }
  }, [edited])

  const addOrUpdateClinic = async event => {
    event?.preventDefault?.()

    name = preferCapitalized(name)
    phone = phone?.replace(/[ \\(\\)\\-]/g, '')
    phone = phone?.length > 4 ? phone : null

    let newClinic = new Clinic({
      ...clinic,
      lastEditTime: new Date().toISOString(),
      name,
      phone,
      street,
      postalCode,
      city,
      state,
      countryCode,
      currency,
      firstDayOfTheWeek,
      openingHours,
      phoneCopiedFromUser,
    })

    setLoading(true)
    await addOrUpdate(newClinic, clinic)
      .then(() => {
        setLoading(false)
        setEdited(false)
        navigate(`/clinics/${newClinic._id}`)
      })
      .catch(error => {
        setLoading(false)
        setEdited(false)
        toastError(t(error))
      })
  }

  let closePrompt = () => {
    blocker.reset()
  }

  let discardChangesPrompt = () => {
    blocker.proceed()
  }

  let saveChangesPrompt = async () => {
    await addOrUpdateClinic()
    blocker.proceed()
  }

  useEffect(() => {
    if (clinic) {
      setName(clinic.name)
      setStreet(clinic.street)
      setPostalCode(clinic.postalCode)
      setCity(clinic.city)
      setState(clinic.state)
      setCountryCode(clinic.countryCode)
      setFirstDayOfTheWeek(clinic.firstDayOfTheWeek || 'Monday')
      setOpeningHours(clinic.openingHours || [])
      let clinicPhone = clinic.phone || ''
      if (!clinicPhone.includes('+') && clinic.countryCode) {
        let prefix = countries[clinic.countryCode]?.phonePrefix || ''
        clinicPhone = prefix + clinicPhone
      }
      setPhone(clinicPhone)
      setPhoneCopiedFromUser(clinic.phoneCopiedFromUser)
      let currency = clinic.currency
      if (!currency && clinic.countryCode) {
        currency = getCurrencyByCountry(clinic.countryCode)
      }
      setCurrency(currency)
    }
  }, [clinic])

  return (
    <form onSubmit={addOrUpdateClinic} disabled>
      <div css={container}>
        <div css={row}>
          <div css={[colSm12, colMd6]}>
            <AppBar>
              <AppBarTitle>{t('Clinic')}</AppBarTitle>
            </AppBar>

            <div css={[card, { marginBottom: 16 }]}>
              <Input
                label={t('name')}
                onChange={changeName}
                value={name}
                required
                minLength="1"
                autoFocus
              />
              <PhoneInput
                label={t('phone')}
                phone={phone}
                onChange={changePhone}
                required
              />
              {clinic.countryCode === 'US' && (
                <Dropdown
                  name="First day of the week"
                  labels={firstDayOfTheWeekLabels}
                  values={firstDayOfTheWeekValues}
                  currentValue={firstDayOfTheWeek}
                  onSelect={changeFirstDayOfTheWeek}
                />
              )}
              <label htmlFor="openingHoursInput" css={label}>
                {t('Opening hours')}
                <OpeningHours
                  firstDayOfTheWeek={firstDayOfTheWeek}
                  openingHours={openingHours}
                  onChange={changeOpeningHours}
                />
              </label>
            </div>
            <ClinicRemindersSection
              clinicId={clinic._id}
              addOrUpdate={addOrUpdateClinic}
            />
          </div>
          <div css={[colSm12, colMd6]}>
            <div css={{ marginTop: 16 }}>
              <AppBar>
                <AppBarTitle>{t('Seats')}</AppBarTitle>
                <div css={{ flexGrow: 1 }} />
                <AppBarButton
                  appearance="secondary"
                  label={t('New seat')}
                  to={`/clinics/${clinic._id}/seats/new`}
                />
              </AppBar>
              {seats.length > 0 && (
                <div css={{ marginBottom: 16 }}>
                  {seats?.map(({ _id, name }) => (
                    <Link
                      key={_id}
                      value={name}
                      css={[
                        clickable,
                        card,
                        {
                          lineHeight: '48px',
                          width: '100%',
                          padding: '0 16px',
                          marginBottom: 8,
                        },
                      ]}
                      to={`/clinics/${clinic._id}/seats/${_id}`}
                    >
                      {name || _id}
                    </Link>
                  ))}
                </div>
              )}
            </div>
            <AppBar>
              <AppBarTitle>{t('Address')}</AppBarTitle>
            </AppBar>
            <div css={[card, { marginBottom: 16 }]}>
              <CountryInput
                country={countryCode}
                onChange={changeCountryCode}
              />
              <Input
                label={t('street')}
                onChange={changeStreet}
                value={street}
              />
              {zipcodePattern && (
                <Input
                  label={t('zipcode')}
                  onChange={changePostalCode}
                  value={postalCode}
                />
              )}
              <Input label={t('city')} onChange={changeCity} value={city} />
              {administrativeDivisionsType && (
                <Dropdown
                  name={t(administrativeDivisionsType)}
                  labels={administrativeDivisionsNames}
                  values={administrativeDivisionsNames}
                  currentValue={state}
                  onSelect={changeState}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {edited && (
        <ButtonBar appearance="secondary" type="submit" isLoading={isLoading}>
          {t('Save')}
        </ButtonBar>
      )}
      {blocker?.state === 'blocked' && (
        <SaveChangesPrompt
          close={closePrompt}
          discardChanges={discardChangesPrompt}
          saveChanges={saveChangesPrompt}
        />
      )}
    </form>
  )
}

const ClinicPageConnected = () => {
  useLoggedInSession()
  const { clinicId } = useParams()
  let clinic = useClinic(clinicId)
  let seats = useSeats({
    clinicId: clinic._id,
  })

  return <ClinicPage clinic={clinic} seats={seats} />
}

const ClinicPageWrapper = () => {
  const { t } = useTranslation()
  return (
    <Layout title={t('Settings')}>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <ClinicPageConnected />
        </Suspense>
      </ErrorBoundary>
    </Layout>
  )
}

export default ClinicPageWrapper
