/* Copyright 2013 - 2024 Waiterio LLC */
import React, { useEffect, useState } from 'react'
import getCountry from '@multilocale/client/getCurrentCountry.js'

export default function useCountry() {
  let [country, setCountry] = useState({})

  useEffect(() => {
    async function fetchCountry() {
      let country = await getCountry().catch(error => {
        // DO NOTHING MORE THAN LOGGING THE ERROR
        console.error(error)
      })
      setCountry(country)
    }

    fetchCountry()
  }, [])

  return country
}
