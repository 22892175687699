/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { Suspense } from 'react'
import { css, jsx } from '@emotion/react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  addDays,
  addMonths,
  endOfWeek,
  format,
  formatISO,
  getDayOfYear,
  getMonth,
  setMonth,
  setYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfToday,
  subMonths,
} from 'date-fns'
import useLoggedInSession from '@monorepo/session/useLoggedInSession.js'
import getDayOfTheWeekForDateFns from '@monorepo/shared/getDayOfTheWeekForDateFns.js'
import getWeekedays from '@monorepo/shared/getWeekdays.js'
import months from '@monorepo/shared/months.js'
import { useTranslation } from '@multilocale/react/index.js'
import ErrorBoundary from '@stiloso/components/ErrorBoundary.js'
import ImageGraceful from '@stiloso/components/ImageGraceful.js'
import Spinner from '@stiloso/components/Spinner.js'
import IconLeft from '@stiloso/icons/IconLeft.js'
import IconRight from '@stiloso/icons/IconRight.js'
import colMd4 from '@stiloso/styles/bootstrap/colMd4.js'
import colSm12 from '@stiloso/styles/bootstrap/colSm12.js'
import row from '@stiloso/styles/bootstrap/row.js'
import clickable from '@stiloso/styles/clickable.js'
import Layout from '../components/Layout.js'
import WeekMonthSwitch from '../components/WeekMonthSwitch.js'
import useAppointments from '../hooks/useAppointments.js'
import useClinic from '../hooks/useClinic.js'
import usePatients from '../hooks/usePatients.js'
import useUsers from '../hooks/useUsers.js'

export const paths = [
  '/clinics/:clinicId/calendar',
  '/clinics/:clinicId/calendar/:monthDashYear',
]

const avatarContainer = css`
  ${clickable}
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  min-width: 32px;
  border-radius: 32px;
`

const CalendarPage = props => {
  let { appointments, clinic, month, patients, users, year } = props
  clinic ||= {}
  month &&= month.charAt(0).toUpperCase() + month.slice(1)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { openingHours } = clinic

  const weekdays = getWeekedays(clinic.firstDayOfTheWeek)

  let openDays = {}

  if (
    openingHours?.length >= 1 &&
    openingHours[0].day &&
    openingHours[0].open &&
    openingHours[0].close
  ) {
    appointments?.forEach(appointment => {
      let dayOfTheWeek = format(new Date(appointment.startTime), 'EEEE')
      openDays[dayOfTheWeek] = true
    })

    openingHours?.forEach(openingHour => {
      if (openingHour.open && openingHour.close) {
        let dayOfTheWeek = openingHour.day
        openDays[dayOfTheWeek] = true
      }
    })
  } else {
    weekdays.forEach(dayOfTheWeek => {
      openDays[dayOfTheWeek] = true
    })
  }

  // console.log({ openDays })

  let openWeekdays = weekdays.filter(dayOfTheWeek => openDays[dayOfTheWeek])

  // console.log({ openWeekdays })

  let date = new Date()

  if (month && year) {
    date = setYear(setMonth(date, months.indexOf(month)), year)
  }

  let firstDayOfMonth = startOfMonth(date)

  // console.log({ firstDayOfMonth })
  while (!openWeekdays.includes(format(firstDayOfMonth, 'EEEE'))) {
    firstDayOfMonth = addDays(firstDayOfMonth, 1)
  }
  // console.log({ firstDayOfMonth })

  month = months[getMonth(firstDayOfMonth)]

  let ids2patients = patients?.reduce((ids2patients, patient) => {
    ids2patients[patient._id] = patient

    return ids2patients
  }, {})

  let ids2usersColors = users?.reduce((ids2usersColors, user) => {
    ids2usersColors[user._id] = user.color

    return ids2usersColors
  }, {})

  appointments = appointments?.map(appointment => ({
    ...appointment,
    patient: ids2patients[appointment.patientId],
  }))

  // console.log({ appointments })

  let days2appointments = appointments.reduce(
    (days2appointments, appointment) => {
      try {
        let day = formatISO(startOfDay(appointment.startTime))

        if (!days2appointments[day]) {
          days2appointments[day] = []
        }

        days2appointments[day].push(appointment)
      } catch (error) {
        console.error(error)
      }

      return days2appointments
    },
    {},
  )

  // console.log(days2appointments)

  let weekStartsOn = getDayOfTheWeekForDateFns(clinic.firstDayOfTheWeek)

  let firstDayOfCalendarPage = startOfWeek(firstDayOfMonth, {
    weekStartsOn,
  })

  while (!openWeekdays.includes(format(firstDayOfCalendarPage, 'EEEE'))) {
    firstDayOfCalendarPage = addDays(firstDayOfCalendarPage, 1)
  }

  // console.log(firstDayOfCalendarPage)

  let d = firstDayOfCalendarPage
  let days = []

  let currentMonth = getMonth(firstDayOfMonth)
  let currentDayOfYear = getDayOfYear(startOfToday())

  let monthOfTheFirstDayOfWeek
  let monthOfTheLastDayOfWeek

  do {
    try {
      let dayOfTheWeek = format(d, 'EEEE')
      if (openDays[dayOfTheWeek]) {
        let isCurrentMonth = getMonth(d) === currentMonth
        let isToday = getDayOfYear(d) === currentDayOfYear

        days.push({
          appointments: days2appointments[formatISO(d)],
          day: d,
          isCurrentMonth,
          isToday,
        })
      }
    } catch (error) {
      console.error(error)
    }

    d = addDays(d, 1)
    monthOfTheFirstDayOfWeek = getMonth(startOfWeek(d, { weekStartsOn }))
    monthOfTheLastDayOfWeek = getMonth(endOfWeek(d, { weekStartsOn }))
  } while (
    monthOfTheFirstDayOfWeek === currentMonth ||
    monthOfTheLastDayOfWeek === currentMonth
  )

  let changeToPreviousMonth = () => {
    let monthDashYear = format(
      subMonths(firstDayOfMonth, 1),
      'MMMM-yyyy',
    ).toLowerCase()
    navigate(`/clinics/${clinic._id}/calendar/${monthDashYear}`)
  }

  let changeToNextMonth = () => {
    let monthDashYear = format(
      addMonths(firstDayOfMonth, 1),
      'MMMM-yyyy',
    ).toLowerCase()
    navigate(`/clinics/${clinic._id}/calendar/${monthDashYear}`)
  }

  let weeksInMonthCount = days.length / openWeekdays.length
  let weeksInMonth = Array.from({ length: weeksInMonthCount }, (_, i) => i)

  return (
    <div
      css={{ height: '100%', width: '100%', padding: 16, overflowX: 'auto' }}
    >
      <div
        css={{
          fontSize: 32,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 8,
          direction: 'ltr',
        }}
      >
        <div
          css={[
            clickable,
            {
              borderRadius: 24,
              width: 48,
              height: 48,
              marginBottom: 8,
            },
          ]}
          onClick={changeToPreviousMonth}
        >
          <IconLeft css={{ margin: 12 }} />
        </div>
        <div css={[row, { width: '100%' }]}>
          <div
            css={[
              colSm12,
              colMd4,
              {
                flexGrow: 1,
                display: 'flex',
                gap: 8,
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: 8,
              },
            ]}
          >
            {users.map(user =>
              user.url ? (
                <div key={user._id} css={avatarContainer}>
                  <ImageGraceful
                    css={{
                      aspectRatio: '1 / 1',
                      height: 32,
                      borderRadius: 1000,
                    }}
                    src={user.url.replace('.jpg', '-256w.webp')}
                    fallbackSrc={user.url.replace('.jpg', '-256w.jpg')}
                    onClick={() => navigate(`/team/${user._id}`)}
                  />
                </div>
              ) : (
                <div
                  key={user._id}
                  css={[
                    avatarContainer,
                    {
                      color: 'white',
                      fontSize: 20,
                      background: user.color,
                      textTransform: 'capitalize',
                    },
                  ]}
                  onClick={() => navigate(`/team/${user._id}`)}
                >
                  {user.getInitial()}
                </div>
              ),
            )}
          </div>
          <div
            css={[
              colSm12,
              colMd4,
              {
                flexGrow: 1,
                textAlign: 'center',
                marginBottom: 8,
                textTransform: 'capitalize',
              },
            ]}
          >
            {t(month)}
          </div>
          <div
            css={[
              colSm12,
              colMd4,
              {
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: 8,
              },
            ]}
          >
            <WeekMonthSwitch value="month" />
          </div>
        </div>
        <div
          css={[
            clickable,
            {
              borderRadius: 24,
              width: 48,
              height: 48,
              marginBottom: 8,
            },
          ]}
          onClick={changeToNextMonth}
        >
          <IconRight css={{ margin: 12 }} />
        </div>
      </div>
      <table css={{ minHeight: 'calc(100vh - 96px)', width: '100%' }}>
        <thead>
          <tr>
            {openWeekdays.map(dayOfTheWeek => (
              <th
                key={dayOfTheWeek}
                css={{ fontSize: 12, padding: 4, textTransform: 'uppercase' }}
              >
                {t(dayOfTheWeek)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {weeksInMonth.map(i => (
            <tr key={i}>
              {openWeekdays.map((weekday, j) => {
                let { appointments, day, isCurrentMonth, isToday } =
                  days[i * openWeekdays.length + j]
                let background = 'white'
                if (isToday) {
                  background = '#FFFFE7'
                } else if (!isCurrentMonth) {
                  background = 'var(--color-background)'
                }
                return (
                  <td
                    key={day.toISOString()}
                    css={[
                      clickable,
                      {
                        color: 'var(--color-base)',
                        background,
                        border: '1px solid var(--color-base)',
                        padding: 16,
                      },
                    ]}
                    onClick={() =>
                      navigate(
                        `/clinics/${clinic._id}/appointments/new?date=${format(day, 'yyyy-MM-dd')}`,
                      )
                    }
                  >
                    <div css={{ display: 'flex', flexDirection: 'column' }}>
                      <div css={{ marginBottom: 4 }}>{day.getDate()}</div>
                      {appointments?.map(appointment => (
                        <div
                          key={appointment._id}
                          onClick={event => {
                            event.stopPropagation()
                            navigate(
                              `/clinics/${clinic._id}/appointments/${appointment._id}`,
                            )
                          }}
                          css={[
                            clickable,
                            {
                              borderRadius: 16,
                              background:
                                ids2usersColors[appointment?.usersIds?.[0]] ||
                                'var(--color-primary)',
                              color: 'white',
                              fontSize: 12,
                              padding: '6px 8px',
                              marginTop: 4,
                              ':hover:not(:has(div:hover))': {
                                background: 'var(--color-primary-lighter)',
                              },
                            },
                          ]}
                        >
                          {format(appointment.startTime, 'HH:mm')}&nbsp;&nbsp;
                          {appointment.patient?.name || ''}
                        </div>
                      ))}
                    </div>
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
      <div css={{ height: 16 }} />
    </div>
  )
}

const CalendarPageConnected = () => {
  useLoggedInSession()
  let { clinicId, monthDashYear } = useParams()
  let clinic = useClinic(clinicId)
  let appointments = useAppointments({ clinicId: clinic._id })

  appointments = appointments?.sort(
    (a, b) => new Date(a?.startTime) - new Date(b?.startTime),
  )

  // console.log(patientsIds)

  let patients = usePatients({
    clinicId: clinic._id,
  })

  let users = useUsers()

  monthDashYear = monthDashYear && monthDashYear.toLowerCase()
  let [month, year] = monthDashYear?.split('-') || []

  // console.log({ patients })

  return (
    <CalendarPage
      appointments={appointments}
      clinic={clinic}
      patients={patients}
      month={month}
      users={users}
      year={year}
    />
  )
}

const CalendarPageWrapper = () => {
  useLoggedInSession()
  const { t } = useTranslation()

  return (
    <Layout title={t('Calendar')}>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <CalendarPageConnected />
        </Suspense>
      </ErrorBoundary>
    </Layout>
  )
}

export default CalendarPageWrapper
