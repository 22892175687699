/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'

const IconStar = props => {
  const { className, width = 48, height = 48, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-star'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 22 24"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="M22.296 8.664q0 0.288-0.36 0.648l-4.848 4.728 1.152 6.696q0 0.096 0 0.288 0 0.264-0.144 0.456t-0.408 0.216q-0.24 0-0.528-0.168l-6.024-3.168-6 3.168q-0.288 0.168-0.552 0.168t-0.408-0.216-0.144-0.456q0-0.096 0.024-0.288l1.152-6.696-4.872-4.728q-0.336-0.36-0.336-0.648 0-0.504 0.744-0.624l6.72-0.96 3.024-6.096q0.264-0.552 0.648-0.552t0.672 0.552l3 6.096 6.72 0.96q0.768 0.12 0.768 0.624z" />
    </svg>
  )
}

export default IconStar
