/* Copyright 2013 - 2024 Waiterio LLC */

import Appointment from './Appointment.js'
import Clinic from './Clinic.js'
import Image from './Image.js'
import Invite from './Invite.js'
import Invoice from './Invoice.js'
import Organization from './Organization.js'
import Patient from './Patient.js'
import Payment from './Payment.js'
import Procedure from './Procedure.js'
import Reminder from './Reminder.js'
import Role from './Role.js'
import Seat from './Seat.js'
import Treatment from './Treatment.js'
import User from './User.js'

const classes = [
  Appointment,
  Clinic,
  Reminder,
  Image,
  Invite,
  Invoice,
  Organization,
  Patient,
  Payment,
  Procedure,
  Role,
  Seat,
  Treatment,
  User,
].map(class_ => (class_.default ? class_.default : class_))

export default classes
