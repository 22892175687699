/* Copyright 2013 - 2024 Waiterio LLC */

const getTotalPriceForProcedures = procedures => {
  let total = 0

  procedures?.forEach(procedure => {
    if (procedure.price) {
      try {
        total += parseFloat(procedure.price, 10)
      } catch (e) {
        console.error(
          `Error parsing price: ${procedure.price} for procedure: ${procedure._id}`,
        )
      }
    }
  })

  return total
}

export default getTotalPriceForProcedures
