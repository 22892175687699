/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
/* eslint-disable camelcase */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { useLocation, useNavigate } from 'react-router-dom'
import getClassMany from '@monorepo/client/getClassMany.js'
import subscribesPusher from '@monorepo/services/subscribesPusher.js'
import storeNewSession from '@monorepo/session/storeNewSession.js'
import getCookie from '@monorepo/shared/getCookie.js'
import getNameFromEmail from '@monorepo/shared/getNameFromEmail.js'
import fetchAllData from '@monorepo/tractor/fetchAllData.js'
import classes from '@dododentist/model/classes.js'
import Clinic from '@dododentist/model/Clinic.js'
import IconBrand from '@dododentist/shared/IconBrand.js'
import IllustrationDentist from '@dododentist/shared/IllustrationDentist.js'
import pusherAppKey from '@dododentist/shared/pusherAppKey.js'
import SignupPage from '@stiloso/pages/SignupPage.js'
import identify from '../identify.js'
import postSignup from '../postSignup.js'

export const paths = ['/signup']

function removeEmptyFields(object) {
  return Object.fromEntries(
    Object.entries(object).filter(([_, v]) => v != null),
  )
}

const SignupPageWrapper = () => {
  const navigate = useNavigate()
  let location = useLocation()
  let urlSearchParams = new URLSearchParams(location.search)
  let email = urlSearchParams.get('email')
  let password = urlSearchParams.get('password')
  let domain = urlSearchParams.get('domain')
  let gclidFromUrl = urlSearchParams.get('gclidFromUrl')
  let gclidFromCookie = getCookie('gclid')
  let referrer = getCookie('referrer')
  let utm_campaign = getCookie('utm_campaign')
  let utm_device = getCookie('utm_device')
  let utm_source = getCookie('utm_source')
  let utm_term = getCookie('utm_term')
  let gclid = gclidFromCookie || gclidFromUrl

  const signup = async ({ email, password, language, phone, country }) => {
    let { firstName, lastName } = getNameFromEmail(email)
    let signupResponse = await postSignup({
      email,
      password,
      phone,
      firstName,
      lastName,
      country,
      language,
      gclid,
      referrer,
      utm_campaign,
      utm_device,
      utm_source,
      utm_term,
    })
    await storeNewSession(signupResponse)
    await fetchAllData(classes)
    await identify()
    subscribesPusher({ classes, pusherAppKey })

    if (signupResponse.newOrganization) {
      navigate(
        '/setup-clinic?' +
          new URLSearchParams(removeEmptyFields({ domain })).toString(),
      )
    } else {
      const clinics = await getClassMany(Clinic)

      if (clinics.length === 1) {
        navigate(`/clinics/${clinics[0]._id}/calendar`)
      } else {
        navigate('/clinics')
      }
    }
  }

  let brand = <IconBrand width={36} height={36} fontSize={36} />

  return (
    <SignupPage
      brand={brand}
      signup={signup}
      email={email}
      password={password}
      illustration={<IllustrationDentist />}
    />
  )
}

export default SignupPageWrapper
