/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { Suspense } from 'react'
import { css, jsx } from '@emotion/react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  addDays,
  addMinutes,
  endOfDay,
  format,
  formatISO,
  getMonth,
  isAfter,
  isBefore,
  isEqual,
  setDate,
  setDay,
  setHours,
  setMinutes,
  setMonth,
  setYear,
  startOfWeek,
  subDays,
} from 'date-fns'
import getDayOfTheWeekForDateFns from '@monorepo/shared/getDayOfTheWeekForDateFns.js'
import getWeekedays from '@monorepo/shared/getWeekdays.js'
import months from '@monorepo/shared/months.js'
import { useTranslation } from '@multilocale/react/index.js'
import ErrorBoundary from '@stiloso/components/ErrorBoundary.js'
import ImageGraceful from '@stiloso/components/ImageGraceful.js'
import Spinner from '@stiloso/components/Spinner.js'
import IconLeft from '@stiloso/icons/IconLeft.js'
import IconRight from '@stiloso/icons/IconRight.js'
import colMd4 from '@stiloso/styles/bootstrap/colMd4.js'
import colSm12 from '@stiloso/styles/bootstrap/colSm12.js'
import row from '@stiloso/styles/bootstrap/row.js'
import clickable from '@stiloso/styles/clickable.js'
import singleLine from '@stiloso/styles/singleLine.js'
import Layout from '../components/Layout.js'
import WeekMonthSwitch from '../components/WeekMonthSwitch.js'
import useAppointments from '../hooks/useAppointments.js'
import useClinic from '../hooks/useClinic.js'
import usePatients from '../hooks/usePatients.js'
import useSeats from '../hooks/useSeats.js'
import useUsers from '../hooks/useUsers.js'
import useLoggedInSession from '@monorepo/session/useLoggedInSession.js'

export const paths = [
  '/clinics/:clinicId/agenda',
  '/clinics/:clinicId/agenda/:yearDashMonthDashDay',
]

const avatarContainer = css`
  ${clickable}
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  min-width: 32px;
  border-radius: 32px;
`

const AgendaPage = props => {
  let { appointments, clinic, day, month, patients, seats, users, year } = props
  clinic ||= {}
  seats ||= []
  seats = !seats.length ? [{}] : seats
  seats = seats.sort((a, b) => (a.name > b.name ? 1 : -1))
  year &&= parseInt(year, 10)
  month &&= month.charAt(0).toUpperCase() + month.slice(1)
  day &&= parseInt(day, 10)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { openingHours } = clinic

  const weekdays = getWeekedays(clinic.firstDayOfTheWeek)
  let date = new Date()

  if (day && month && year) {
    date = setYear(date, year)
    date = setMonth(date, months.indexOf(month))
    date = setDate(date, day)
  }

  let weekStartsOn = getDayOfTheWeekForDateFns(clinic.firstDayOfTheWeek)

  let firstDayOfWeek = startOfWeek(date, {
    weekStartsOn,
  })
  let lastDayOfWeek = addDays(firstDayOfWeek, 6)

  appointments = appointments?.filter(
    ({ startTime, endTime }) =>
      isBefore(startTime, endOfDay(lastDayOfWeek)) &&
      isAfter(endTime, firstDayOfWeek),
  )

  let openDays = {}

  if (
    openingHours?.length >= 1 &&
    openingHours[0].day &&
    openingHours[0].open &&
    openingHours[0].close
  ) {
    appointments?.forEach(appointment => {
      let dayOfTheWeek = format(new Date(appointment.startTime), 'EEEE')
      openDays[dayOfTheWeek] = true
    })

    openingHours?.forEach(openingHour => {
      if (openingHour.open && openingHour.close) {
        let dayOfTheWeek = openingHour.day
        openDays[dayOfTheWeek] = true
      }
    })
  } else {
    weekdays.forEach(dayOfTheWeek => {
      openDays[dayOfTheWeek] = true
    })
  }

  // console.log({ openDays })

  let openWeekdays = weekdays.filter(dayOfTheWeek => openDays[dayOfTheWeek])

  // console.log({ openWeekdays })

  lastDayOfWeek = openWeekdays[openWeekdays.length - 1]
  lastDayOfWeek = getDayOfTheWeekForDateFns(lastDayOfWeek)
  let endOfWeek = endOfDay(setDay(firstDayOfWeek, lastDayOfWeek))

  while (!openWeekdays.includes(format(firstDayOfWeek, 'EEEE'))) {
    firstDayOfWeek = addDays(firstDayOfWeek, 1)
  }

  month = months[getMonth(firstDayOfWeek)]

  let ids2patients = patients?.reduce((ids2patients, patient) => {
    ids2patients[patient._id] = patient

    return ids2patients
  }, {})

  let ids2usersColors = users?.reduce((ids2usersColors, user) => {
    ids2usersColors[user._id] = user.color

    return ids2usersColors
  }, {})

  appointments = appointments?.filter(
    ({ endTime, startTime }) =>
      isBefore(startTime, endOfWeek) && isBefore(firstDayOfWeek, endTime),
  )

  // console.log(
  //   'appointments',
  //   appointments.map(({ startTime }) => startTime),
  // )

  appointments = appointments?.map(appointment => ({
    ...appointment,
    patient: ids2patients[appointment.patientId],
  }))

  // console.log({ appointments })

  let slotMinutes = 15

  let slot2appointments = appointments.reduce(
    (slot2appointments, appointment) => {
      try {
        let appointmentStart = appointment.startTime
        let appointmentEnd = appointment.endTime

        if (appointmentStart && appointmentEnd) {
          let slotStartMinutes = 0
          let slotEndMinutes = slotStartMinutes + slotMinutes
          let slotStart = setMinutes(appointmentStart, slotStartMinutes)
          let slotEnd = setMinutes(appointmentStart, slotEndMinutes)

          do {
            const head =
              (isEqual(appointmentStart, slotStart) ||
                isAfter(appointmentStart, slotStart)) &&
              isBefore(appointmentStart, slotEnd)
            const middle =
              isBefore(appointmentStart, slotStart) &&
              isAfter(appointmentEnd, slotEnd)

            const tail =
              isAfter(appointmentEnd, slotStart) &&
              (isEqual(appointmentEnd, slotEnd) ||
                isBefore(appointmentEnd, slotEnd))

            if (head || middle || tail) {
              let slotStartISO = formatISO(slotStart)
              slot2appointments[slotStartISO] ||= []
              slot2appointments[slotStartISO].push(appointment)
            }
            slotStart = addMinutes(slotStart, slotMinutes)
            slotEnd = addMinutes(slotEnd, slotMinutes)
          } while (!isBefore(appointmentEnd, slotStart))
        }
      } catch (error) {
        console.error(error)
      }

      return slot2appointments
    },
    {},
  )
  // console.log('slot2appointments', slot2appointments)

  let slots = {}

  for (let d = 0; d < openWeekdays.length; d += 1) {
    let dayOfTheWeekName = openWeekdays[d]
    let dayOfTheWeek = addDays(firstDayOfWeek, d)

    let openingHours = clinic.openingHours.filter(
      ({ day, open, close }) => day === dayOfTheWeekName && open && close,
    )
    for (let o = 0; o < openingHours.length; o += 1) {
      let { open, close } = openingHours[o]
      let openingTime = dayOfTheWeek
      openingTime = setHours(openingTime, parseInt(open.slice(0, 2), 10))
      openingTime = setMinutes(openingTime, parseInt(open.slice(3), 10))

      let closingTime = dayOfTheWeek
      closingTime = setHours(closingTime, parseInt(close.slice(0, 2), 10))
      closingTime = setMinutes(closingTime, parseInt(close.slice(3), 10))

      let time = setMinutes(openingTime, 0)

      while (isBefore(openingTime, time)) {
        time = addMinutes(time, slotMinutes)
      }

      while (isBefore(time, closingTime)) {
        slots[format(time, 'HH:mm')] = true
        time = addMinutes(time, slotMinutes)
      }
    }
  }
  slots = Object.keys(slots)

  let days2slots = []

  for (let d = 0; d < openWeekdays.length; d += 1) {
    // let dayOfTheWeekName = openWeekdays[d]
    let dayOfTheWeek = addDays(firstDayOfWeek, d)

    // let openingHours = clinic.openingHours.filter(
    //   ({ day, open, close }) => day === dayOfTheWeekName && open && close,
    // )

    for (let slotIndex = 0; slotIndex < slots.length; slotIndex += 1) {
      let slotStartString = slots[slotIndex]
      // console.log('slotStart', slotStart)
      let [hours, minutes] = slotStartString.split(':')

      let slotStart = dayOfTheWeek
      slotStart = setHours(slotStart, parseInt(hours, 10))
      slotStart = setMinutes(slotStart, parseInt(minutes, 10))

      let appointments = slot2appointments[formatISO(slotStart)]

      days2slots[d] = days2slots[d] || []
      let seatsIds2appointments = {}

      for (let s = 0; s < seats.length; s += 1) {
        let seat = seats[s]

        let appointmentsForSeat = appointments?.filter(
          appointment => appointment.seatId === seat._id,
        )
        if (!appointmentsForSeat?.length && appointments?.length) {
          appointmentsForSeat = appointments.filter(({ seatId }) => !seatId)
        }
        let appointment = appointmentsForSeat?.[0]
        appointments = appointments?.filter(
          appointment_ => appointment_._id !== appointment?._id,
        )

        seatsIds2appointments[seat._id || ''] = appointment
      }
      days2slots[d].push(seatsIds2appointments)
    }
  }

  // console.log('days2slots', JSON.stringify(days2slots, null, 2))

  let changeToPreviousWeek = () => {
    let yearDashMonthDashDay = format(
      subDays(firstDayOfWeek, 7),
      'yyyy-MMMM-d',
    ).toLowerCase()
    navigate(`/clinics/${clinic._id}/agenda/${yearDashMonthDashDay}`)
  }

  let changeToNextWeek = () => {
    let yearDashMonthDashDay = format(
      addDays(firstDayOfWeek, 7),
      'yyyy-MMMM-d',
    ).toLowerCase()
    navigate(`/clinics/${clinic._id}/agenda/${yearDashMonthDashDay}`)
  }

  let weekName
  if (getMonth(firstDayOfWeek) === getMonth(endOfWeek)) {
    weekName = `${format(firstDayOfWeek, 'd')} - ${format(endOfWeek, 'd')} ${t(month)}`
  } else {
    weekName = `${format(firstDayOfWeek, 'd')} ${t(format(firstDayOfWeek, 'MMMM'))} - ${format(endOfWeek, 'd')} ${t(format(endOfWeek, 'MMMM'))}`
  }

  return (
    <div css={{ height: '100%', width: '100%', padding: 16 }}>
      <div
        css={{
          fontSize: 32,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          direction: 'ltr',
        }}
      >
        <div
          css={[
            clickable,
            { borderRadius: 24, width: 48, height: 48, marginBottom: 8 },
          ]}
          onClick={changeToPreviousWeek}
        >
          <IconLeft css={{ margin: 12 }} />
        </div>
        <div css={[row, { width: '100%' }]}>
          <div
            css={[
              colSm12,
              colMd4,
              {
                flexGrow: 1,
                display: 'flex',
                gap: 8,
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: 8,
              },
            ]}
          >
            {users.map(user =>
              user.url ? (
                <div key={user._id} css={avatarContainer}>
                  <ImageGraceful
                    css={{
                      aspectRatio: '1 / 1',
                      height: 32,
                      borderRadius: 1000,
                    }}
                    src={user.url.replace('.jpg', '-256w.webp')}
                    fallbackSrc={user.url.replace('.jpg', '-256w.jpg')}
                    onClick={() => navigate(`/team/${user._id}`)}
                  />
                </div>
              ) : (
                <div
                  key={user._id}
                  css={[
                    avatarContainer,
                    {
                      color: 'white',
                      fontSize: 20,
                      background: user.color,
                      textTransform: 'capitalize',
                    },
                  ]}
                  onClick={() => navigate(`/team/${user._id}`)}
                >
                  {user.getInitial()}
                </div>
              ),
            )}
          </div>
          <div
            css={[
              colSm12,
              colMd4,
              {
                flexGrow: 1,
                textAlign: 'center',
                marginBottom: 8,
              },
            ]}
          >
            {weekName}
          </div>
          <div
            css={[
              colSm12,
              colMd4,
              {
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: 8,
              },
            ]}
          >
            <WeekMonthSwitch value="week" />
          </div>
        </div>
        <div
          css={[
            clickable,
            { borderRadius: 24, width: 48, height: 48, marginBottom: 8 },
          ]}
          onClick={changeToNextWeek}
        >
          <IconRight css={{ margin: 12 }} />
        </div>
      </div>
      <table css={{ width: '100%', tableLayout: 'fixed' }}>
        <colgroup>
          <col css={{ width: '5ch' }} />
          <col />
          <col />
        </colgroup>
        <thead>
          <tr>
            <th> </th>
            {openWeekdays.map(dayOfTheWeek => {
              let day = setDay(
                firstDayOfWeek,
                getDayOfTheWeekForDateFns(dayOfTheWeek),
              )
              return (
                <th
                  key={dayOfTheWeek}
                  css={{
                    fontSize: 12,
                    padding: 4,
                    textTransform: 'uppercase',
                  }}
                  colSpan={seats.length}
                >
                  {t(dayOfTheWeek)} {format(day, 'd')}
                </th>
              )
            })}
          </tr>
          {seats.length > 1 && (
            <tr>
              <th
                css={{
                  fontSize: 14,
                  textTranform: 'uppercase',
                  paddingInlineEnd: 8,
                  width: '5ch',
                }}
              >
                <div
                  css={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {t('Seats')}
                </div>
              </th>
              {openWeekdays.map(dayOfTheWeek => (
                <>
                  {seats.map(seat => (
                    <th
                      key={`${dayOfTheWeek}-${seat._id}`}
                      css={{
                        fontSize: 12,
                        padding: 4,
                        textTransform: 'uppercase',
                      }}
                    >
                      {seat.name}
                    </th>
                  ))}
                </>
              ))}
            </tr>
          )}
        </thead>
        <tbody>
          {slots?.map((slotStart, slotIndex) => (
            <tr key={slotStart}>
              <td css={{ verticalAlign: 'middle' }}>{slotStart}</td>
              {openWeekdays.map((dayOfTheWeek, dayOfTheWeekIndex) => {
                let seatsIds = Object.keys(
                  days2slots?.[dayOfTheWeekIndex]?.[slotIndex] || {},
                )
                let day = setDay(
                  firstDayOfWeek,
                  getDayOfTheWeekForDateFns(dayOfTheWeek),
                )
                return (
                  <>
                    {seatsIds.map(seatId => {
                      let appointment =
                        days2slots[dayOfTheWeekIndex][slotIndex][seatId]

                      let background =
                        ids2usersColors[appointment?.usersIds?.[0]]

                      if (!background) {
                        if (appointment) {
                          background = 'var(--color-primary)'
                        } else {
                          background = 'white'
                        }
                      }

                      return (
                        <td
                          key={`${slotStart}-${dayOfTheWeek}-${seatId}`}
                          css={[
                            clickable,
                            singleLine,
                            {
                              height: 24,
                              maxHeight: 24,
                              fontSize: 12,
                              color: 'white',
                              padding: '0 8',
                              lineHeight: '24px',
                              background,
                              border: '1px solid var(--color-base)',
                            },
                          ]}
                          onClick={event => {
                            if (appointment) {
                              event.stopPropagation()
                              navigate(
                                `/clinics/${clinic._id}/appointments/${appointment._id}`,
                              )
                            } else {
                              navigate(
                                `/clinics/${clinic._id}/appointments/new?date=${format(day, 'yyyy-MM-dd')}&start=${slotStart}&seatId=${seatId}`,
                              )
                            }
                          }}
                        >
                          {appointment
                            ? appointment.patient?.name
                            : `${slotStart} ${t(dayOfTheWeek)} ${format(day, 'd')}`}
                        </td>
                      )
                    })}
                  </>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
      <div css={{ height: 16 }} />
    </div>
  )
}

const AgendaPageConnected = () => {
  useLoggedInSession()
  let { clinicId, yearDashMonthDashDay } = useParams()
  let clinic = useClinic(clinicId)
  let appointments = useAppointments({ clinicId: clinic._id })
  let seats = useSeats({ clinicId: clinic._id })
  let users = useUsers()
  appointments = appointments?.sort(
    (a, b) => new Date(a?.startTime) - new Date(b?.startTime),
  )

  // console.log(patientsIds)

  let patients = usePatients({
    clinicId: clinic._id,
  })
  // console.log({ patients })

  // console.log('yearDashMonthDashDay', yearDashMonthDashDay)
  yearDashMonthDashDay &&= yearDashMonthDashDay.toLowerCase()
  let [year, month, day] = yearDashMonthDashDay?.split('-') || []
  // console.log({ year, month, day })

  return (
    <AgendaPage
      appointments={appointments}
      clinic={clinic}
      day={day}
      month={month}
      patients={patients}
      seats={seats}
      users={users}
      year={year}
    />
  )
}

const AgendaPageWrapper = () => {
  useLoggedInSession()
  const { t } = useTranslation()

  return (
    <Layout title={t('Agenda')}>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <AgendaPageConnected />
        </Suspense>
      </ErrorBoundary>
    </Layout>
  )
}

export default AgendaPageWrapper
