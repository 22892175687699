/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  flag: '🇨🇳',
  countryName: 'China',
  phonePrefix: '+86',
  administrativeDivisionsNames: [
    '安徽省',
    '澳门',
    '北京市',
    '重庆市',
    '福建省',
    '甘肃省',
    '广东省',
    '广西',
    '贵州省',
    '海南省',
    '河北省',
    '河南省',
    '黑龙江省',
    '湖北省',
    '湖南省',
    '吉林省',
    '江苏省',
    '江西省',
    '辽宁省',
    '内蒙古',
    '宁夏',
    '青海省',
    '山东省',
    '山西省',
    '陕西省',
    '上海市',
    '四川省',
    '台湾',
    '天津市',
    '西藏',
    '香港',
    '新疆',
    '云南省',
    '浙江省',
  ],
  administrativeDivisionsType: 'province',
  countryCode: 'CN',
  languages: ['zh'],
  zipcodePattern: '\\d{6}',
}
