/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */

/* eslint-disable max-len */
import React, { Suspense, useEffect, useState } from 'react'
import { css, jsx } from '@emotion/react'
import {
  unstable_useBlocker as useBlocker,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import useLoggedInSession from '@monorepo/session/useLoggedInSession.js'
import { toastError } from '@monorepo/shared/AndroidToast.js'
import preferCapitalized from '@monorepo/shared/preferCapitalized.js'
import uuid from '@monorepo/shared/uuid.js'
import addOrUpdate from '@monorepo/tractor/addOrUpdate.js'
import remove from '@monorepo/tractor/remove.js'
import Procedure from '@dododentist/model/Procedure.js'
import { useTranslation } from '@multilocale/react/index.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarButton from '@stiloso/components/AppBarButton.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import Button from '@stiloso/components/Button.js'
import ErrorBoundary from '@stiloso/components/ErrorBoundary.js'
import Input from '@stiloso/components/Input.js'
import Spinner from '@stiloso/components/Spinner.js'
import IconDelete from '@stiloso/icons/IconDelete.js'
import dLgBlock from '@stiloso/styles/bootstrap/dLgBlock.js'
import dNone from '@stiloso/styles/bootstrap/dNone.js'
import card from '@stiloso/styles/card.js'
import section from '@stiloso/styles/section.js'
import Layout from '../components/Layout.js'
import SaveChangesPrompt from '../dialogs/SaveChangesPrompt.js'
import useClinic from '../hooks/useClinic.js'
import useProcedure from '../hooks/useProcedure.js'

export const paths = ['/clinics/:clinicId/procedures/:procedureId']

const ProcedurePage = props => {
  let { action, clinic, procedure } = props
  useLoggedInSession()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [edited, setEdited] = useState(false)
  const [isLoading, setLoading] = useState(false)
  let [_id, setId] = useState(procedure?._id || uuid())
  let [name, setName] = useState('')
  let [price, setPrice] = useState('')

  const changeName = event => {
    setEdited(true)
    setName(event.target.value)
  }

  const changePrice = event => {
    setEdited(true)
    setPrice(event.target.value)
  }

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      edited && currentLocation.pathname !== nextLocation.pathname,
  )

  useEffect(() => {
    if (!edited) {
      blocker?.proceed?.()
    }
  }, [edited])

  const addOrUpdateProcedure = async event => {
    event?.preventDefault?.()

    try {
      name = preferCapitalized(name)
      const newProcedure = new Procedure({
        _id,
        ...procedure,
        lastEditTime: new Date().toISOString(),
        organizationId: clinic.organizationId,
        clinicId: clinic._id,
        name,
        price,
      })

      if (newProcedure.demo) {
        newProcedure.demo = false
      }

      setLoading(true)
      await addOrUpdate(newProcedure, procedure)
      setLoading(false)
      setEdited(false)
      navigate(`/clinics/${clinic._id}/procedures`)
    } catch (error) {
      toastError(t(error))
    }
  }

  let closePrompt = () => {
    blocker.reset()
  }

  let discardChangesPrompt = () => {
    blocker.proceed()
  }

  let deleteProcedure = async event => {
    event?.preventDefault?.()

    try {
      if (procedure) {
        await remove(procedure)
      }

      setEdited(false)
      navigate(`/clinics/${clinic._id}/procedures`)
    } catch (error) {
      toastError(t(error))
    }
  }

  let saveChangesPrompt = async () => {
    await addOrUpdateProcedure()
    blocker.proceed()
  }

  useEffect(() => {
    if (procedure) {
      setId(procedure._id)
      setName(procedure.name)
      setPrice(procedure.price)
    }
  }, [procedure])

  useEffect(() => {
    if (action) {
      navigate('?action=')
    }

    if (action === 'delete') {
      deleteProcedure()
    }
  }, [action])

  return (
    <>
      {blocker?.state === 'blocked' && (
        <SaveChangesPrompt
          close={closePrompt}
          discardChanges={discardChangesPrompt}
          saveChanges={saveChangesPrompt}
        />
      )}
      <div css={section}>
        <div css={[dNone, dLgBlock]}>
          <AppBar>
            <AppBarTitle>
              {procedure ? t('Procedure') : t('New procedure')}
            </AppBarTitle>
            <div css={{ flexGrow: 1 }} />
            {procedure && (
              <AppBarButton
                appearance="secondary"
                Icon={IconDelete}
                label={t('Delete')}
                onClick={deleteProcedure}
              />
            )}
          </AppBar>
        </div>
        <form
          onSubmit={addOrUpdateProcedure}
          css={[card, { margin: '0 16px' }]}
        >
          <Input
            label={t('Name')}
            value={name}
            onChange={changeName}
            autoFocus
            autoComplete="off"
          />
          <Input
            label={t('Price')}
            value={price}
            placeholder="0"
            min="0"
            type="number"
            onChange={changePrice}
          />
          {(edited || !procedure) && (
            <Button
              type="submit"
              css={{ marginTop: 16 }}
              fullWidth
              isLoading={isLoading}
            >
              {procedure ? t('Save procedure') : t('Add procedure')}
            </Button>
          )}
        </form>
      </div>
    </>
  )
}

const ProcedurePageConnected = () => {
  useLoggedInSession()
  const { clinicId, procedureId } = useParams()
  const [searchParams] = useSearchParams()
  const action = searchParams.get('action')
  const clinic = useClinic(clinicId)
  const procedure = useProcedure(procedureId)

  return <ProcedurePage action={action} clinic={clinic} procedure={procedure} />
}

const ProcedurePageWrapper = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { clinicId, procedureId } = useParams()
  const isNewProcedure = procedureId === 'new'
  let title = t(isNewProcedure ? 'New procedure' : 'Procedure')
  const onClickTitle = () => {
    navigate(`/clinics/${clinicId}/procedures`)
  }
  const actions = [!isNewProcedure && 'delete'].filter(_ => _)
  return (
    <Layout
      actions={actions}
      back
      main={false}
      title={title}
      onClickTitle={onClickTitle}
    >
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <ProcedurePageConnected />
        </Suspense>
      </ErrorBoundary>
    </Layout>
  )
}

export default ProcedurePageWrapper
