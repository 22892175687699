/* Copyright 2013 - 2024 Waiterio LLC */
import convertStringToColor from '@monorepo/shared/convertStringToColor.js'
import uuid from '@monorepo/shared/uuid.js'

function User(source) {
  if (source instanceof User) {
    return source
  } else {
    Object.keys(source || {}).forEach(key => {
      this[key] = source[key]
    })

    if (!this._id) {
      this._id = uuid()
    }

    if (!this.creationTime) {
      this.creationTime = new Date().toISOString()
    }

    if (!this.lastEditTime) {
      this.lastEditTime = this.creationTime
    }

    if (!this.color) {
      this.color = convertStringToColor(this._id)
    }

    return this
  }
}

User.prototype.getInitial = function getInitial() {
  let initial

  if (this.firstName?.trim().length > 0) {
    initial = this.firstName.charAt(0)
  } else if (this.lastName?.trim().length > 0) {
    initial = this.lastName.charAt(0)
  } else if (this.email?.trim().length > 0) {
    initial = this.email.charAt(0)
  } else {
    initial = '?'
  }

  return initial
}

User.prototype.constructor = User

User.name_ = 'User'
User.collection = User.name_.toLowerCase() + 's'

export default User
