/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { Suspense, useEffect, useState } from 'react'
import { jsx } from '@emotion/react'
import {
  unstable_useBlocker as useBlocker,
  useNavigate,
} from 'react-router-dom'
import getRoles from '@monorepo/session/getRoles.js'
import getUserId from '@monorepo/session/getUserId.js'
import useLoggedInSession from '@monorepo/session/useLoggedInSession.js'
import { toastError } from '@monorepo/shared/AndroidToast.js'
import iosNative from '@monorepo/shared/device/iosNative.js'
import nativeVersion from '@monorepo/shared/device/nativeVersion.js'
import getBrowserLanguage from '@monorepo/shared/getBrowserLanguage.js'
import getImageFromEvent from '@monorepo/shared/getImageFromEvent.js'
import preferCapitalized from '@monorepo/shared/preferCapitalized.js'
import addOrUpdate from '@monorepo/tractor/addOrUpdate.js'
import User from '@dododentist/model/User.js'
import { useTranslation } from '@multilocale/react/index.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import Button from '@stiloso/components/Button.js'
import ButtonBar from '@stiloso/components/ButtonBar.js'
import DeleteDialog from '@stiloso/components/DeleteDialog.js'
import ErrorBoundary from '@stiloso/components/ErrorBoundary.js'
import ErrorDialog from '@stiloso/components/ErrorDialog.js'
import ImageGraceful from '@stiloso/components/ImageGraceful.js'
import Input from '@stiloso/components/Input.js'
import LanguageAutocomplete from '@stiloso/components/LanguageAutocomplete.js'
import PhoneInput from '@stiloso/components/PhoneInput.js'
import Spinner from '@stiloso/components/Spinner.js'
import UploadImageComponent from '@stiloso/components/UploadImage.js'
import IconDelete from '@stiloso/icons/IconDelete.js'
import card from '@stiloso/styles/card.js'
import label from '@stiloso/styles/label.js'
import section from '@stiloso/styles/section.js'
import Layout from '../components/Layout.js'
import SaveChangesPrompt from '../dialogs/SaveChangesPrompt.js'
import useUser from '../hooks/useUser.js'
import updateUserWithAvatar from '../tractor/updateUserWithAvatar.js'
import webappVersion from '../version.js'

export const paths = ['/account']

const AccountPage = props => {
  const { roles, user } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [error, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [isLoadingAvatar, setLoadingAvatar] = useState(false)

  const browserLanguage = getBrowserLanguage()

  const [edited, setEdited] = useState(false)
  let [firstName, setFirstName] = useState(user.firstName || '')
  let [lastName, setLastName] = useState(user.lastName || '')
  let [phone, setPhone] = useState(user.phone || '')
  let [language, setLanguage] = useState(user.language || browserLanguage)
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] =
    useState(false)
  let [avatarUrl, setAvatarUrl] = useState(user.avatarUrl || '')

  const changeFirstName = event => {
    setEdited(true)
    setFirstName(event.target.value)
  }

  const changeLastName = event => {
    setEdited(true)
    setLastName(event.target.value)
  }

  const changePhone = phone => {
    setEdited(true)
    setPhone(phone)
  }

  const changeLanguage = language => {
    setEdited(true)
    setLanguage(language)
  }

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      edited && currentLocation.pathname !== nextLocation.pathname,
  )

  useEffect(() => {
    if (!edited) {
      blocker?.proceed?.()
    }
  }, [edited])

  const changeUser = async event => {
    event?.preventDefault?.()

    // console.log('changeUser', avatarUrl)

    const newUser = new User({
      ...user,
      firstName: preferCapitalized(firstName),
      lastName: preferCapitalized(lastName),
      phone,
      avatarUrl: avatarUrl?.length ? avatarUrl : null,
      language,
      lastEditTime: new Date().toISOString(),
    })

    setLoading(true)

    await addOrUpdate(newUser, user)
      .then(() => {
        setEdited(false)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        setError(error)
        toastError(t(error))
      })
  }

  const deleteAvatar = async event => {
    event?.preventDefault?.()
    try {
      const newUser = new User({
        ...user,
        avatarUrl: null,
        lastEditTime: new Date().toISOString(),
      })

      setAvatarUrl(null)
      setLoadingAvatar(true)
      await addOrUpdate(newUser, user)

      setLoadingAvatar(false)
    } catch (error) {
      setAvatarUrl(user?.avatarUrl)
      setLoadingAvatar(false)
      toastError(t(error))
    }
  }

  const onClickUploadAvatar = () => {
    document.getElementById('uploadAvatarInput').click()
  }

  const uploadAvatar = async event => {
    event?.preventDefault?.()
    try {
      setLoadingAvatar(true)
      let imageFile = await getImageFromEvent(event)
      let newUser = await updateUserWithAvatar({
        file: imageFile,
        userId: user._id,
      })

      setLoadingAvatar(false)
      setAvatarUrl(newUser.avatarUrl)
    } catch (error) {
      setLoadingAvatar(false)
      toastError(t(error))
    }
  }

  const showDeleteConfirmationDialog = () => {
    setDeleteConfirmationDialog(true)
  }

  const hideDeleteConfirmationDialog = () => {
    setDeleteConfirmationDialog(false)
  }

  let onDeleteAccount = async event => {
    event?.preventDefault?.()
    hideDeleteConfirmationDialog()
    if (window.$globo) {
      window.$globo.push(['showChat'])
      window.$globo.push(['openChat'])
    } else {
      window.open('mailto:info@veterical.com', '_blank')
    }
  }

  let closePrompt = () => {
    blocker.reset()
  }

  let discardChangesPrompt = () => {
    blocker.proceed()
  }

  let saveChangesPrompt = async () => {
    await changeUser()
    blocker.proceed()
  }

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName)
      setLastName(user.lastName)
      setLanguage(user.language)
      setPhone(user.phone)
    }
  }, [user])

  return (
    <>
      {blocker?.state === 'blocked' && (
        <SaveChangesPrompt
          close={closePrompt}
          discardChanges={discardChangesPrompt}
          saveChanges={saveChangesPrompt}
        />
      )}
      {deleteConfirmationDialog && (
        <DeleteDialog
          title={t('Delete account')}
          message={t(
            'Do you want to permanently delete your account and all data related to your clinic?',
          )}
          onDelete={onDeleteAccount}
          close={hideDeleteConfirmationDialog}
          loading={isLoading}
        />
      )}
      <form
        onSubmit={changeUser}
        css={{ display: 'flex', flexDirection: 'column' }}
      >
        <div css={[card, { display: 'flex', flexDirection: 'column' }]}>
          <Input label={t('email')} value={user.email} readOnly />
          <Input
            label={t('First name')}
            value={firstName}
            onChange={changeFirstName}
          />
          <Input
            label={t('Last name')}
            value={lastName}
            onChange={changeLastName}
          />
          <PhoneInput phone={phone} onChange={changePhone} />
          <LanguageAutocomplete
            inputLabel="UI language"
            selectedLanguage={language}
            onSelectLanguage={changeLanguage}
          />
          {!iosNative && (
            <div css={{ width: 140 }}>
              <label css={label}>{t('Upload avatar')}</label>
              {avatarUrl && (
                <div css={{ position: 'relative', width: 128 }}>
                  <ImageGraceful
                    css={{
                      aspectRatio: '1 / 1',
                      height: 128,
                      borderRadius: 1000,
                    }}
                    retry={{
                      count: 10,
                      delay: 1,
                      accumulate: false,
                    }}
                    src={avatarUrl.replace('.jpg', '-256w.webp')}
                    fallbackSrc={avatarUrl.replace('.jpg', '-256w.jpg')}
                  />

                  <div
                    css={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      padding: 4,
                      borderRadius: 4,
                      backgroundColor: 'var(--color-primary)',
                      cursor: 'pointer',
                      ':hover': {
                        backgroundColor: 'var(--color-primary-light)',
                      },
                      ':active': {
                        backgroundColor: 'var(--color-primary-dark)',
                      },
                    }}
                    onClick={deleteAvatar}
                  >
                    <IconDelete css={{ fill: 'white' }} />
                  </div>
                </div>
              )}
              {(!avatarUrl || isLoadingAvatar) && (
                <UploadImageComponent
                  inputId="uploadAvatarInput"
                  onClickUploadInput={onClickUploadAvatar}
                  uploadImage={uploadAvatar}
                  isPending={isLoadingAvatar}
                  styles={{ borderRadius: 1000 }}
                />
              )}
            </div>
          )}
        </div>
        <div css={[card, { margin: '16 0', paddingBottom: 0 }]}>
          <div css={{ width: '100%', display: 'flex', lineHeight: '48px' }}>
            <div css={{ flexGrow: 1 }}>{t('webapp version')}</div>
            <div>{webappVersion}</div>
          </div>
          {nativeVersion && (
            <div css={{ width: '100%', display: 'flex', lineHeight: '48px' }}>
              <div css={{ flexGrow: 1 }}>{t('native app version')}</div>
              <div>{nativeVersion}</div>
            </div>
          )}
        </div>
        <Button
          onClick={() => {
            navigate('/logout')
          }}
          appearance="secondary"
        >
          {t('Logout')}
        </Button>
        {roles?.some(role => role?.type === 'admin') && (
          <Button
            appearance="danger"
            onClick={showDeleteConfirmationDialog}
            disabled={isLoading}
            css={{ marginBottom: 16, maxWidth: 240, marginTop: 32 }}
          >
            {t('Delete account')}
          </Button>
        )}
        {edited && (
          <ButtonBar
            type="submit"
            css={{ marginTop: 16 }}
            isLoading={isLoading}
          >
            {t('Save')}
          </ButtonBar>
        )}
      </form>

      {error && <ErrorDialog error={error} close={setError(null)} />}
    </>
  )
}

const AccountPageConnected = () => {
  useLoggedInSession()
  const roles = getRoles()
  const user = useUser(getUserId())
  // console.log('user', user)

  return <AccountPage roles={roles} user={user} />
}

const AccountPageWrapper = () => {
  const { t } = useTranslation()
  const title = t('Account')
  return (
    <Layout title={title}>
      <div css={section}>
        <AppBar>
          <AppBarTitle>{title}</AppBarTitle>
        </AppBar>
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <AccountPageConnected />
          </Suspense>
        </ErrorBoundary>
      </div>
    </Layout>
  )
}

export default AccountPageWrapper
