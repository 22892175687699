/* Copyright 2013 - 2024 Waiterio LLC */
import batchCalls from '@monorepo/client/batchCalls.js'
import pick from '@monorepo/forage/pick.js'
import plant from '@monorepo/forage/plant.js'
import getClass from '@monorepo/model/getClass.js'
import getOrganizationId from '@monorepo/session/getOrganizationId.js'

const getAllData = classes => {
  const organizationId = getOrganizationId()
  // const twentyfourHours = 86400000 // TWENTYFOUR_HOURS
  // const startTime = new Date().getTime() - twentyfourHours

  let calls = classes
    .filter(
      class_ =>
        // class_.name_ !== 'Appointment' &&
        class_.name_ !== 'Organization',
    )
    .map(class_ => ({
      alias: class_.collection,
      url: `${class_.collection}?organizationId=${organizationId}`,
    }))

  return batchCalls([
    // {
    //   alias: 'organization',
    //   url: `organizations/${organizationId}`,
    // },
    // {
    //   alias: 'appointments',
    //   url: `appointments?organizationId=${organizationId}&startTime=${startTime}`,
    // },
    ...calls,
  ]).then(alias2documents =>
    Object.keys(alias2documents).reduce((data, alias) => {
      let result = alias2documents[alias]

      if (result && result instanceof Array) {
        let documents = result
        data[alias] = documents.map(document => {
          let Class = getClass(classes, alias)
          return new Class(document)
        })
      } else if (result) {
        let document = result
        let Class = getClass(classes, alias)
        data[alias] = new Class(document)
      }

      return data
    }, {}),
  )
}

const fetchAllData = async classes => {
  let alias2documents = await getAllData(classes)
  // console.log(alias2documents)

  let aliases = Object.keys(alias2documents)

  for (let a = 0; a < aliases.length; a += 1) {
    let alias = aliases[a]
    let result = alias2documents[alias]
    // debugger
    if (result && result instanceof Array) {
      let documents = result

      for (let d = 0; d < documents.length; d += 1) {
        let document = documents[d]
        let Class = getClass(classes, alias)
        let oldDocument = await pick(Class, document._id)
        if (!oldDocument || document.lastEditTime > oldDocument.lastEditTime) {
          await plant(Class, document)
        }
      }
    } else if (result) {
      let document = result
      let Class = getClass(classes, alias)
      let oldDocument = await pick(Class, document._id)
      if (!oldDocument || document.lastEditTime > oldDocument.lastEditTime) {
        await plant(Class, document)
      }
    }
  }
}

export default fetchAllData
