/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */

/* eslint-disable max-len */
import React, { Suspense, useEffect, useState } from 'react'
import { css, jsx } from '@emotion/react'
import {
  unstable_useBlocker as useBlocker,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { toastError } from '@monorepo/shared/AndroidToast.js'
import preferCapitalized from '@monorepo/shared/preferCapitalized.js'
import uuid from '@monorepo/shared/uuid.js'
import { useTranslation } from '@multilocale/react/index.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarButton from '@stiloso/components/AppBarButton.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import Button from '@stiloso/components/Button.js'
import ErrorBoundary from '@stiloso/components/ErrorBoundary.js'
import Spinner from '@stiloso/components/Spinner.js'
import IconDelete from '@stiloso/icons/IconDelete.js'
import card from '@stiloso/styles/card.js'
import input from '@stiloso/styles/input.js'
import label from '@stiloso/styles/label.js'
import section from '@stiloso/styles/section.js'
import Layout from '../components/Layout.js'
import SaveChangesPrompt from '../dialogs/SaveChangesPrompt.js'
import addOrUpdate from '@monorepo/tractor/addOrUpdate.js'
import remove from '@monorepo/tractor/remove.js'
import useClinic from '../hooks/useClinic.js'
import useSeat from '../hooks/useSeat.js'
import useSeats from '../hooks/useSeats.js'
import Seat from '@dododentist/model/Seat.js'
import useLoggedInSession from '@monorepo/session/useLoggedInSession.js'

export const paths = ['/clinics/:clinicId/seats/:seatId']

const SeatPage = props => {
  let { clinic, seat, seats } = props
  useLoggedInSession()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [edited, setEdited] = useState(false)
  const [isLoading, setLoading] = useState(false)
  let [_id, setId] = useState(seat?._id || uuid())
  let [name, setName] = useState('')

  const changeName = event => {
    setEdited(true)
    setName(event.target.value)
  }

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      edited && currentLocation.pathname !== nextLocation.pathname,
  )

  useEffect(() => {
    if (!edited) {
      blocker?.proceed?.()
    }
  }, [edited])

  const addOrUpdateSeat = async event => {
    event?.preventDefault?.()

    try {
      name = preferCapitalized(name)
      const newSeat = new Seat({
        _id,
        ...seat,
        lastEditTime: new Date().toISOString(),
        organizationId: clinic.organizationId,
        clinicId: clinic._id,
        name,
      })

      if (newSeat.demo) {
        newSeat.demo = false
      }

      setLoading(true)
      await addOrUpdate(newSeat, seat)
      setLoading(false)
      setEdited(false)
      navigate(`/clinics/${clinic._id}`)
    } catch (error) {
      toastError(t(error))
    }
  }

  let closePrompt = () => {
    blocker.reset()
  }

  let discardChangesPrompt = () => {
    blocker.proceed()
  }

  let onDelete = async event => {
    event?.preventDefault?.()

    try {
      if (seat) {
        await remove(seat)
      }

      setEdited(false)
      navigate(`/clinics/${clinic._id}`)
    } catch (error) {
      toastError(t(error))
    }
  }

  let saveChangesPrompt = async () => {
    await addOrUpdateSeat()
    blocker.proceed()
  }

  useEffect(() => {
    if (seat) {
      setId(seat._id)
      setName(seat.name)
    }
  }, [seat])

  return (
    <>
      {blocker?.state === 'blocked' && (
        <SaveChangesPrompt
          close={closePrompt}
          discardChanges={discardChangesPrompt}
          saveChanges={saveChangesPrompt}
        />
      )}
      <div css={section}>
        <AppBar>
          <AppBarTitle>{seat ? t('Seat') : t('New seat')}</AppBarTitle>
          <div css={{ flexGrow: 1 }} />
          {seat && seats.length > 1 && (
            <AppBarButton
              appearance="secondary"
              Icon={IconDelete}
              label={t('Delete')}
              onClick={onDelete}
            />
          )}
        </AppBar>
        <form onSubmit={addOrUpdateSeat} css={[card, { margin: '0 16px' }]}>
          <label htmlFor="nameInput" css={label}>
            {t('Name')}
          </label>
          <input
            id="nameInput"
            css={input}
            value={name}
            onChange={changeName}
            required
            minLength={1}
            autoFocus
            autoComplete="off"
          />
          {(edited || !seat) && (
            <Button
              type="submit"
              css={{ marginTop: 16 }}
              fullWidth
              isLoading={isLoading}
            >
              {seat ? t('Save seat') : t('Add seat')}
            </Button>
          )}
        </form>
      </div>
    </>
  )
}

const SeatPageConnected = () => {
  useLoggedInSession()
  const { clinicId, seatId } = useParams()
  const clinic = useClinic(clinicId)
  const seat = useSeat(seatId)
  const seats = useSeats({ clinicId })

  return <SeatPage clinic={clinic} seat={seat} seats={seats} />
}

const SeatPageWrapper = () => {
  const { t } = useTranslation()
  const { seatId } = useParams()
  const isNewSeat = seatId === 'new'
  return (
    <Layout title={t(isNewSeat ? 'New seat' : 'Seat')}>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <SeatPageConnected />
        </Suspense>
      </ErrorBoundary>
    </Layout>
  )
}

export default SeatPageWrapper
