/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import Modal from '@stiloso/components/Modal.js'
import ModalHeader from '@stiloso/components/ModalHeader.js'
import clickable from '@stiloso/styles/clickable.js'

const SaveChangesPrompt = props => {
  let { t } = useTranslation()

  return (
    <Modal close={props.close} size="small">
      <ModalHeader>{t('Save changes')}</ModalHeader>

      <div
        css={{
          minHeight: 48,
          padding: 16,
          textAlign: 'center',
          border: 0,
          borderBottom: 1,
          borderColor: '#e4e4e4',
          borderStyle: 'solid',
        }}
      >
        {t('Do you want to save the changes made')}
      </div>

      <div css={{ display: 'flex' }}>
        <div
          css={[
            clickable,
            {
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderInlineEnd: '1px solid #e4e4e4',
              lineHeight: '48px',
            },
          ]}
          onClick={props.discardChanges}
        >
          {t('No')}
        </div>

        <div
          css={[
            clickable,
            {
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              lineHeight: '48px',
            },
          ]}
          onClick={props.saveChanges}
        >
          {t('Yes')}
        </div>
      </div>
    </Modal>
  )
}

export default SaveChangesPrompt
