/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  flag: '🇻🇪',
  countryName: 'Venezuela',
  phonePrefix: '+58',
  administrativeDivisionsType: 'state',
  countryCode: 'VE',
  languages: ['es'],
  zipcodePattern: '\\d{4}',
}
