/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  flag: '🇰🇷',
  countryName: 'South Korea',
  phonePrefix: '+82',
  administrativeDivisionsNames: [
    '강원',
    '경기',
    '경남',
    '경북',
    '광주',
    '대구',
    '대전',
    '부산',
    '서울',
    '세종',
    '울산',
    '인천',
    '전남',
    '전북',
    '제주',
    '충남',
    '충북',
  ],
  administrativeDivisionsType: 'province',
  countryCode: 'KR',
  languages: ['ko'],
  zipcodePattern: '\\d{5}',
}
