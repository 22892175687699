/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useEffect, useRef } from 'react'
import { jsx, css } from '@emotion/react'
import clickable from '@stiloso/styles/clickable.js'
import singleLine from '@stiloso/styles/singleLine.js'

export const tab = css([
  clickable,
  singleLine,
  {
    display: 'flex',
    padding: '0px 16px',
    border: 9,
    margin: 0,
    background: 'transparent',
    fontSize: 14,
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'capitalize',
  },
])

export const selectedTab = css`
  &,
  &:active {
    box-shadow: 0px -4px 0px var(--color-primary) inset;
    color: var(--color-primary);
  }
`

export const tabText = css([singleLine, {}])

const Tab = ({
  children,
  onClick,
  selected,
  styles,
  dataTestId,
  totalTabs,
}) => {
  const selectedTabRef = useRef(null)

  totalTabs ||= 7
  let innerWidth = 320

  if (typeof window !== 'undefined') {
    innerWidth = window?.innerWidth ?? 320
  }
  let minTabWidth = 100
  let leftOfTheFoldTabs

  let possibleTabs = [...Array(totalTabs - 1).keys()].map(i => i + 1.5)

  let p = 0
  while (p < possibleTabs.length - 1) {
    let p1 = possibleTabs[p]
    let p2 = possibleTabs[p + 1]
    if (innerWidth / p1 > minTabWidth && innerWidth / p2 < minTabWidth) {
      leftOfTheFoldTabs = p1
    }

    p += 1
  }

  let width

  if (leftOfTheFoldTabs) {
    width = Math.floor(innerWidth / leftOfTheFoldTabs)
  } else {
    width = Math.floor(innerWidth / totalTabs)
  }

  useEffect(() => {
    if (selectedTabRef.current) {
      selectedTabRef.current.scrollIntoView({
        behavior: 'auto',
        inline: 'center',
      })
    }
  }, [])

  return (
    <div
      type="button"
      ref={selected ? selectedTabRef : null}
      css={[
        tab,
        selected && selectedTab,
        styles,
        { minWidth: width, width, maxWidth: width },
      ]}
      onClick={onClick}
      data-testid={dataTestId}
    >
      {children}
    </div>
  )
}

export default Tab
