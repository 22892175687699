/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  flag: '🇦🇪',
  countryName: 'United Arab Emirates',
  phonePrefix: '+971',
  administrativeDivisionsNames: [
    'أبو ظبي',
    'الشارقة',
    'الفجيرة',
    'ام القيوين',
    'دبي',
    'رأس الخيمة',
    'عجمان',
  ],
  administrativeDivisionsType: 'emirate',
  countryCode: 'AE',
  languages: ['ar'],
}
