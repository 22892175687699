/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  flag: '🇲🇽',
  countryName: 'Mexico',
  phonePrefix: '+52',
  administrativeDivisionsNames: [
    'Aguascalientes',
    'Baja California',
    'Baja California Sur',
    'Campeche',
    'Chiapas',
    'Chihuahua',
    'Ciudad de México',
    'Coahuila de Zaragoza',
    'Colima',
    'Durango',
    'Estado de México',
    'Guanajuato',
    'Guerrero',
    'Hidalgo',
    'Jalisco',
    'Michoacán',
    'Morelos',
    'Nayarit',
    'Nuevo León',
    'Oaxaca',
    'Puebla',
    'Querétaro',
    'Quintana Roo',
    'San Luis Potosí',
    'Sinaloa',
    'Sonora',
    'Tabasco',
    'Tamaulipas',
    'Tlaxcala',
    'Veracruz',
    'Yucatán',
    'Zacatecas',
  ],
  administrativeDivisionsType: 'state',
  countryCode: 'MX',
  languages: ['es'],
  zipcodePattern: '\\d{5}',
}
