/* Copyright 2013 - 2024 Waiterio LLC */
import deleteClass from '@monorepo/client/deleteClass.js'
import burn from '@monorepo/forage/burn.js'
import plant from '@monorepo/forage/plant.js'

const remove = async document => {
  let Class = Object.getPrototypeOf(document).constructor
  document = await plant(Class, document)
  await burn(Class, document._id)
  await deleteClass(Class, document._id).catch(error => {
    plant(Class, document)

    throw error
  })
}

export default remove
