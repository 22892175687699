/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useState } from 'react'
import { jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import Modal from '@stiloso/components/Modal.js'
import IconDelete from '@stiloso/icons/IconDelete.js'
import clickable from '@stiloso/styles/clickable.js'
import label from '@stiloso/styles/label.js'
import conditions2colors from '../conditions2colors.js'

const ToothDialog = ({
  close,
  deleteTooth,
  tooth: oldTooth,
  toothNumber,
  changeTooth,
}) => {
  const { t } = useTranslation()

  let [edited, setEdited] = useState(false)
  let [tooth, setTooth] = useState({
    ...oldTooth,
    conditions: oldTooth?.conditions || {},
    note: oldTooth?.note || '',
  })
  let hasConditionOrNote =
    tooth.note || Object.values(tooth.conditions).some(condition => condition)

  let changeNote = event => {
    let note = event.target.value
    setEdited(true)
    setTooth({ ...tooth, note })
  }

  const toggleCondition = condition => {
    setEdited(true)
    let newTooth = { ...tooth }
    newTooth.conditions ||= {}

    if (newTooth.conditions?.[condition]) {
      delete newTooth.conditions[condition]
    } else {
      newTooth.conditions[condition] = true
    }

    setTooth(newTooth)
  }

  const save = async () => {
    if (edited) {
      if (hasConditionOrNote) {
        changeTooth(tooth)
      } else {
        deleteTooth(toothNumber)
      }
    } else {
      close()
    }
  }

  return (
    <Modal close={save}>
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '16 16 0 16',
        }}
      >
        <div css={{ flexGrow: 1, fontSize: 20 }}>
          {`${t('Tooth')} ${toothNumber}`}
        </div>
        {hasConditionOrNote && (
          <IconDelete
            onClick={() => deleteTooth(toothNumber)}
            css={[
              clickable,
              { cursor: 'pointer', height: 48, width: 48, padding: 12 },
            ]}
          />
        )}
      </div>
      <div
        css={{
          marginTop: 8,
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
          padding: '0 16',
        }}
      >
        {Object.keys(conditions2colors).map(condition => (
          <div
            key={condition}
            css={[
              clickable,
              {
                display: 'flex',
                alignItems: 'center',
                height: 32,
              },
            ]}
            onClick={() => toggleCondition(condition)}
          >
            <div
              css={{
                width: 16,
                height: 16,
                border: '2px solid',
                borderColor: conditions2colors[condition],
                background:
                  tooth.conditions[condition] && conditions2colors[condition],
                borderRadius: 8,
                marginInlineEnd: 8,
              }}
            />
            {t(condition)}
          </div>
        ))}
        <label htmlFor="nameInput" css={label}>
          {t('Note')}
        </label>
        <textarea
          name="note"
          defaultValue={tooth?.note}
          onChange={changeNote}
          data-testid="note-input"
          css={{
            width: '100%',
            fontSize: 16,
            padding: 16,
            resize: 'vertical',
            minHeight: 160,
            border: '1px solid #e4e4e4',
          }}
        />
      </div>
    </Modal>
  )
}

export default ToothDialog
