/* Copyright 2013 - 2024 Waiterio LLC */
import localForage from 'localforage'
import PRODUCT from '@monorepo/env/PRODUCT.js'

function pick(Class, documentId) {
  const { collection } = Class
  return localForage
    .getItem(`${PRODUCT}.${collection}.${documentId}`)
    .then(document => (document ? new Class(document) : null))
}

export default pick
