/* Copyright 2013 - 2024 Waiterio LLC */

import preferCapitalizedEachWord from './preferCapitalizedEachWord.js'

export default function getNameFromEmail(email) {
  let [namePart] = email.split('@')
  let [firstName, lastName = ''] = namePart.split(/[._-]/)

  firstName = preferCapitalizedEachWord(firstName)
  lastName = preferCapitalizedEachWord(lastName)

  return { firstName, lastName }
}
