/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  flag: '🇰🇾',
  countryName: 'Cayman Islands',
  phonePrefix: '+345',
  administrativeDivisionsType: 'island',
  countryCode: 'KY',
  languages: ['en'],
  zipcodePattern: 'KY\\d-\\d{4}',
}
