/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import getDirection from '../getDirection.js'

const DirectionInjector = () => {
  const direction = getDirection()

  document.documentElement.style.setProperty('direction', direction)

  return null
}

export default DirectionInjector
