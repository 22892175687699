/* Copyright 2013 - 2024 Waiterio LLC */
import {
  setAccessTokenForClient,
  setAccessTokenCallbackForClient,
} from '@monorepo/client/accessToken.js'
import { setRefreshTokenForClient } from '@monorepo/client/refreshToken.js'
import setCookie from '@monorepo/shared/setCookie.js'
import getAccessTokenPayload from '@monorepo/session/getAccessTokenPayload.js'
import setAccessToken from '@monorepo/session/setAccessToken.js'
import setRefreshToken from '@monorepo/session/setRefreshToken.js'

export default async function storeNewSession(loginResponse) {
  const { accessToken, refreshToken } = loginResponse
  try {
    setAccessToken(accessToken)
    setRefreshToken(refreshToken)

    setAccessTokenForClient(accessToken, setAccessToken)
    setRefreshTokenForClient(refreshToken, setRefreshToken)
    setAccessTokenCallbackForClient(setAccessToken)

    let accessTokenPayload = getAccessTokenPayload()

    setCookie('globoUserId', accessTokenPayload?.metadata?.userId)
    setCookie('globoSignature', accessTokenPayload?.metadata?.globoSignature)

    return true
  } catch (error) {
    console.error('error', error)
    throw error
  }
}
