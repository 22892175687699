/* Copyright 2013 - 2024 Waiterio LLC */
import uuid from '@monorepo/shared/uuid.js'

function Role(source) {
  if (source instanceof Role) {
    return source
  } else {
    Object.keys(source || {}).forEach(key => {
      this[key] = source[key]
    })

    if (!this._id) {
      this._id = uuid()
    }

    if (!this.creationTime) {
      this.creationTime = new Date().toISOString()
    }

    if (!this.lastEditTime) {
      this.lastEditTime = this.creationTime
    }

    return this
  }
}

Role.prototype.constructor = Role

Role.name_ = 'Role'
Role.collection = Role.name_.toLowerCase() + 's'

export default Role
