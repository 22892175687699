/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import IconBrand from '@dododentist/shared/IconBrand.js'
import IllustrationDentist from '@dododentist/shared/IllustrationDentist.js'
import ForgotPasswordPage from '@stiloso/pages/ForgotPasswordPage.js'
import postForgotPassword from '../postForgotPassword.js'

export const paths = ['/forgotpassword', '/forgot-password']

const ForgotPasswordPageWrapper = () => {
  const resetPassword = async ({ email }) => {
    await postForgotPassword(email)
  }

  let brand = <IconBrand width={36} height={36} fontSize={36} />

  return (
    <ForgotPasswordPage
      brand={brand}
      resetPassword={resetPassword}
      illustration={<IllustrationDentist />}
    />
  )
}

export default ForgotPasswordPageWrapper
