/* Copyright 2013 - 2024 Waiterio LLC */

const getDayOfTheWeekForDateFns = dayOfTheWeek => {
  let dayOfTheWeekNumber = 0
  dayOfTheWeek &&= dayOfTheWeek.toLowerCase()

  switch (dayOfTheWeek) {
    case 'sunday':
      dayOfTheWeekNumber = 0
      break
    case 'monday':
      dayOfTheWeekNumber = 1
      break
    case 'tuesday':
      dayOfTheWeekNumber = 2
      break
    case 'wednesday':
      dayOfTheWeekNumber = 3
      break
    case 'thursday':
      dayOfTheWeekNumber = 4
      break
    case 'friday':
      dayOfTheWeekNumber = 5
      break
    case 'saturday':
      dayOfTheWeekNumber = 6
      break
    default:
      break
  }

  return dayOfTheWeekNumber
}

export default getDayOfTheWeekForDateFns
