/* Copyright 2013 - 2024 Waiterio LLC */
import get from './get.js'

export default function getClass(Class, documentId, organizationId) {
  let url = `${Class.collection}/${documentId}`

  if (organizationId) {
    url += `?organizationId=${organizationId}`
  }

  return get({
    url,
  }).catch(error => {
    let newError = new Error("Couldn't get {document}", {
      cause: error,
    })
    newError.variables = { document: Class.name_.toLowerCase() }
    throw newError
  })
}
