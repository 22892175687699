/* Copyright 2013 - 2024 Waiterio LLC */
import getBrowserLanguage from '@monorepo/shared/getBrowserLanguage.js'
// import device from './device/index.js'
// import getDisplay from '@monorepo/services/getDisplay.js'

export default function getInitialContext() {
  return {
    connection: {
      online: navigator.onLine,
    },
    data: {},
    // device,
    // display: getDisplay(),
    language: getBrowserLanguage(),
    session: {},
    countries: {},
  }
}
