/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  flag: '🇨🇦',
  countryName: 'Canada',
  phonePrefix: '+1',
  administrativeDivisionsNames: [
    'Alberta',
    'British Columbia',
    'Manitoba',
    'New Brunswick',
    'Newfoundland and Labrador',
    'Northwest Territories',
    'Nova Scotia',
    'Nunavut',
    'Ontario',
    'Prince Edward Island',
    'Quebec',
    'Saskatchewan',
    'Yukon',
  ],
  administrativeDivisionsType: 'province',
  countryCode: 'CA',
  languages: ['en', 'fr'],
  streetNumberBeforeStreetName: true,
  zipcodePattern:
    '[ABCEGHJKLMNPRSTVXY]\\d[ABCEGHJ-NPRSTV-Z] ?\\d[ABCEGHJ-NPRSTV-Z]\\d',
}
