/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  flag: '🇦🇷',
  countryName: 'Argentina',
  phonePrefix: '+54',
  administrativeDivisionsNames: [
    'Buenos Aires',
    'Catamarca',
    'Chaco',
    'Chubut',
    'Ciudad Autónoma de Buenos Aires',
    'Córdoba',
    'Corrientes',
    'Entre Ríos',
    'Formosa',
    'Jujuy',
    'La Pampa',
    'La Rioja',
    'Mendoza',
    'Misiones',
    'Neuquén',
    'Río Negro',
    'Salta',
    'San Juan',
    'San Luis',
    'Santa Cruz',
    'Santa Fe',
    'Santiago del Estero',
    'Tierra del Fuego',
    'Tucumán',
  ],
  administrativeDivisionsType: 'province',
  countryCode: 'AR',
  languages: ['es'],
  zipcodePattern: '((?:[A-HJ-NP-Z])?\\d{4})([A-Z]{3})?',
}
