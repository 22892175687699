/* Copyright 2013 - 2024 Waiterio LLC */
import getClass from '@monorepo/client/getClass.js'
import pick from '@monorepo/forage/pick.js'
import plant from '@monorepo/forage/plant.js'

const fetchClass = async (Class, documentId) => {
  let document = await getClass(Class, documentId)
  let oldDocument = await pick(Class, document._id)

  if (!oldDocument || document.lastEditTime > oldDocument.lastEditTime) {
    plant(Class, document)
  }
}

export default fetchClass
