/* Copyright 2013 - 2024 Waiterio LLC */

export default function getClass(classes, className) {
  let classes_ = classes?.default ? classes.default : classes
  let class_ = classes_.find(
    class_ =>
      class_.name_?.toLowerCase() === className.toLowerCase() ||
      class_.collection?.toLowerCase() === className.toLowerCase() ||
      class_.collection?.toLowerCase() === className.toLowerCase() + 's',
  )
  class_ = class_?.default ? class_.default : class_

  return class_
}
