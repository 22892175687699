/* Copyright 2013 - 2024 Waiterio LLC */
/* istanbul ignore file */

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export default months
