/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import clickable from '@stiloso/styles/clickable.js'

let actionbartitle = css([
  clickable,
  {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: 16,
    gap: 16,
  },
])

const ActionBarTitle = ({ children, onClick }) => (
  <div css={actionbartitle} onClick={onClick}>
    {children}
  </div>
)

export default ActionBarTitle
