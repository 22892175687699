/* Copyright 2013 - 2024 Waiterio LLC */
import deleteClassQuery from '@monorepo/client/deleteClassQuery.js'
import burn from '@monorepo/forage/burn.js'
import picks from '@monorepo/forage/picks.js'
import plant from '@monorepo/forage/plant.js'

const removeByQuery = async (Class, query) => {
  let documents = await picks(Class)
  documents = documents.filter(document => {
    let result = true
    let keys = Object.keys(query || {})
    let k = 0
    while (result && k < keys.length) {
      let key = keys[k]
      if (query[key] && document[key] !== query[key]) {
        result = false
      }
      k += 1
    }
    return result
  })
  await Promise.all(documents.map(document => burn(Class, document._id)))
  await deleteClassQuery(Class, query).catch(error => {
    documents.map(document => plant(Class, document))

    throw error
  })
}

export default removeByQuery
