/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  flag: '🇨🇱',
  countryName: 'Chile',
  phonePrefix: '+56',
  administrativeDivisionsNames: [
    'Antofagasta',
    'Araucanía',
    'Arica y Parinacota',
    'Atacama',
    'Aysén del General Carlos Ibáñez del Campo',
    'Biobío',
    'Coquimbo',
    "Libertador General Bernardo O'Higgins",
    'Los Lagos',
    'Los Ríos',
    'Magallanes y de la Antártica Chilena',
    'Maule',
    'Metropolitana de Santiago',
    'Tarapacá',
    'Valparaíso',
  ],
  administrativeDivisionsType: 'region',
  countryCode: 'CL',
  languages: ['es'],
  zipcodePattern: '\\d{7}',
}
