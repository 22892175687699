/* Copyright 2013 - 2024 Waiterio LLC */
import IS_DEVELOPMENT from './IS_DEVELOPMENT.js'
import IS_STAGING from './IS_STAGING.js'
import IS_TESTING from './IS_TESTING.js'
import IS_BROWSER from './IS_BROWSER.js'
import IS_NODE from './IS_NODE.js'

export default function getDododentistApiUrl() {
  let url = 'https://api.dododentist.com'

  if (
    IS_BROWSER &&
    window.DODODENTIST_API_URL === '{{{DODODENTIST_API_URL}}}'
  ) {
    delete window.DODODENTIST_API_URL
  }

  if (
    IS_NODE &&
    process.env.DODODENTIST_API_URL === '{{{DODODENTIST_API_URL}}}'
  ) {
    delete process.env.DODODENTIST_API_URL
  }

  if (IS_BROWSER && window.DODODENTIST_API_URL) {
    url = `${window.DODODENTIST_API_URL}`
  } else if (IS_NODE && process.env.DODODENTIST_API_URL) {
    url = process.env.DODODENTIST_API_URL
  } else if (IS_STAGING) {
    url = 'https://api.dododentist-staging.com'
  } else if (IS_DEVELOPMENT || IS_TESTING) {
    // url = 'https://api.dododentist-staging.com';
    // url = 'http://192.168.1.2:22300'
    url = 'http://localhost:22300'
  }

  // if (IS_STAGING || IS_DEVELOPMENT) {
  //   console.debug('Dododentist.url = ' + url)
  // }

  return url
}
