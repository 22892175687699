/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */

/* eslint-disable max-len */
import React, { Suspense, useEffect, useState } from 'react'
import { css, jsx } from '@emotion/react'
import {
  unstable_useBlocker as useBlocker,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import IS_DEVELOPMENT from '@monorepo/env/IS_DEVELOPMENT.js'
import getUserId from '@monorepo/session/getUserId.js'
import useLoggedInSession from '@monorepo/session/useLoggedInSession.js'
import { toast, toastError } from '@monorepo/shared/AndroidToast.js'
import camelCaseToCapitalized from '@monorepo/shared/camelCaseToCapitalized.js'
import uuid from '@monorepo/shared/uuid.js'
import addOrUpdate from '@monorepo/tractor/addOrUpdate.js'
import remove from '@monorepo/tractor/remove.js'
import Reminder from '@dododentist/model/Reminder.js'
import { useTranslation } from '@multilocale/react/index.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarButton from '@stiloso/components/AppBarButton.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import Button from '@stiloso/components/Button.js'
import ButtonBar from '@stiloso/components/ButtonBar.js'
import Dropdown from '@stiloso/components/Dropdown.js'
import ErrorBoundary from '@stiloso/components/ErrorBoundary.js'
import Spinner from '@stiloso/components/Spinner.js'
import IconDelete from '@stiloso/icons/IconDelete.js'
import dLgBlock from '@stiloso/styles/bootstrap/dLgBlock.js'
import dNone from '@stiloso/styles/bootstrap/dNone.js'
import card from '@stiloso/styles/card.js'
import clickable from '@stiloso/styles/clickable.js'
import input from '@stiloso/styles/input.js'
import section from '@stiloso/styles/section.js'
import Layout from '../components/Layout.js'
import SaveChangesPrompt from '../dialogs/SaveChangesPrompt.js'
import useClinic from '../hooks/useClinic.js'
import useReminder from '../hooks/useReminder.js'
import useUser from '../hooks/useUser.js'
import sendTestReminder from '../sendTestReminder.js'

export const paths = ['/clinics/:clinicId/reminders/:reminderId']

let timeRelationValues = [
  'before',
  'after',
  'previous day',
  'same day',
  'next day',
]

const ReminderPage = props => {
  let { action, clinic, reminder, user } = props
  useLoggedInSession()

  const navigate = useNavigate()
  const { t } = useTranslation()
  const [edited, setEdited] = useState(false)
  const [isLoading, setLoading] = useState(false)
  let [_id, setId] = useState(reminder?._id || uuid())
  let [timeAmount, setTimeAmount] = useState(24)
  let [timeRelation, setTimeRelation] = useState('before')
  let [timeUnit, setTimeUnit] = useState('hours')
  let [class_, setClass_] = useState('Appointment')
  let [event, setEvent] = useState('startTime')
  let [time, setTime] = useState('')
  let channelValues = ['email', 'sms']
  if (IS_DEVELOPMENT) {
    channelValues = ['email', 'sms', 'whatsapp']
  }
  let channelLabels = ['Send email', 'Send SMS']
  if (IS_DEVELOPMENT) {
    channelLabels = ['Send email', 'Send SMS', 'Send WhatsApp message']
  }
  let [channels, setChannels] = useState(channelValues)

  let timeRelationLabels = timeRelationValues.map(relation => t(relation))

  let timeUnitValues = ['minutes', 'hours', 'days', 'weeks', 'months']
  let timeUnitLabels = timeUnitValues.map(unit => t(unit))

  let classesValues = Object.keys(Reminder.class2events)
  let classesLabels = classesValues.map(class_ => t(class_))

  let eventsValues = Reminder.class2events[class_]
  let eventsLabels = eventsValues
    .map(event => camelCaseToCapitalized(event))
    .map(event => t(event))

  const changeTimeRelation = timeRelation => {
    setEdited(true)
    setTimeRelation(timeRelation)
  }

  const changeTimeUnit = timeUnit => {
    setEdited(true)
    setTimeUnit(timeUnit)
  }

  const changeTimeAmount = event => {
    setEdited(true)
    setTimeAmount(event.target.value)
  }

  const changeTime = event => {
    setEdited(true)
    setTime(event.target.value)
  }

  const changeClass_ = class_ => {
    setEdited(true)
    setClass_(class_)
  }

  const changeEvent = event => {
    setEdited(true)
    setEvent(event)
  }

  const toggleChannel = channel => {
    let newChannels

    if (channels.includes(channel)) {
      newChannels = channels.filter(c => c !== channel)
    } else {
      newChannels = [...channels, channel]
    }

    setEdited(true)
    setChannels(newChannels)
  }

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      edited && currentLocation.pathname !== nextLocation.pathname,
  )

  useEffect(() => {
    if (!edited) {
      blocker?.proceed?.()
    }
  }, [edited])

  const addOrUpdateReminder = async event_ => {
    event_?.preventDefault?.()

    try {
      let creationTime = new Date().toISOString()
      let lastEditTime = creationTime

      const newReminder = new Reminder({
        _id,
        creationTime,
        ...reminder,
        lastEditTime,
        organizationId: clinic.organizationId,
        clinicId: clinic._id,
        channels,
        class_,
        event,
        time,
        timeAmount,
        timeRelation,
        timeUnit,
      })

      if (newReminder.demo) {
        newReminder.demo = false
      }

      setLoading(true)
      await addOrUpdate(newReminder, reminder)

      setLoading(false)
      setEdited(false)
      navigate(`/clinics/${clinic._id}`)
    } catch (error) {
      setLoading(false)
      toastError(t(error))
    }
  }

  let closePrompt = () => {
    blocker.reset()
  }

  let discardChangesPrompt = () => {
    blocker.proceed()
  }

  let deleteReminder = async event => {
    event?.preventDefault?.()

    try {
      if (reminder) {
        await remove(reminder)
      }

      setEdited(false)
      navigate(`/clinics/${clinic._id}`)
    } catch (error) {
      toastError(t(error))
    }
  }

  const testReminder = async () => {
    let contacts = []

    if (channels.includes('email')) {
      contacts.push(user.email)
    }

    if (channels.includes('sms') || channels.includes('whatsapp')) {
      contacts.push(user.phone)
    }

    let emailOrPhone = contacts.join(' ')

    sendTestReminder({
      reminder: {
        _id,
        clinicId: clinic._id,
        organizationId: clinic.organizationId,
        time,
        timeAmount,
        timeRelation,
        timeUnit,
        class_,
        event,
        channels,
      },
    })
      .then(() => {
        toast(
          t('Reminder sent to {email_and_or_phone}').replace(
            '{email_and_or_phone}',
            emailOrPhone,
          ),
        )
      })
      .catch(error => toastError(error))
  }

  let saveChangesPrompt = async () => {
    await addOrUpdateReminder()
    blocker.proceed()
  }

  useEffect(() => {
    if (reminder) {
      setId(reminder._id)
      setChannels(reminder.channels || ['email', 'sms'])
      setClass_(reminder.class_ || 'Appointment')
      setEvent(reminder.event || 'startTime')
      setTime(reminder.time || '')
      setTimeAmount(reminder.timeAmount || 24)
      setTimeRelation(reminder.timeRelation || 'before')
      setTimeUnit(reminder.timeUnit || 'hours')
    }
  }, [reminder])

  useEffect(() => {
    if (action) {
      navigate('?action=')
    }

    if (action === 'delete') {
      deleteReminder()
    }
  }, [action])

  return (
    <>
      {blocker?.state === 'blocked' && (
        <SaveChangesPrompt
          close={closePrompt}
          discardChanges={discardChangesPrompt}
          saveChanges={saveChangesPrompt}
        />
      )}
      <div css={section}>
        <div css={[dNone, dLgBlock]}>
          <AppBar>
            <AppBarTitle>
              {reminder ? t('Reminder') : t('New reminder')}
            </AppBarTitle>
            <div css={{ flexGrow: 1 }} />
            {reminder && (
              <AppBarButton
                appearance="secondary"
                Icon={IconDelete}
                label={t('Delete')}
                onClick={deleteReminder}
              />
            )}
          </AppBar>
        </div>
        <form onSubmit={addOrUpdateReminder}>
          <div
            css={[
              card,
              {
                flexDirection: 'column',
                marginBottom: 16,
              },
            ]}
          >
            <label
              css={{
                display: 'block',
                paddingTop: 16,
                fontWeight: 'bold',
              }}
            >
              {t('When')}
            </label>
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 16,
              }}
            >
              {(timeRelation === 'after' || timeRelation === 'before') && (
                <>
                  <input
                    css={[
                      input,
                      {
                        height: '48px',
                        marginTop: 16,
                        width: 128,
                      },
                    ]}
                    type="number"
                    step="1"
                    placeholder="0"
                    value={timeAmount}
                    onChange={changeTimeAmount}
                  />

                  <Dropdown
                    labels={timeUnitLabels}
                    values={timeUnitValues}
                    currentValue={timeUnit}
                    onSelect={changeTimeUnit}
                  />
                </>
              )}
              <Dropdown
                labels={timeRelationLabels}
                values={timeRelationValues}
                currentValue={timeRelation}
                onSelect={changeTimeRelation}
              />
              {(timeRelation === 'previous day' ||
                timeRelation === 'same day' ||
                timeRelation === 'next day') && (
                <input
                  id="startTimeInput"
                  type="time"
                  css={[input, { marginTop: 16 }]}
                  value={time}
                  onChange={changeTime}
                  required
                />
              )}
            </div>

            <label
              css={{
                display: 'block',
                paddingTop: 16,
                fontWeight: 'bold',
              }}
            >
              {t('About')}
            </label>
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 16,
              }}
            >
              <Dropdown
                labels={classesLabels}
                values={classesValues}
                currentValue={class_}
                onSelect={changeClass_}
              />
              <Dropdown
                labels={eventsLabels}
                values={eventsValues}
                currentValue={event}
                onSelect={changeEvent}
              />
            </div>

            <label
              css={{
                display: 'block',
                paddingTop: 16,
                fontWeight: 'bold',
              }}
            >
              {t('How')}
            </label>
            <div css={{ display: 'flex' }}>
              {channelValues.map((channel, i) => {
                let isEnabled = channels.includes(channel)
                return (
                  <div
                    key={channel}
                    onClick={() => {
                      toggleChannel(channel)
                    }}
                    css={[
                      clickable,
                      {
                        padding: '16 16 16 0',
                        color: isEnabled
                          ? 'inherit'
                          : 'var(--color-placeholder)',
                        textDecoration: isEnabled ? 'inherit' : 'line-through',
                      },
                    ]}
                  >
                    {t(channelLabels[i])}
                  </div>
                )
              })}
            </div>
            <Button onClick={() => testReminder()}>Test</Button>
          </div>
          {(edited || !reminder) && (
            <ButtonBar
              appearance="secondary"
              type="submit"
              isLoading={isLoading}
            >
              {t('Save')}
            </ButtonBar>
          )}
        </form>
      </div>
    </>
  )
}

const ReminderPageConnected = () => {
  useLoggedInSession()
  const { clinicId, reminderId } = useParams()
  const [searchParams] = useSearchParams()
  const action = searchParams.get('action')
  const clinic = useClinic(clinicId)
  const reminder = useReminder(reminderId)
  let user = useUser(getUserId())

  return (
    <ReminderPage
      action={action}
      clinic={clinic}
      reminder={reminder}
      user={user}
    />
  )
}

const ReminderPageWrapper = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { clinicId, reminderId } = useParams()
  const isNewReminder = reminderId === 'new'
  let title = t(isNewReminder ? 'New reminder' : 'Reminder')
  const onClickTitle = () => {
    navigate(`/clinics/${clinicId}/reminders`)
  }
  const actions = [!isNewReminder && 'delete'].filter(_ => _)
  return (
    <Layout
      actions={actions}
      back
      main={false}
      title={title}
      onClickTitle={onClickTitle}
    >
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <ReminderPageConnected />
        </Suspense>
      </ErrorBoundary>
    </Layout>
  )
}

export default ReminderPageWrapper
