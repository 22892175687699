/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { Suspense } from 'react'
import { css, jsx } from '@emotion/react'
import { Link, useNavigate } from 'react-router-dom'
import camelCaseToCapitalized from '@monorepo/shared/camelCaseToCapitalized.js'
import { useTranslation } from '@multilocale/react/index.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarButton from '@stiloso/components/AppBarButton.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import ErrorBoundary from '@stiloso/components/ErrorBoundary.js'
import Spinner from '@stiloso/components/Spinner.js'
import card from '@stiloso/styles/card.js'
import clickable from '@stiloso/styles/clickable.js'
import useReminders from '../hooks/useReminders.js'

let reminderCard = css([
  card,
  clickable,
  {
    lineHeight: '48px',
    width: '100%',
    padding: '0 16px',
    display: 'flex',
    flexDirection: 'column',
  },
])

const ClinicRemindersSection = ({ addOrUpdate, clinicId }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  let reminders = useReminders({
    clinicId,
  })
  return (
    <>
      <AppBar>
        <AppBarTitle>{t('Reminders')}</AppBarTitle>
        <div css={{ flexGrow: 1 }} />
        <AppBarButton
          appearance="secondary"
          label={t('Add reminder')}
          onClick={async () => {
            await addOrUpdate()
            navigate(`/clinics/${clinicId}/reminders/new`)
          }}
        />
      </AppBar>
      {reminders.length > 0 && (
        <div
          css={{
            marginBottom: 16,
            display: 'flex',
            flexWrap: 'wrap',
            gap: 8,
          }}
        >
          {reminders?.map(reminder => {
            let {
              _id,
              class_,
              channels,
              event,
              time,
              timeAmount,
              timeRelation,
              timeUnit,
            } = reminder

            let description = ''

            if (timeAmount === 1) {
              timeUnit = timeUnit.replace(/s$/, '')
            }
            if (timeRelation === 'after' || timeRelation === 'before') {
              description += `${timeAmount} ${t(timeUnit)} ${t(timeRelation)} ${t(class_)} ${t(camelCaseToCapitalized(event))}`
            } else {
              description += ` ${t(timeRelation)} ${time}`
            }

            return (
              <Link
                key={_id}
                css={reminderCard}
                to={`/clinics/${clinicId}/reminders/${_id}`}
              >
                <div css={{ textTransform: 'lowercase' }}>{description}</div>
                <div css={{ display: 'flex' }}>
                  {channels?.map(channel => (
                    <span key={channel} css={{ marginInlineEnd: 8 }}>
                      {t(channel)}
                    </span>
                  ))}
                </div>
              </Link>
            )
          })}
        </div>
      )}
    </>
  )
}

const ClinicRemindersSectionWrapper = props => (
  <ErrorBoundary>
    <Suspense fallback={<Spinner />}>
      <ClinicRemindersSection {...props} />
    </Suspense>
  </ErrorBoundary>
)

export default ClinicRemindersSectionWrapper
