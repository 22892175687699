/* Copyright 2013 - 2024 Waiterio LLC */
import uuid from '@monorepo/shared/uuid.js'

function Image(source) {
  if (source instanceof Image) {
    return source
  } else {
    Object.keys(source || {}).forEach(key => {
      this[key] = source[key]
    })

    if (!this._id) {
      this._id = uuid()
    }

    if (!this.creationTime) {
      this.creationTime = new Date().toISOString()
    }

    if (!this.lastEditTime) {
      this.lastEditTime = this.creationTime
    }

    return this
  }
}

Image.prototype.constructor = Image

Image.name_ = 'Image'
Image.collection = Image.name_.toLowerCase() + 's'

export default Image
