/* Copyright 2013 - 2024 Waiterio LLC */
export default function getCurrencyByCountry(countryCode) {
  /* istanbul ignore next */
  switch (countryCode) {
    case 'AF':
      return 'AFN'
    case 'AL':
      return 'ALL'
    case 'DZ':
      return 'DZD'
    case 'AS':
      return 'USD'
    case 'AD':
      return 'EUR'
    case 'AO':
      return 'AOA'
    case 'AI':
      return 'XCD'
    case 'AG':
      return 'XCD'
    case 'AR':
      return 'ARS'
    case 'AM':
      return 'AMD'
    case 'AW':
      return 'AWG'
    case 'AU':
      return 'AUD'
    case 'AT':
      return 'EUR'
    case 'AZ':
      return 'AZN'
    case 'BS':
      return 'BSD'
    case 'BH':
      return 'BHD'
    case 'BD':
      return 'BDT'
    case 'BB':
      return 'BBD'
    case 'BY':
      return 'BYN'
    case 'BE':
      return 'EUR'
    case 'BZ':
      return 'BZD'
    case 'BJ':
      return 'XOF'
    case 'BM':
      return 'BMD'
    case 'BT':
      return 'INR'
    case 'BO':
      return 'BOB'
    case 'BA':
      return 'BAM'
    case 'BW':
      return 'BWP'
    case 'BR':
      return 'BRL'
    case 'VG':
      return 'USD'
    case 'BN':
      return 'BND'
    case 'BG':
      return 'BGN'
    case 'BF':
      return 'XOF'
    case 'BI':
      return 'BIF'
    case 'KH':
      return 'KHR'
    case 'CM':
      return 'XAF'
    case 'CA':
      return 'CAD'
    case 'CV':
      return 'CVE'
    case 'BQ':
      return 'USD'
    case 'KY':
      return 'KYD'
    case 'CF':
      return 'XAF'
    case 'TD':
      return 'XAF'
    case 'CL':
      return 'CLP'
    case 'CN':
      return 'CNY'
    case 'CO':
      return 'COP'
    case 'KM':
      return 'KMF'
    case 'CG':
      return 'XAF'
    case 'CD':
      return 'CDF'
    case 'CK':
      return 'NZD'
    case 'CR':
      return 'CRC'
    case 'HR':
      return 'HRK'
    case 'CU':
      return 'CUP'
    case 'CW':
      return 'USD'
    case 'CY':
      return 'EUR'
    case 'CZ':
      return 'CZK'
    case 'CI':
      return 'XOF'
    case 'DK':
      return 'DKK'
    case 'DJ':
      return 'DJF'
    case 'DM':
      return 'XCD'
    case 'DO':
      return 'DOP'
    case 'EC':
      return 'USD'
    case 'EG':
      return 'EGP'
    case 'SV':
      return 'USD'
    case 'GQ':
      return 'XAF'
    case 'ER':
      return 'ERN'
    case 'EE':
      return 'EUR'
    case 'ET':
      return 'ETB'
    case 'FK':
      return 'FKP'
    case 'FJ':
      return 'FJD'
    case 'FI':
      return 'EUR'
    case 'FR':
      return 'EUR'
    case 'GF':
      return 'EUR'
    case 'PF':
      return 'XPF'
    case 'GA':
      return 'XAF'
    case 'GM':
      return 'GMD'
    case 'GE':
      return 'GEL'
    case 'DE':
      return 'EUR'
    case 'GH':
      return 'GHS'
    case 'GI':
      return 'GIP'
    case 'GR':
      return 'EUR'
    case 'GL':
      return 'DKK'
    case 'GD':
      return 'XCD'
    case 'GP':
      return 'EUR'
    case 'GU':
      return 'USD'
    case 'GT':
      return 'GTQ'
    case 'GG':
      return 'GBP'
    case 'GN':
      return 'GNF'
    case 'GW':
      return 'XOF'
    case 'GY':
      return 'GYD'
    case 'HT':
      return 'HTG'
    case 'HN':
      return 'HNL'
    case 'HK':
      return 'HKD'
    case 'HU':
      return 'HUF'
    case 'IS':
      return 'ISK'
    case 'IN':
      return 'INR'
    case 'ID':
      return 'IDR'
    case 'IR':
      return 'IRR'
    case 'IQ':
      return 'IQD'
    case 'IE':
      return 'EUR'
    case 'IM':
      return 'GBP'
    case 'IL':
      return 'ILS'
    case 'IT':
      return 'EUR'
    case 'JM':
      return 'JMD'
    case 'JP':
      return 'JPY'
    case 'JE':
      return 'GBP'
    case 'JO':
      return 'JOD'
    case 'KZ':
      return 'KZT'
    case 'KE':
      return 'KES'
    case 'KI':
      return 'AUD'
    case 'KW':
      return 'KWD'
    case 'KG':
      return 'KGS'
    case 'LA':
      return 'LAK'
    case 'LV':
      return 'EUR'
    case 'LB':
      return 'LBP'
    case 'LS':
      return 'ZAR'
    case 'LR':
      return 'LRD'
    case 'LY':
      return 'LYD'
    case 'LI':
      return 'CHF'
    case 'LT':
      return 'EUR'
    case 'LU':
      return 'EUR'
    case 'MO':
      return 'MOP'
    case 'MK':
      return 'MKD'
    case 'MG':
      return 'MGA'
    case 'MW':
      return 'MWK'
    case 'MY':
      return 'MYR'
    case 'MV':
      return 'MVR'
    case 'ML':
      return 'XOF'
    case 'MT':
      return 'EUR'
    case 'MH':
      return 'USD'
    case 'MQ':
      return 'EUR'
    case 'MR':
      return 'MRU'
    case 'MU':
      return 'MUR'
    case 'YT':
      return 'EUR'
    case 'MX':
      return 'MXN'
    case 'FM':
      return 'USD'
    case 'MD':
      return 'MDL'
    case 'MC':
      return 'EUR'
    case 'MN':
      return 'MNT'
    case 'ME':
      return 'EUR'
    case 'MS':
      return 'XCD'
    case 'MA':
      return 'MAD'
    case 'MZ':
      return 'MZN'
    case 'MM':
      return 'MMK'
    case 'NA':
      return 'NAD'
    case 'NR':
      return 'AUD'
    case 'NP':
      return 'NPR'
    case 'NL':
      return 'EUR'
    case 'NC':
      return 'XPF'
    case 'NZ':
      return 'NZD'
    case 'NI':
      return 'NIO'
    case 'NE':
      return 'XOF'
    case 'NG':
      return 'NGN'
    case 'NU':
      return 'NZD'
    case 'NF':
      return 'AUD'
    case 'MP':
      return 'USD'
    case 'NO':
      return 'NOK'
    case 'OM':
      return 'OMR'
    case 'PK':
      return 'PKR'
    case 'PW':
      return 'USD'
    case 'PA':
      return 'USD'
    case 'PG':
      return 'PGK'
    case 'PY':
      return 'PYG'
    case 'PE':
      return 'PEN'
    case 'PH':
      return 'PHP'
    case 'PN':
      return 'NZD'
    case 'PL':
      return 'PLN'
    case 'PT':
      return 'EUR'
    case 'PR':
      return 'USD'
    case 'PS':
      return 'JOD'
    case 'QA':
      return 'QAR'
    case 'RO':
      return 'RON'
    case 'RU':
      return 'RUB'
    case 'RW':
      return 'RWF'
    case 'RE':
      return 'EUR'
    case 'WS':
      return 'WST'
    case 'SM':
      return 'EUR'
    case 'SA':
      return 'SAR'
    case 'SN':
      return 'XOF'
    case 'RS':
      return 'RSD'
    case 'SC':
      return 'SCR'
    case 'SL':
      return 'SLL'
    case 'SG':
      return 'SGD'
    case 'SX':
      return 'USD'
    case 'SK':
      return 'EUR'
    case 'SI':
      return 'EUR'
    case 'SB':
      return 'SBD'
    case 'SO':
      return 'SOS'
    case 'ZA':
      return 'ZAR'
    case 'KR':
      return 'KRW'
    case 'SS':
      return 'SSP'
    case 'ES':
      return 'EUR'
    case 'LK':
      return 'LKR'
    case 'BL':
      return 'EUR'
    case 'SH':
      return 'SHP'
    case 'KN':
      return 'XCD'
    case 'LC':
      return 'XCD'
    case 'MF':
      return 'EUR'
    case 'PM':
      return 'EUR'
    case 'VC':
      return 'XCD'
    case 'SD':
      return 'SDG'
    case 'SR':
      return 'SRD'
    case 'SJ':
      return 'NOK'
    case 'SZ':
      return 'SZL'
    case 'SE':
      return 'SEK'
    case 'CH':
      return 'CHF'
    case 'SY':
      return 'SYP'
    case 'ST':
      return 'STD'
    case 'TJ':
      return 'TJS'
    case 'TZ':
      return 'TZS'
    case 'TH':
      return 'THB'
    case 'TL':
      return 'USD'
    case 'TG':
      return 'XOF'
    case 'TK':
      return 'NZD'
    case 'TO':
      return 'TOP'
    case 'TT':
      return 'TTD'
    case 'TN':
      return 'TND'
    case 'TR':
      return 'TRY'
    case 'TM':
      return 'TMT'
    case 'TC':
      return 'USD'
    case 'TV':
      return 'AUD'
    case 'TW':
      return 'TWD'
    case 'VI':
      return 'USD'
    case 'GB':
      return 'GBP'
    case 'US':
      return 'USD'
    case 'UG':
      return 'UGX'
    case 'UA':
      return 'UAH'
    case 'AE':
      return 'AED'
    case 'UY':
      return 'UYU'
    case 'UZ':
      return 'UZS'
    case 'VU':
      return 'VUV'
    case 'VA':
      return 'EUR'
    case 'VE':
      return 'VEF'
    case 'VN':
      return 'VND'
    case 'WF':
      return 'XPF'
    case 'EH':
      return 'MAD'
    case 'YE':
      return 'YER'
    case 'ZM':
      return 'ZMW'
    case 'ZW':
      return 'ZWL'
    case 'AX':
      return 'EUR'

    default:
      return null
  }
}
