/* Copyright 2013 - 2024 Waiterio LLC */
import React, { Suspense } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { createRoot } from 'react-dom/client' // eslint-disable-line import/extensions
import { setApiUrl } from '@monorepo/client/apiUrl.js'
import { Provider } from '@monorepo/context/index.js'
import listenForConnectionChanges from '@monorepo/services/listenForConnectionChanges.js'
import listenForUpdates from '@monorepo/services/listenForUpdates.js'
import listenForWindowResize from '@monorepo/services/listenForWindowResize.js'
import SessionRehydrator from '@monorepo/session/SessionRehydrator.js'
import allowSubdomainsCrossOrigin from '@monorepo/shared/allowSubdomainsCrossOrigin.js'
import nativeVersion from '@monorepo/shared/device/nativeVersion.js'
import getSentryBrowser from '@monorepo/telemetry/getSentryBrowser.js'
import classes from '@dododentist/model/classes.js'
import getApiUrl from '@dododentist/shared/getApiUrl.js'
import GlobalStyles from '@dododentist/shared/GlobalStyles.js'
import pusherAppKey from '@dododentist/shared/pusherAppKey.js'
import { initMultilocale } from '@multilocale/react/index.js'
import ErrorBoundary from '@stiloso/components/ErrorBoundary.js'
import ScrollToTop from '@stiloso/components/ScrollToTop.js'
import Spinner from '@stiloso/components/Spinner.js'
import StageBadge from '@stiloso/components/StageBadge.js'
import DirectionInjector from './components/DirectionInjector.js'
import DialogsEnforcer from './dialogs/DialogsEnforcer.js'
import getInitialContext from './getInitialContext.js'
import identify from './identify.js'
import LanguageSetter from './LanguageSetter.js'
import Routes from './Routes.js'

setApiUrl(getApiUrl())
allowSubdomainsCrossOrigin()
initMultilocale({
  organizationId: 'e395dba6a14d8af4c2d35890',
  project: 'dododentist-app',
})

const initialContext = getInitialContext()

function onContextProvided() {
  listenForUpdates()
  listenForConnectionChanges()
  listenForWindowResize()

  if (typeof window !== 'undefined' && nativeVersion) {
    window.$globo ||= []
    window.$globo.push(['setVersionNative', nativeVersion])
  }
}

const Root = () => (
  <ErrorBoundary>
    <Provider initialContext={initialContext} callback={onContextProvided}>
      <SessionRehydrator
        classes={classes}
        identify={identify}
        pusherAppKey={pusherAppKey}
      >
        <Suspense fallback={<Spinner />}>
          <LanguageSetter />
          <ScrollToTop />
          <GlobalStyles />
          <DirectionInjector />
          <Routes />
          <DialogsEnforcer />
          <StageBadge />
        </Suspense>
      </SessionRehydrator>
    </Provider>
  </ErrorBoundary>
)

const router = createBrowserRouter([{ path: '*', Component: Root }])

createRoot(document.getElementById('root')).render(
  <RouterProvider router={router} />,
)

getSentryBrowser()
