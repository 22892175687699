/* Copyright 2013 - 2024 Waiterio LLC */
import putClass from '@monorepo/client/putClass.js'
import plant from '@monorepo/forage/plant.js'

const update = async (newDocument, oldDocument) => {
  // console.log('update', newDocument, oldDocument)
  let Class = Object.getPrototypeOf(newDocument).constructor
  newDocument.lastEditTime = new Date().toISOString()
  newDocument = await plant(Class, newDocument)
  if (newDocument) {
    return putClass(Class, newDocument).catch(error => {
      plant(Class, oldDocument)

      throw error
    })
  }
}

export default update
