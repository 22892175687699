/* Copyright 2013 - 2024 Waiterio LLC */
import get from './get.js'

export default function getClassMany(Class, parameters) {
  let url = `${Class.collection}`

  if (parameters) {
    url += '?' + new URLSearchParams(parameters).toString()
  }

  return get({
    url,
  }).catch(error => {
    let newError = new Error("Couldn't get {document}", {
      cause: error,
    })
    newError.variables = { document: Class.name_.toLowerCase() }
    throw newError
  })
}
