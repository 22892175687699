/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import { isBefore, subDays, subHours } from 'date-fns'
import getUserId from '@monorepo/session/getUserId.js'
import CountryDialog from './CountryDialog.js'
import OpeningHoursDialog from './OpeningHoursDialog.js'
import RateAppDialog from './RateAppDialog.js'
import UserPhoneDialog from './UserPhoneDialog.js'
import useClinics from '../hooks/useClinics.js'
import useUser from '../hooks/useUser.js'

const isUserCreationTimeOlderThanXDays = (user, days) => {
  let result = false
  if (user?.creationTime) {
    const dateToCompare = new Date(user.creationTime)
    const threeDaysAgo = subDays(new Date(), days)
    result = isBefore(dateToCompare, threeDaysAgo)
  }
  return result
}

const isRateAppDialogShowLastTimeOldEnough = user => {
  let result = true
  if (user?.rateAppDialogShowLastTime) {
    const dateToCompare = new Date(user.rateAppDialogShowLastTime)
    const sevenDaysAgo = subDays(new Date(), 7)
    const oneHourAgo = subHours(new Date(), 1)
    result =
      isBefore(dateToCompare, sevenDaysAgo) ||
      isBefore(oneHourAgo, dateToCompare)
  }
  return result
}

const useShowCountryDialog = () => {
  let showCountryDialog = false

  let clinics = useClinics()

  if (clinics?.length > 0) {
    let c = 0
    while (!showCountryDialog && c < clinics.length) {
      let clinic = clinics[c]

      if (!clinic.countryCode) {
        showCountryDialog = true
      }
      c += 1
    }
  }

  return showCountryDialog
}

const useShowRateAppDialog = () => {
  let showRateAppDialog = false

  let userId
  try {
    userId = getUserId()
  } catch (error) {
    // DO NOTHING ON PURPOSE
  }

  let user = useUser(userId)

  if (user) {
    let userOldEnough = isUserCreationTimeOlderThanXDays(user, 3)
    let rateAppDialogOldEnough = isRateAppDialogShowLastTimeOldEnough(user)

    showRateAppDialog =
      userOldEnough && rateAppDialogOldEnough && !user.ratedApp
  }

  return showRateAppDialog
}

// const useShowOpeningHoursDialog = () => {
//   let showOpeningHoursDialog = false

//   let userId
//   try {
//     userId = getUserId()
//   } catch (error) {
//     // DO NOTHING ON PURPOSE
//   }

//   let user = useUser(userId)

//   let clinics = useClinics()

//   if (user) {
//     let userOldEnough = isUserCreationTimeOlderThanXDays(user, 7)

//     if (userOldEnough && clinics?.length > 0) {
//       let c = 0
//       while (!showOpeningHoursDialog && c < clinics.length) {
//         let clinic = clinics[c]
//         let openingHours = clinic.openingHours || []
//         openingHours = openingHours.filter(({ demo }) => !demo)

//         if (openingHours.length === 0) {
//           showOpeningHoursDialog = true
//         }
//         c += 1
//       }
//     }
//   }

//   return showOpeningHoursDialog
// }

const useShowUserPhoneDialog = () => {
  let showUserPhoneDialog = false

  let userId
  try {
    userId = getUserId()
  } catch (error) {
    // DO NOTHING ON PURPOSE
  }

  let user = useUser(userId)
  if (user && !user.phone) {
    showUserPhoneDialog = true
  }

  return showUserPhoneDialog
}

const DialogsEnforcer = () => {
  let showCountryDialog = useShowCountryDialog()
  let showOpeningHoursDialog = false // useShowOpeningHoursDialog()
  let showRateAppDialog = useShowRateAppDialog()
  let showUserPhoneDialog = useShowUserPhoneDialog()

  if (showRateAppDialog) {
    return <RateAppDialog />
  } else if (showCountryDialog) {
    return <CountryDialog />
  } else if (showOpeningHoursDialog) {
    return <OpeningHoursDialog />
  } else if (showUserPhoneDialog) {
    return <UserPhoneDialog />
  } else {
    return null
  }
}

export default DialogsEnforcer
