/* Copyright 2013 - 2024 Waiterio LLC */
import uuid from '@monorepo/shared/uuid.js'

function Reminder(source) {
  if (source instanceof Reminder) {
    return source
  } else {
    Object.keys(source || {}).forEach(key => {
      this[key] = source[key]
    })

    if (!this._id) {
      this._id = uuid()
    }

    if (!this.creationTime) {
      this.creationTime = new Date().toISOString()
    }

    if (!this.lastEditTime) {
      this.lastEditTime = this.creationTime
    }

    return this
  }
}

Reminder.prototype.constructor = Reminder

Reminder.name_ = 'Reminder'
Reminder.collection = Reminder.name_.toLowerCase() + 's'

Reminder.class2events = {
  Appointment: ['creationTime', 'startTime', 'endTime'],
  // Patient: ['creationTime'],
  // Payment: ['creationTime'],
  // Invoice: ['creationTime', 'dueTime'],
}

Reminder.classes = Object.keys(Reminder.class2events)

export default Reminder
