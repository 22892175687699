/* Copyright 2013 - 2024 Waiterio LLC */
import delete_ from './delete_.js'

export default function deleteClassQuery(Class, query) {
  let url = Class.collection
  url += '?' + new URLSearchParams(query).toString()
  return delete_({
    url,
  }).catch(error => {
    throw new Error(`Couldn't delete ${Class.name_.toLowerCase()}: ${error}`)
  })
}
