/* Copyright 2013 - 2024 Waiterio LLC */
export default function compareSemanticVersions(a, b) {
  if (a === b) {
    return 0
  }

  let aComponents = a.split('.')
  let bComponents = b.split('.')

  let len = Math.min(aComponents.length, bComponents.length)

  // loop while the components are equal
  for (let i = 0; i < len; i += 1) {
    // A bigger than B
    if (parseInt(aComponents[i], 10) > parseInt(bComponents[i], 10)) {
      return 1
    }

    // B bigger than A
    if (parseInt(aComponents[i], 10) < parseInt(bComponents[i], 10)) {
      return -1
    }
  }

  // If one's a prefix of the other, the longer one is greater.
  if (aComponents.length > bComponents.length) {
    return 1
  }

  if (aComponents.length < bComponents.length) {
    return -1
  }

  // Otherwise they are the same.
  return 0
}
