/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { Suspense, useState } from 'react'
import { css, jsx } from '@emotion/react'
import { Link } from 'react-router-dom'
import getOrganizationId from '@monorepo/session/getOrganizationId.js'
import getRoles from '@monorepo/session/getRoles.js'
import getUserId from '@monorepo/session/getUserId.js'
import useLoggedInSession from '@monorepo/session/useLoggedInSession.js'
import convertStringToColor from '@monorepo/shared/convertStringToColor.js'
import addOrUpdate from '@monorepo/tractor/addOrUpdate.js'
import remove from '@monorepo/tractor/remove.js'
import Invite from '@dododentist/model/Invite.js'
import { useTranslation } from '@multilocale/react/index.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarButton from '@stiloso/components/AppBarButton.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import DeleteDialog from '@stiloso/components/DeleteDialog.js'
import ErrorBoundary from '@stiloso/components/ErrorBoundary.js'
import ErrorDialog from '@stiloso/components/ErrorDialog.js'
import ImageGraceful from '@stiloso/components/ImageGraceful.js'
import Spinner from '@stiloso/components/Spinner.js'
import clickable from '@stiloso/styles/clickable.js'
import section from '@stiloso/styles/section.js'
import singleLine from '@stiloso/styles/singleLine.js'
import unselectable from '@stiloso/styles/unselectable.js'
import Layout from '../components/Layout.js'
import InviteTeamMemberDialog from '../dialogs/InviteTeamMemberDialog.js'
import useInvites from '../hooks/useInvites.js'
import useRoles from '../hooks/useRoles.js'
import useUsers from '../hooks/useUsers.js'

export const paths = ['/team']

const ROLES = ['doctor', 'nurse', 'admin', 'secretary', 'assistant', 'manager']

export const listItem = css`
  ${clickable}
  ${singleLine}
  ${unselectable}
  background-color: white;
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
  width: 100%;
`

const avatarContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  min-width: 48px;
  border-radius: 48px;
`

const Users = props => {
  let { canInviteTeamMember, roles, users } = props
  const { t } = useTranslation()

  const members = users.reduce((users, user) => {
    const userRoles = roles.filter(role => role.userId === user._id)

    if (userRoles.length) {
      const roleNames = userRoles.map(userRole => userRole.type)
      users.push({
        userId: user._id,
        email: user.email,
        roles: roleNames.map(roleName => t(roleName)).join(', '),
        initial: user.email?.charAt(0),
        color: convertStringToColor(user.email),
        avatarUrl: user.avatarUrl,
      })
    }

    return users
  }, [])

  return (
    <>
      {members.map(member => (
        <Link
          key={member.userId}
          to={`/team/${member.userId}`}
          css={[
            listItem,
            !canInviteTeamMember && {
              pointerEvents: 'none',
            },
          ]}
        >
          {member.avatarUrl && (
            <div css={avatarContainer}>
              <ImageGraceful
                css={{
                  aspectRatio: '1 / 1',
                  height: 48,
                  borderRadius: 1000,
                }}
                src={member.avatarUrl.replace('.jpg', '-256w.webp')}
                fallbackSrc={member.avatarUrl.replace('.jpg', '-256w.jpg')}
              />
            </div>
          )}

          {!member.avatarUrl && (
            <div
              css={[
                avatarContainer,
                {
                  color: 'white',
                  fontSize: 28,
                  background: member.color,
                  textTransform: 'capitalize',
                },
              ]}
            >
              {member.initial}
            </div>
          )}
          <div css={{ margin: 'auto 12px' }}>
            {member.email}
            <div css={{ marginTop: 8, fontWeight: '300' }}>{member.roles}</div>
          </div>
        </Link>
      ))}
    </>
  )
}

const Invites = ({ canInviteTeamMember }) => {
  const { t } = useTranslation()
  const invites = useInvites()
  const [deleteInviteDialog, setDeleteInviteDialog] = useState()
  const [error, setError] = useState()
  const [isLoading, setLoading] = useState(false)

  const showDeleteInviteDialog = invite => {
    setDeleteInviteDialog(invite)
  }

  const hideDeleteInviteDialog = () => {
    setDeleteInviteDialog(null)
  }

  const deleteInvite = async invite => {
    await remove(invite)
    setDeleteInviteDialog(false)
    setLoading(false)
  }

  return (
    <div>
      {invites?.length > 0 && (
        <>
          <h3 css={{ margin: '32 0 16 0' }}>{t('Invites')}</h3>
          {invites.map(invite => (
            <div
              key={invite._id}
              css={[
                listItem,
                !canInviteTeamMember && {
                  pointerEvents: 'none',
                },
              ]}
              onClick={() => showDeleteInviteDialog(invite)}
            >
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 48,
                  width: 48,
                  minWidth: 48,
                  borderRadius: 48,
                  color: 'white',
                  fontSize: 28,
                  background: convertStringToColor(invite.email),
                  textTransform: 'capitalize',
                }}
              >
                {invite.email?.charAt(0)}
              </div>
              <div css={{ margin: 'auto 12px' }}>
                {invite.email}
                <div css={{ marginTop: 8, fontWeight: '300' }}>
                  {t(invite.roleType)}
                </div>
              </div>
            </div>
          ))}
        </>
      )}

      {deleteInviteDialog && (
        <DeleteDialog
          title={t('Delete invite')}
          close={hideDeleteInviteDialog}
          message={t(
            'Do you want to delete the invite for {invitee_name}?',
          ).replace('{invitee_name}', deleteInviteDialog.email)}
          onDelete={() => deleteInvite(deleteInviteDialog)}
          loading={isLoading}
        />
      )}

      {error && <ErrorDialog error={error} close={setError(null)} />}
    </div>
  )
}

const TeamPageConnected = () => {
  useLoggedInSession()
  const { t } = useTranslation()
  const users = useUsers()
  const roles = useRoles()

  const [inviteTeamMemberDialog, setInviteTeamMemberDialog] = useState(false)

  const canInviteTeamMember = !!getRoles().filter(
    ({ type }) => type === 'admin',
  ).length

  const showInviteTeamMemberDialog = () => {
    setInviteTeamMemberDialog(true)
  }

  const hideInviteTeamMemberDialog = () => {
    setInviteTeamMemberDialog(false)
  }

  const addInvite = invite => {
    let newInvite = new Invite({
      ...invite,
      organizationId: getOrganizationId(),
    })
    return addOrUpdate(newInvite)
  }

  return (
    <>
      <div css={section}>
        <AppBar>
          <AppBarTitle>{t('Team')}</AppBarTitle>
          <div css={{ flexGrow: 1 }} />

          {canInviteTeamMember && (
            <AppBarButton
              label={t('Invite team member')}
              onClick={showInviteTeamMemberDialog}
            />
          )}
        </AppBar>
        <div css={{ margin: '16 0' }}>
          <ErrorBoundary>
            <Suspense fallback={<Spinner />}>
              <Users
                canInviteTeamMember={canInviteTeamMember}
                roles={roles}
                users={users}
              />
            </Suspense>
          </ErrorBoundary>
          <ErrorBoundary>
            <Suspense fallback={<Spinner />}>
              <Invites canInviteTeamMember={canInviteTeamMember} />
            </Suspense>
          </ErrorBoundary>
        </div>
      </div>
      {inviteTeamMemberDialog && (
        <InviteTeamMemberDialog
          close={hideInviteTeamMemberDialog}
          roles={ROLES}
          addInvite={addInvite}
          userId={getUserId()}
        />
      )}
    </>
  )
}

const TeamPageWrapper = () => {
  const { t } = useTranslation()
  return (
    <Layout title={t('Team')}>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <TeamPageConnected />
        </Suspense>
      </ErrorBoundary>
    </Layout>
  )
}

export default TeamPageWrapper
