/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */

/* eslint-disable max-len */
import React, { Suspense } from 'react'
import { css, jsx } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import useLoggedInSession from '@monorepo/session/useLoggedInSession.js'
import { useTranslation } from '@multilocale/react/index.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarButton from '@stiloso/components/AppBarButton.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import ErrorBoundary from '@stiloso/components/ErrorBoundary.js'
import Spinner from '@stiloso/components/Spinner.js'
import card from '@stiloso/styles/card.js'
import clickable from '@stiloso/styles/clickable.js'
import useImages from '../hooks/useImages.js'

const ImagesSection = ({ addOrUpdate, clinicId, images, patientId }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <>
      <AppBar>
        <AppBarTitle>{t('Images')}</AppBarTitle>
        <div css={{ flexGrow: 1 }} />
        <AppBarButton
          appearance="secondary"
          label={t('Add image')}
          onClick={async () => {
            let document = await addOrUpdate()
            let patientId_ =
              patientId || document?.patientId || document._id || ''
            navigate(`/clinics/${clinicId}/images/new?patientId=${patientId_}`)
          }}
        />
      </AppBar>
      {images.length > 0 && (
        <div
          css={{
            padding: '0 16',
            marginBottom: 16,
            display: 'flex',
            flexWrap: 'wrap',
            gap: 8,
          }}
        >
          {patientId &&
            images
              ?.filter(({ url }) => url)
              ?.map(({ _id, name, url }, i) => (
                <div
                  key={_id}
                  value={name}
                  css={[
                    clickable,
                    card,
                    {
                      lineHeight: '48px',
                      padding: '0',
                      marginBottom: 8,
                    },
                  ]}
                  onClick={() => navigate(`/clinics/${clinicId}/images/${_id}`)}
                >
                  <img
                    src={url}
                    css={{ height: 56, borderRadius: 4 }}
                    alt={name || `${t('Patient')} t('Image')} ${i + 1}`}
                  />
                </div>
              ))}
        </div>
      )}
    </>
  )
}

const ImagesSectionConnected = ({ addOrUpdate, clinicId, patientId }) => {
  useLoggedInSession()
  const images = useImages({ clinicId, patientId })

  return (
    <ImagesSection
      addOrUpdate={addOrUpdate}
      clinicId={clinicId}
      images={images}
      patientId={patientId}
    />
  )
}

const ImagesSectionWrapper = props => (
  <ErrorBoundary>
    <Suspense fallback={<Spinner />}>
      <ImagesSectionConnected {...props} />
    </Suspense>
  </ErrorBoundary>
)

export default ImagesSectionWrapper
