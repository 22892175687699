/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'

let actionbar = css({
  display: 'flex',
  flexWrap: 'nowrap',
  position: 'fixed',
  width: '100%',
  padding: '10px 100px',
  backgroundColor: 'var(--color-primary)',
  color: 'white',
  fill: 'white',
  alignItems: 'center',
  boxSizing: 'border-box',
  justifyContent: 'space-between',
  transition: 'all .5s ease-in-out',
  zIndex: 1000,
  '@media(max-width:1199px)': {
    padding: '10px 40px',
  },
  '@media(max-width:992px)': {
    padding: 0,
    height: 64,
  },
})

const ActionBar = ({ children }) => <div css={actionbar}>{children}</div>

export default ActionBar
