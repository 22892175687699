/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint-disable camelcase */
import { produce } from '@monorepo/context/index.js'
import ENVIRONMENT from '@monorepo/env/ENVIRONMENT.js'
import IS_PRODUCTION from '@monorepo/env/IS_PRODUCTION.js'
import burn from '@monorepo/forage/burn.js'
import plant from '@monorepo/forage/plant.js'
import getClass from '@monorepo/model/getClass.js'
import getOrganizationId from '@monorepo/session/getOrganizationId.js'
import fetchClass from '@monorepo/tractor/fetchClass.js'
import getPusher from './getPusher.js'

let channel = null
let channelName = null
let previous_previous = null
let classes_

const handleAddedEvent = addedEvent => {
  if (
    addedEvent.organizationId &&
    addedEvent.organizationId === getOrganizationId()
  ) {
    let classNameUppercase = addedEvent.type?.replace('_ADDED_EVENT', '')
    let classNameLowercase = classNameUppercase.toLowerCase()
    let Class = getClass(classes_, classNameUppercase)
    let document = addedEvent[classNameLowercase]
    let documentId = addedEvent[`${classNameLowercase}Id`]

    if (document) {
      plant(Class, document)
    } else if (documentId) {
      fetchClass(Class, documentId)
    }
  }
}

const handleDeletedEvent = deletedEvent => {
  if (
    deletedEvent.organizationId &&
    deletedEvent.organizationId === getOrganizationId()
  ) {
    let classNameUppercase = deletedEvent.type?.replace('_REMOVED_EVENT', '')
    let classNameLowercase = classNameUppercase.toLowerCase()
    let Class = getClass(classes_, classNameUppercase)
    let documentId = deletedEvent[`${classNameLowercase}Id`]

    burn(Class, documentId)
  }
}

const handleUpdatedEvent = updatedEvent => {
  if (
    updatedEvent.organizationId &&
    updatedEvent.organizationId === getOrganizationId()
  ) {
    let classNameUppercase = updatedEvent.type?.replace('_UPDATED_EVENT', '')
    let classNameLowercase = classNameUppercase.toLowerCase()
    let Class = getClass(classes_, classNameUppercase)
    let document = updatedEvent[classNameLowercase]
    let documentId = updatedEvent[`${classNameLowercase}Id`]

    if (document) {
      plant(Class, document)
    } else {
      fetchClass(Class, documentId)
    }
  }
}

// you can debug pusher events by uncommenting Pusher.log in getPusher.js
const subscribesPusher = ({ classes, pusherAppKey }) => {
  classes_ = classes
  const organizationId = getOrganizationId()
  const pusher = getPusher(pusherAppKey)

  if (organizationId) {
    if (channel && channelName) {
      pusher.unsubscribe(channelName)
      channel = null
      channelName = null
    }

    pusher.connection.bind('state_change', pusherStates => {
      pusherStates.previous_previous = previous_previous

      // console.log(pusherStates)

      produce(draft => {
        draft.connection.pusherStates = pusherStates
      })

      previous_previous = pusherStates.previous
    })

    channelName = 'organization_' + organizationId

    if (!IS_PRODUCTION) {
      channelName += '_' + ENVIRONMENT
    }

    channel = pusher.subscribe(channelName)

    classes.forEach(class_ => {
      let classNameUppercase = class_.name_.toUpperCase()
      channel.bind(`${classNameUppercase}_ADDED_EVENT`, handleAddedEvent)
      channel.bind(`${classNameUppercase}_REMOVED_EVENT`, handleDeletedEvent)
      channel.bind(`${classNameUppercase}_UPDATED_EVENT`, handleUpdatedEvent)
    })
  }
}

export default subscribesPusher
