/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'

const IconAgenda = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-agenda'

  return (
    <svg
      viewBox="0 -960 960 960"
      width={width}
      height={height}
      className={className}
      style={style}
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm360-80h100v-480H520v480Zm-180 0h100v-480H340v480Zm-180 0h100v-480H160v480Zm540 0h100v-480H700v480Z" />
    </svg>
  )
}

export default IconAgenda
