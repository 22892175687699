/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useState } from 'react'
import { css, jsx } from '@emotion/react'
import getUserId from '@monorepo/session/getUserId.js'
import { toastError } from '@monorepo/shared/AndroidToast.js'
import addOrUpdate from '@monorepo/tractor/addOrUpdate.js'
import User from '@dododentist/model/User.js'
import { useTranslation } from '@multilocale/react/index.js'
import useCurrentCountry from '@multilocale/react/useCurrentCountry.js'
import Button from '@stiloso/components/Button.js'
import Modal from '@stiloso/components/Modal.js'
import PhoneInput from '@stiloso/components/PhoneInput.js'
import label from '@stiloso/styles/label.js'
import useUser from '../hooks/useUser.js'

const UserPhoneDialog = () => {
  const { t } = useTranslation()

  const user = useUser(getUserId())

  let [, setShow] = useState(true)
  let [phone, setPhone] = useState(user?.phone || '')
  const currentCountry = useCurrentCountry()

  if (!phone?.length > 0) {
    phone = currentCountry?.phonePrefix
  }

  const hideUserPhoneDialog = () => {
    setShow(false)
  }

  const addOrUpdateUser = event => {
    event?.preventDefault?.()

    let lastEditTime = new Date().toISOString()
    phone = phone?.replace(/[ \\(\\)\\-]/g, '')
    let newUser = new User({
      ...user,
      lastEditTime,
      phone,
    })

    addOrUpdate(newUser, user)
      .then(() => {
        hideUserPhoneDialog()
      })
      .catch(error => {
        console.error(error)
        toastError(error)
      })
  }

  return (
    <Modal>
      <form css={{ padding: 16 }} onSubmit={addOrUpdateUser}>
        <h2>{t('phone')}</h2>
        <label
          htmlFor="phoneInput"
          css={[label, { display: 'inline-block', textTransform: 'inherit' }]}
        >
          {user.name || user.email}
        </label>
        <PhoneInput
          phone={phone}
          onChange={phone => {
            setPhone(phone)
          }}
          required
        />
        <div css={{ height: 16 }} />
        <Button type="submit" fullWidth>
          {t('Save')}
        </Button>
      </form>
    </Modal>
  )
}

export default UserPhoneDialog
