/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  flag: '🇺🇦',
  countryName: 'Ukraine',
  phonePrefix: '+380',
  administrativeDivisionsNames: [
    'Автономна Республіка Крим',
    'Вінницька область',
    'Волинська область',
    'Дніпропетровська область',
    'Донецька область',
    'Житомирська область',
    'Закарпатська область',
    'Запорізька область',
    'Івано-Франківська область',
    'Київ',
    'Київська область',
    'Кіровоградська область',
    'Луганська область',
    'Львівська область',
    'Миколаївська область',
    'Одеська область',
    'Полтавська область',
    'Рівненська область',
    'Севастополь',
    'Сумська область',
    'Тернопільська область',
    'Харківська область',
    'Херсонська область',
    'Хмельницька область',
    'Черкаська область',
    'Чернівецька область',
    'Чернігівська область',
  ],
  administrativeDivisionsType: 'region',
  countryCode: 'UA',
  languages: ['uk'],
  zipcodePattern: '\\d{5}',
}
