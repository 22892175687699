/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { Suspense } from 'react'
import { css, jsx } from '@emotion/react'
import { format } from 'date-fns'
import camelCaseToCapitalized from '@monorepo/shared/camelCaseToCapitalized.js'
import getReminderScheduledTime from '@dododentist/shared/getReminderScheduleTime.js'
import { useTranslation } from '@multilocale/react/index.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import ErrorBoundary from '@stiloso/components/ErrorBoundary.js'
import Spinner from '@stiloso/components/Spinner.js'
import card from '@stiloso/styles/card.js'

let reminderCard = css([
  card,
  {
    lineHeight: '48px',
    width: '100%',
    padding: '0 16px',
    display: 'flex',
    flexDirection: 'column',
  },
])

const AppointmentRemindersSection = ({ appointment, reminders }) => {
  const { t } = useTranslation()

  return (
    <>
      <AppBar>
        <AppBarTitle>{t('Reminders')}</AppBarTitle>
        <div css={{ flexGrow: 1 }} />
      </AppBar>
      {reminders.length > 0 && (
        <div
          css={{
            marginBottom: 16,
            display: 'flex',
            flexWrap: 'wrap',
            gap: 8,
          }}
        >
          {reminders?.map(reminder => {
            let {
              _id,
              channels,
              event,
              time,
              timeAmount,
              timeRelation,
              timeUnit,
            } = reminder

            const scheduledTime = getReminderScheduledTime(
              reminder,
              appointment,
            )

            let description = ''

            if (timeAmount === 1) {
              timeUnit = timeUnit.replace(/s$/, '')
            }
            if (timeRelation === 'after' || timeRelation === 'before') {
              description += `${timeAmount} ${t(timeUnit)} ${t(timeRelation)} ${t(camelCaseToCapitalized(event))}`
            } else {
              description += ` ${t(timeRelation)} ${time}`
            }

            return (
              <div key={_id} css={reminderCard}>
                <div css={{ textTransform: 'lowercase' }}>{description}</div>
                <div css={{ display: 'flex' }}>
                  <span css={{ flexGrow: 1 }}>
                    {format(scheduledTime, 'yyyy-MM-dd HH:mm')}&nbsp;&nbsp;
                  </span>
                  {channels?.map(channel => (
                    <span key={channel} css={{ marginLeft: 8 }}>
                      {channel}
                    </span>
                  ))}
                </div>
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}

const AppointmentRemindersSectionWrapper = props => (
  <ErrorBoundary>
    <Suspense fallback={<Spinner />}>
      <AppointmentRemindersSection {...props} />
    </Suspense>
  </ErrorBoundary>
)

export default AppointmentRemindersSectionWrapper
