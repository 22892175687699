/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { Suspense, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import useLoggedInSession from '@monorepo/session/useLoggedInSession.js'
import getBrowserLanguage from '@monorepo/shared/getBrowserLanguage.js'
import preferCapitalized from '@monorepo/shared/preferCapitalized.js'
import addOrUpdate from '@monorepo/tractor/addOrUpdate.js'
import Clinic from '@dododentist/model/Clinic.js'
import IconBrand from '@dododentist/shared/IconBrand.js'
import { useTranslation } from '@multilocale/react/index.js'
import Button from '@stiloso/components/Button.js'
import ErrorBoundary from '@stiloso/components/ErrorBoundary.js'
import Spinner from '@stiloso/components/Spinner.js'
import input from '@stiloso/styles/input.js'
import label from '@stiloso/styles/label.js'
import useClinics from '../hooks/useClinics.js'

export const paths = ['/setup-clinic']

const SetupClinicPage = props => {
  let { clinic } = props
  const { t } = useTranslation()
  const navigate = useNavigate()

  let [name, setName] = useState(clinic?.name)
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const changeName = event => {
    setName(event.target.value)
  }

  const setupClinic = async event => {
    event?.preventDefault?.()

    const defaultLocale = getBrowserLanguage()
    const locales = [defaultLocale]

    name = preferCapitalized(name)

    let newClinic = new Clinic({
      defaultLocale,
      locales,
      ...clinic,
      lastEditTime: new Date().toISOString(),
      name,
    })

    setError(null)
    setLoading(true)
    addOrUpdate(newClinic, clinic)
      .then(_ => {
        setLoading(false)
        navigate(`/clinics/${newClinic._id}/calendar`)
      })
      .catch(error => {
        setLoading(false)
        setError(error?.message)
      })
  }

  return (
    <div
      css={{
        height: '100%',
        padding: '48px 0px',
        backgroundColor: 'var(--color-primary)',
      }}
    >
      <div
        css={{
          maxWidth: 1200,
          marginLeft: 'auto',
          marginInlineEnd: 'auto',
          padding: '0px 16px',
          width: '100%',
          backgroundColor: 'var(--color-primary)',
        }}
      >
        <div css={{ padding: '0px 16px' }}>
          <IconBrand light />
        </div>

        <div
          css={{
            margin: '48px 0px',
            textAlign: 'center',
            color: 'white',
            fontSize: 24,
            fontWeight: 'bold',
            lineHeight: '24px',
          }}
        >
          {t('Setup your dental practice')}
        </div>

        <div
          css={{
            margin: '16px 0px',
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
          }}
        >
          <div css={{ width: '100%', maxWidth: 480, padding: '0px 16px' }}>
            <form
              onSubmit={setupClinic}
              css={{
                background: 'var(--color-background)',
                padding: '16px 32px',
                borderRadius: 8,
              }}
            >
              <div>
                <label htmlFor="nameInput" css={label}>
                  {t('Name of your dental clinic or dentist studio')}
                </label>
                <div
                  css={[
                    input,
                    {
                      display: 'flex',
                      padding: 0,
                      boxShadow: error && 'inset 0px 0px 0px 1px red',
                    },
                  ]}
                >
                  <input
                    id="nameInput"
                    css={{
                      display: 'flex',
                      flexGrow: 1,
                      fontWeight: 'bold',
                      color: error ? 'red' : '#6A768A',
                      padding: 12,
                      '::placeholder': {
                        fontWeight: 'normal',
                      },
                    }}
                    value={name || ''}
                    onChange={changeName}
                    placeholder={t('name')}
                    autoComplete="off"
                    autoFocus
                    required
                  />
                </div>
              </div>
              <Button
                type="submit"
                css={{ margin: '32px 0px' }}
                isLoading={isLoading}
                fullWidth
              >
                {t('Save')}
              </Button>
              {error && (
                <div
                  css={{
                    lineHeight: '24px',
                    marginTop: 16,
                    color: 'red',
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                >
                  {t(error?.message)}
                </div>
              )}
              {error && (
                <div
                  css={{
                    lineHeight: '24px',
                    marginTop: 16,
                    color: 'red',
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                >
                  {t(error)}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

const SetupClinicPageConnected = () => {
  useLoggedInSession()
  const clinics = useClinics()
  const [clinic] = clinics

  return <SetupClinicPage clinic={clinic} />
}

const SetupClinicPageWrapper = () => (
  <ErrorBoundary>
    <Suspense fallback={<Spinner />}>
      <SetupClinicPageConnected />
    </Suspense>
  </ErrorBoundary>
)

export default SetupClinicPageWrapper
