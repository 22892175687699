/* Copyright 2013 - 2024 Waiterio LLC */
export default function preferCapitalized(string) {
  if (string) {
    if (string.toLowerCase() === string || string.toUpperCase() === string) {
      string =
        string.charAt(0).toUpperCase() + string.substring(1).toLowerCase()
    }
  }

  return string
}
