/* Copyright 2013 - 2024 Waiterio LLC */
import localForage from 'localforage'
import localStorage from 'localStorage'
import {
  setAccessTokenForClient,
  setAccessTokenCallbackForClient,
} from '@monorepo/client/accessToken.js'
import { setRefreshTokenForClient } from '@monorepo/client/refreshToken.js'
import IS_BROWSER from '@monorepo/env/IS_BROWSER.js'
import clearCookies from '@monorepo/shared/clearCookies.js'
import getPreviousAppVersion from './getPreviousAppVersion.js'
import setPreviousAppVersion from './setPreviousAppVersion.js'

export default function clearSession() {
  if (IS_BROWSER) {
    localStorage.clear()
    localForage.clear()

    setAccessTokenCallbackForClient(null)

    setAccessTokenForClient(null)
    setRefreshTokenForClient(null)

    clearCookies()

    let previousAppVersion = getPreviousAppVersion()

    if (previousAppVersion) {
      setPreviousAppVersion(previousAppVersion)
    }
  }
}
