/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */

/* eslint-disable max-len */
import React, { Suspense, useEffect, useState } from 'react'
import { css, jsx } from '@emotion/react'
import {
  unstable_useBlocker as useBlocker,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import useLoggedInSession from '@monorepo/session/useLoggedInSession.js'
import { toastError } from '@monorepo/shared/AndroidToast.js'
import iosNative from '@monorepo/shared/device/iosNative.js'
import getImageFromEvent from '@monorepo/shared/getImageFromEvent.js'
import preferCapitalized from '@monorepo/shared/preferCapitalized.js'
import uuid from '@monorepo/shared/uuid.js'
import addOrUpdate from '@monorepo/tractor/addOrUpdate.js'
import remove from '@monorepo/tractor/remove.js'
import Image from '@dododentist/model/Image.js'
import { useTranslation } from '@multilocale/react/index.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarButton from '@stiloso/components/AppBarButton.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import Button from '@stiloso/components/Button.js'
import ErrorBoundary from '@stiloso/components/ErrorBoundary.js'
import ImageGraceful from '@stiloso/components/ImageGraceful.js'
import Input from '@stiloso/components/Input.js'
import Spinner from '@stiloso/components/Spinner.js'
import UploadImageComponent from '@stiloso/components/UploadImage.js'
import IconDelete from '@stiloso/icons/IconDelete.js'
import card from '@stiloso/styles/card.js'
import section from '@stiloso/styles/section.js'
import Layout from '../components/Layout.js'
import SaveChangesPrompt from '../dialogs/SaveChangesPrompt.js'
import useClinic from '../hooks/useClinic.js'
import useImage from '../hooks/useImage.js'

export const paths = ['/clinics/:clinicId/images/:imageId']

const ImagePage = props => {
  let { clinic, image, patientId } = props
  useLoggedInSession()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [edited, setEdited] = useState(false)
  const [isLoading, setLoading] = useState(false)
  let [_id, setId] = useState(image?._id || uuid())
  let [name, setName] = useState(image?.name || '')
  let [url, setUrl] = useState(image?.url || '')
  let [file, setFile] = useState(null)

  const changeName = event => {
    setEdited(true)
    setName(event.target.value)
  }

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      edited && currentLocation.pathname !== nextLocation.pathname,
  )

  useEffect(() => {
    if (!edited) {
      blocker?.proceed?.()
    }
  }, [edited])

  const addOrUpdateImage = async event => {
    event?.preventDefault?.()

    try {
      name = preferCapitalized(name)
      const newImage = new Image({
        _id,
        ...image,
        lastEditTime: new Date().toISOString(),
        organizationId: clinic.organizationId,
        clinicId: clinic._id,
        patientId,
        name,
        file,
      })
      console.log('newImage', newImage)

      setLoading(true)
      await addOrUpdate(newImage, image)
      setLoading(false)
      setEdited(false)
      if (patientId) {
        navigate(`/clinics/${clinic._id}/patients/${patientId}`)
      } else {
        navigate(`/clinics/${clinic._id}`)
      }
    } catch (error) {
      toastError(t(error))
    }
  }

  let closePrompt = () => {
    blocker.reset()
  }

  let discardChangesPrompt = () => {
    blocker.proceed()
  }

  let onDelete = async event => {
    event?.preventDefault?.()

    try {
      if (image) {
        await remove(image)
      }

      setEdited(false)
      if (patientId) {
        navigate(`/clinics/${clinic._id}/patients/${patientId}`)
      } else {
        navigate(`/clinics/${clinic._id}`)
      }
    } catch (error) {
      toastError(t(error))
    }
  }

  const deleteImage = async event => {
    event?.preventDefault?.()
    try {
      const newImage = new Image({
        ...image,
        avatarUrl: null,
        lastEditTime: new Date().toISOString(),
      })

      setUrl(null)
      setLoading(true)
      await addOrUpdate(newImage, image)

      setLoading(false)
    } catch (error) {
      setUrl(image?.avatarUrl)
      setLoading(false)
      toastError(t(error))
    }
  }

  const onClickUpload = () => {
    document.getElementById('uploadInput').click()
  }

  const upload = async event => {
    event?.preventDefault?.()
    try {
      let imageFile = await getImageFromEvent(event)
      console.log('imageFile', imageFile)
      setFile(imageFile)
    } catch (error) {
      toastError(t(error))
    }
  }

  let saveChangesPrompt = async () => {
    await addOrUpdateImage()
    blocker.proceed()
  }

  useEffect(() => {
    if (image) {
      setId(image._id)
      setName(image.name)
      setUrl(image.url)
    }
  }, [image])

  useEffect(() => {
    if (file) {
      addOrUpdateImage()
    }
  }, [file])

  return (
    <>
      {blocker?.state === 'blocked' && (
        <SaveChangesPrompt
          close={closePrompt}
          discardChanges={discardChangesPrompt}
          saveChanges={saveChangesPrompt}
        />
      )}
      <div css={section}>
        <AppBar>
          <AppBarTitle>{image ? t('Image') : t('New image')}</AppBarTitle>
          <div css={{ flexGrow: 1 }} />
          {image && (
            <AppBarButton
              appearance="secondary"
              Icon={IconDelete}
              label={t('Delete')}
              onClick={onDelete}
            />
          )}
        </AppBar>
        <form onSubmit={addOrUpdateImage} css={[card, { margin: '0 16px' }]}>
          <Input
            label={t('Name')}
            value={name}
            onChange={changeName}
            autoFocus
            autoComplete="off"
          />
          {image?.url && (
            <img src={image?.url} css={{ paddingTop: 16, width: '100%' }} />
          )}
          {!iosNative && !image?.url && (
            <div css={{ width: 140, paddingTop: 16 }}>
              {url && (
                <div css={{ position: 'relative', width: 128 }}>
                  <ImageGraceful
                    css={{
                      aspectRatio: '1 / 1',
                      height: 128,
                      borderRadius: 1000,
                    }}
                    retry={{
                      count: 10,
                      delay: 1,
                      accumulate: false,
                    }}
                    src={url.replace('.jpg', '-256w.webp')}
                    fallbackSrc={url.replace('.jpg', '-256w.jpg')}
                  />

                  <div
                    css={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      padding: 4,
                      borderRadius: 4,
                      backgroundColor: 'var(--color-primary)',
                      cursor: 'pointer',
                      ':hover': {
                        backgroundColor: 'var(--color-primary-light)',
                      },
                      ':active': {
                        backgroundColor: 'var(--color-primary-dark)',
                      },
                    }}
                    onClick={deleteImage}
                  >
                    <IconDelete css={{ fill: 'white' }} />
                  </div>
                </div>
              )}
              {(!url || isLoading) && (
                <UploadImageComponent
                  inputId="uploadInput"
                  onClickUploadInput={onClickUpload}
                  uploadImage={upload}
                  isPending={isLoading}
                  styles={{ borderRadius: 1000 }}
                />
              )}
            </div>
          )}
          {edited && image?.url && (
            <Button
              type="submit"
              css={{ marginTop: 16 }}
              fullWidth
              isLoading={isLoading}
            >
              {image ? t('Save image') : t('Add image')}
            </Button>
          )}
        </form>
      </div>
    </>
  )
}

const ImagePageConnected = () => {
  useLoggedInSession()
  const { clinicId, imageId } = useParams()
  const clinic = useClinic(clinicId)
  const image = useImage(imageId)
  let location = useLocation()
  let urlSearchParams = new URLSearchParams(location.search)

  let patientId = image?.patientId || urlSearchParams.get('patientId')

  return <ImagePage clinic={clinic} image={image} patientId={patientId} />
}

const ImagePageWrapper = () => {
  const { t } = useTranslation()
  const { imageId } = useParams()
  const isNewImage = imageId === 'new'
  return (
    <Layout title={t(isNewImage ? 'New image' : 'Image')}>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <ImagePageConnected />
        </Suspense>
      </ErrorBoundary>
    </Layout>
  )
}

export default ImagePageWrapper
