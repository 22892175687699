/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from '@multilocale/react/index.js'
import clickable from '@stiloso/styles/clickable.js'

const WeekMonthSwitch = ({ value }) => {
  let { clinicId } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isWeek = value === 'week'
  const isMonth = value === 'month'
  return (
    <div
      css={{
        fontSize: 18,
        border: '1px solid var(--color-base)',
        borderRadius: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'var(--color-primary-lightest)',
      }}
    >
      <div
        css={[
          clickable,
          {
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: 'center',
            padding: '4 16',
            borderRadius: 16,
            minWidth: 100,
            boxSizing: 'border-box',
            background: isWeek ? 'white' : 'inehrit',
            color: isWeek ? 'var(--color-base)' : 'var(--color-base)',
            textTransform: 'lowercase',
          },
        ]}
        onClick={() => navigate(`/clinics/${clinicId}/agenda`)}
      >
        {t('Week')}
      </div>
      <div
        css={[
          clickable,
          {
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: 'center',
            padding: '4 16',
            borderRadius: 16,
            minWidth: 100,
            boxSizing: 'border-box',
            background: isMonth ? 'white' : 'inehrit',
            color: isMonth ? 'var(--color-base)' : 'var(--color-base)',
            textTransform: 'lowercase',
          },
        ]}
        onClick={() => navigate(`/clinics/${clinicId}/calendar`)}
      >
        {t('Month')}
      </div>
    </div>
  )
}

export default WeekMonthSwitch
