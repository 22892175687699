/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import IconBrand from '@dododentist/shared/IconBrand.js'
import IllustrationDentist from '@dododentist/shared/IllustrationDentist.js'
import ChangePasswordPage from '@stiloso/pages/ChangePasswordPage.js'
import postChangePassword from '../postChangePassword.js'

export const paths = ['/changepassword', '/change-password']

const ChangePasswordPageWrapper = () => {
  const changePassword = async ({ newPassword, forgotPasswordToken }) => {
    await postChangePassword({ newPassword, forgotPasswordToken })
  }

  let brand = <IconBrand width={36} height={36} fontSize={36} />

  return (
    <ChangePasswordPage
      brand={brand}
      changePassword={changePassword}
      illustration={<IllustrationDentist />}
    />
  )
}

export default ChangePasswordPageWrapper
