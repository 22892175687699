/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  flag: '🇳🇮',
  countryName: 'Nicaragua',
  phonePrefix: '+505',
  administrativeDivisionsType: 'department',
  countryCode: 'NI',
  languages: ['es'],
  zipcodePattern: '\\d{5}',
}
