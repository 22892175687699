/* Copyright 2013 - 2024 Waiterio LLC */
import postClass from '@monorepo/client/postClass.js'
import burn from '@monorepo/forage/burn.js'
import plant from '@monorepo/forage/plant.js'

const add = async document => {
  let Class = Object.getPrototypeOf(document).constructor
  document = await plant(Class, document)
  return postClass(Class, document).catch(error => {
    burn(Class, document._id)

    throw error
  })
}

export default add
