/* Copyright 2013 - 2024 Waiterio LLC */
import { useContext } from './index.js'

const useDocuments = (collection, query) => {
  let [context] = useContext()
  return Object.values(context.data?.[collection] ?? {}).filter(document => {
    let result = true
    let keys = Object.keys(query || {})
    let k = 0
    while (result && k < keys.length) {
      let key = keys[k]
      if (query[key] && document[key] !== query[key]) {
        result = false
      }
      k += 1
    }
    return result
  })
}

export default useDocuments
