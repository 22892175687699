/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { Link } from 'react-router-dom'
import singleLine from '@stiloso/styles/singleLine.js'
import ImageGraceful from './ImageGraceful.js'

const drawerButton = selected =>
  css([
    singleLine,
    {
      color: 'var(--color-background)',
      fill: 'white',
      textTransform: 'capitalize',
      lineHeight: '48px',
      padding: '0 16px',
      ':hover, :focus-visible': {
        backgroundColor: 'var(--color-primary-light)',
      },
      ':active': {
        backgroundColor: 'var(--color-primary-dark)',
      },
    },
    selected && {
      boxShadow: 'inset 4px 0px 0px 0px var(--color-secondary-light)',
      backgroundColor: 'var(--color-primary-dark)',
    },
  ])

const DrawerButton = ({ label, to, Icon, avatarUrl, selected }) => (
  <Link to={to} css={drawerButton(selected)}>
    {Icon && !avatarUrl && (
      <Icon
        style={{
          marginInlineEnd: 16,
          verticalAlign: 'text-top',
        }}
      />
    )}
    {avatarUrl && (
      <ImageGraceful
        css={{
          aspectRatio: '1 / 1',
          height: 44,
          borderRadius: 1000,
          marginRight: 16,
          verticalAlign: 'top',
        }}
        retry={{
          count: 10,
          delay: 1,
          accumulate: false,
        }}
        src={avatarUrl.replace('.jpg', '-256w.webp')}
        fallbackSrc={avatarUrl.replace('.jpg', '-256w.jpg')}
      />
    )}
    {label}
  </Link>
)

export default DrawerButton
