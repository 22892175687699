/* Copyright 2013 - 2024 Waiterio LLC */
import { css } from '@emotion/react'

export default css`
  border: 0;
  margin: 16px auto;
  padding: 0 16px;
  max-width: 640px;
  width: 100%;
`
