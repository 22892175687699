/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { css, jsx } from '@emotion/react'
import { toastError } from '@monorepo/shared/AndroidToast.js'
import getCurrencyByCountry from '@monorepo/shared/currencies/getCurrencyByCountry.js'
import addOrUpdate from '@monorepo/tractor/addOrUpdate.js'
import Clinic from '@dododentist/model/Clinic.js'
import { useTranslation } from '@multilocale/react/index.js'
import useCurrentCountry from '@multilocale/react/useCurrentCountry.js'
import Button from '@stiloso/components/Button.js'
import CountryInput from '@stiloso/components/CountryInput.js'
import Modal from '@stiloso/components/Modal.js'
import label from '@stiloso/styles/label.js'
import useClinics from '../hooks/useClinics.js'

const CountryDialog = () => {
  const { t } = useTranslation()
  const currentCountry = useCurrentCountry()
  let clinics = useClinics()

  let clinic
  let c = 0
  while (!clinic && c < clinics.length) {
    let clinic_ = clinics[c]
    if (!clinic_.countryCode) {
      clinic = clinic_
    }
    c += 1
  }

  let [, setShow] = useState(true)
  let [countryCode, setCountryCode] = useState(
    clinic?.countryCode || currentCountry?.countryCode || '',
  )

  useEffect(() => {
    if (currentCountry?.currencyCode && !countryCode) {
      setCountryCode(currentCountry?.countryCode)
    }
  }, [currentCountry])

  const changeCountryCode = countryCode => {
    setCountryCode(countryCode)
  }

  const hideCountryDialog = () => {
    setShow(false)
  }

  const addOrUpdateClinic = event => {
    event?.preventDefault?.()

    if (countryCode.length > 0) {
      let lastEditTime = new Date().toISOString()
      let newClinic = new Clinic({
        ...clinic,
        lastEditTime,
        countryCode,
        currency: getCurrencyByCountry(countryCode),
      })

      addOrUpdate(newClinic, clinic)
        .then(() => {
          hideCountryDialog()
        })
        .catch(error => {
          console.error(error)
          toastError(error)
        })
    } else {
      toastError(t('Please select country'))
    }
  }

  return (
    clinic && (
      <Modal>
        <form css={{ padding: 16 }} onSubmit={addOrUpdateClinic}>
          <h2>{t('country')}</h2>
          <label
            htmlFor="countryInput"
            css={[label, { display: 'inline-block' }]}
          >
            {clinic.name}
          </label>
          <CountryInput country={countryCode} onChange={changeCountryCode} />
          <div css={{ height: 16 }} />
          <Button type="submit" fullWidth>
            {t('Save')}
          </Button>
        </form>
      </Modal>
    )
  )
}

export default CountryDialog
