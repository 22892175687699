/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  flag: '🇸🇴',
  countryName: 'Somalia',
  phonePrefix: '+252',
  administrativeDivisionsNames: [
    'Awdal',
    'Bakool',
    'Banaadir',
    'Bari',
    'Bay',
    'Galguduud',
    'Gedo',
    'Hiiraan',
    'Jubbada Dhexe',
    'Jubbada Hoose',
    'Mudug',
    'Nugaal',
    'Sanaag',
    'Shabeellaha Dhexe',
    'Shabeellaha Hoose',
    'Sool',
    'Togdheer',
    'Woqooyi Galbeed',
  ],
  administrativeDivisionsType: 'region',
  countryCode: 'SO',
  languages: ['so'],
  zipcodePattern: '[A-Z]{2} ?\\d{5}',
}
