/* Copyright 2013 - 2024 Waiterio LLC */
import AD from './AD.js'
import AE from './AE.js'
import AF from './AF.js'
import AG from './AG.js'
import AI from './AI.js'
import AL from './AL.js'
import AM from './AM.js'
import AN from './AN.js'
import AO from './AO.js'
import AQ from './AQ.js'
import AR from './AR.js'
import AS from './AS.js'
import AT from './AT.js'
import AU from './AU.js'
import AW from './AW.js'
import AX from './AX.js'
import AZ from './AZ.js'
import BA from './BA.js'
import BB from './BB.js'
import BD from './BD.js'
import BE from './BE.js'
import BF from './BF.js'
import BG from './BG.js'
import BH from './BH.js'
import BI from './BI.js'
import BJ from './BJ.js'
import BL from './BL.js'
import BM from './BM.js'
import BN from './BN.js'
import BO from './BO.js'
import BQ from './BQ.js'
import BR from './BR.js'
import BS from './BS.js'
import BT from './BT.js'
import BV from './BV.js'
import BW from './BW.js'
import BY from './BY.js'
import BZ from './BZ.js'
import CA from './CA.js'
import CC from './CC.js'
import CD from './CD.js'
import CF from './CF.js'
import CG from './CG.js'
import CH from './CH.js'
import CI from './CI.js'
import CK from './CK.js'
import CL from './CL.js'
import CM from './CM.js'
import CN from './CN.js'
import CO from './CO.js'
import CR from './CR.js'
import CU from './CU.js'
import CV from './CV.js'
import CW from './CW.js'
import CX from './CX.js'
import CY from './CY.js'
import CZ from './CZ.js'
import DE from './DE.js'
import DJ from './DJ.js'
import DK from './DK.js'
import DM from './DM.js'
import DO from './DO.js'
import DZ from './DZ.js'
import EC from './EC.js'
import EE from './EE.js'
import EG from './EG.js'
import EH from './EH.js'
import ER from './ER.js'
import ES from './ES.js'
import ET from './ET.js'
import FI from './FI.js'
import FJ from './FJ.js'
import FK from './FK.js'
import FM from './FM.js'
import FO from './FO.js'
import FR from './FR.js'
import GA from './GA.js'
import GB from './GB.js'
import GD from './GD.js'
import GE from './GE.js'
import GF from './GF.js'
import GG from './GG.js'
import GH from './GH.js'
import GI from './GI.js'
import GL from './GL.js'
import GM from './GM.js'
import GN from './GN.js'
import GP from './GP.js'
import GQ from './GQ.js'
import GR from './GR.js'
import GS from './GS.js'
import GT from './GT.js'
import GU from './GU.js'
import GW from './GW.js'
import GY from './GY.js'
import HK from './HK.js'
import HM from './HM.js'
import HN from './HN.js'
import HR from './HR.js'
import HT from './HT.js'
import HU from './HU.js'
import ID from './ID.js'
import IE from './IE.js'
import IL from './IL.js'
import IM from './IM.js'
import IN from './IN.js'
import IQ from './IQ.js'
import IR from './IR.js'
import IS from './IS.js'
import IT from './IT.js'
import JE from './JE.js'
import JM from './JM.js'
import JO from './JO.js'
import JP from './JP.js'
import KE from './KE.js'
import KG from './KG.js'
import KH from './KH.js'
import KI from './KI.js'
import KM from './KM.js'
import KN from './KN.js'
import KP from './KP.js'
import KR from './KR.js'
import KW from './KW.js'
import KY from './KY.js'
import KZ from './KZ.js'
import LA from './LA.js'
import LB from './LB.js'
import LC from './LC.js'
import LI from './LI.js'
import LK from './LK.js'
import LR from './LR.js'
import LS from './LS.js'
import LT from './LT.js'
import LU from './LU.js'
import LV from './LV.js'
import LY from './LY.js'
import MA from './MA.js'
import MC from './MC.js'
import MD from './MD.js'
import ME from './ME.js'
import MF from './MF.js'
import MG from './MG.js'
import MH from './MH.js'
import MK from './MK.js'
import ML from './ML.js'
import MM from './MM.js'
import MN from './MN.js'
import MO from './MO.js'
import MP from './MP.js'
import MQ from './MQ.js'
import MR from './MR.js'
import MS from './MS.js'
import MT from './MT.js'
import MU from './MU.js'
import MV from './MV.js'
import MW from './MW.js'
import MX from './MX.js'
import MY from './MY.js'
import MZ from './MZ.js'
import NA from './NA.js'
import NC from './NC.js'
import NE from './NE.js'
import NF from './NF.js'
import NG from './NG.js'
import NI from './NI.js'
import NL from './NL.js'
import NO from './NO.js'
import NP from './NP.js'
import NR from './NR.js'
import NU from './NU.js'
import NZ from './NZ.js'
import OM from './OM.js'
import PA from './PA.js'
import PE from './PE.js'
import PF from './PF.js'
import PG from './PG.js'
import PH from './PH.js'
import PK from './PK.js'
import PL from './PL.js'
import PM from './PM.js'
import PN from './PN.js'
import PR from './PR.js'
import PS from './PS.js'
import PT from './PT.js'
import PW from './PW.js'
import PY from './PY.js'
import QA from './QA.js'
import RE from './RE.js'
import RO from './RO.js'
import RS from './RS.js'
import RU from './RU.js'
import RW from './RW.js'
import SA from './SA.js'
import SB from './SB.js'
import SC from './SC.js'
import SD from './SD.js'
import SE from './SE.js'
import SG from './SG.js'
import SH from './SH.js'
import SI from './SI.js'
import SJ from './SJ.js'
import SK from './SK.js'
import SL from './SL.js'
import SM from './SM.js'
import SN from './SN.js'
import SO from './SO.js'
import SR from './SR.js'
import SS from './SS.js'
import ST from './ST.js'
import SV from './SV.js'
import SX from './SX.js'
import SY from './SY.js'
import SZ from './SZ.js'
import TA from './TA.js'
import TC from './TC.js'
import TD from './TD.js'
import TF from './TF.js'
import TG from './TG.js'
import TH from './TH.js'
import TJ from './TJ.js'
import TK from './TK.js'
import TL from './TL.js'
import TM from './TM.js'
import TN from './TN.js'
import TO from './TO.js'
import TR from './TR.js'
import TT from './TT.js'
import TV from './TV.js'
import TW from './TW.js'
import TZ from './TZ.js'
import UA from './UA.js'
import UG from './UG.js'
import US from './US.js'
import UY from './UY.js'
import UZ from './UZ.js'
import VA from './VA.js'
import VC from './VC.js'
import VE from './VE.js'
import VG from './VG.js'
import VI from './VI.js'
import VN from './VN.js'
import VU from './VU.js'
import WF from './WF.js'
import WS from './WS.js'
import XK from './XK.js'
import YE from './YE.js'
import YT from './YT.js'
import ZA from './ZA.js'
import ZM from './ZM.js'
import ZW from './ZW.js'

/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  AD,
  AE,
  AF,
  AG,
  AI,
  AL,
  AM,
  AN,
  AO,
  AQ,
  AR,
  AS,
  AT,
  AU,
  AW,
  AX,
  AZ,
  BA,
  BB,
  BD,
  BE,
  BF,
  BG,
  BH,
  BI,
  BJ,
  BL,
  BM,
  BN,
  BO,
  BQ,
  BR,
  BS,
  BT,
  BV,
  BW,
  BY,
  BZ,
  CA,
  CC,
  CD,
  CF,
  CG,
  CH,
  CI,
  CK,
  CL,
  CM,
  CN,
  CO,
  CR,
  CU,
  CV,
  CW,
  CX,
  CY,
  CZ,
  DE,
  DJ,
  DK,
  DM,
  DO,
  DZ,
  EC,
  EE,
  EG,
  EH,
  ER,
  ES,
  ET,
  FI,
  FJ,
  FK,
  FM,
  FO,
  FR,
  GA,
  GB,
  GD,
  GE,
  GF,
  GG,
  GH,
  GI,
  GL,
  GM,
  GN,
  GP,
  GQ,
  GR,
  GS,
  GT,
  GU,
  GW,
  GY,
  HK,
  HM,
  HN,
  HR,
  HT,
  HU,
  ID,
  IE,
  IL,
  IM,
  IN,
  IQ,
  IR,
  IS,
  IT,
  JE,
  JM,
  JO,
  JP,
  KE,
  KG,
  KH,
  KI,
  KM,
  KN,
  KP,
  KR,
  KW,
  KY,
  KZ,
  LA,
  LB,
  LC,
  LI,
  LK,
  LR,
  LS,
  LT,
  LU,
  LV,
  LY,
  MA,
  MC,
  MD,
  ME,
  MF,
  MG,
  MH,
  MK,
  ML,
  MM,
  MN,
  MO,
  MP,
  MQ,
  MR,
  MS,
  MT,
  MU,
  MV,
  MW,
  MX,
  MY,
  MZ,
  NA,
  NC,
  NE,
  NF,
  NG,
  NI,
  NL,
  NO,
  NP,
  NR,
  NU,
  NZ,
  OM,
  PA,
  PE,
  PF,
  PG,
  PH,
  PK,
  PL,
  PM,
  PN,
  PR,
  PS,
  PT,
  PW,
  PY,
  QA,
  RE,
  RO,
  RS,
  RU,
  RW,
  SA,
  SB,
  SC,
  SD,
  SE,
  SG,
  SH,
  SI,
  SJ,
  SK,
  SL,
  SM,
  SN,
  SO,
  SR,
  SS,
  ST,
  SV,
  SX,
  SY,
  SZ,
  TA,
  TC,
  TD,
  TF,
  TG,
  TH,
  TJ,
  TK,
  TL,
  TM,
  TN,
  TO,
  TR,
  TT,
  TV,
  TW,
  TZ,
  UA,
  UG,
  US,
  UY,
  UZ,
  VA,
  VC,
  VE,
  VG,
  VI,
  VN,
  VU,
  WF,
  WS,
  XK,
  YE,
  YT,
  ZA,
  ZM,
  ZW,
}
