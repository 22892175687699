/* Copyright 2013 - 2024 Waiterio LLC */
export default function getTextDirectionFromLanguageCode(languageCode) {
  let dir = 'ltr'

  switch (languageCode) {
    case 'ar':
    case 'fa':
    case 'he':
    case 'ur':
      dir = 'rtl'
      break
    default:
    // do nothing on purpose
  }

  return dir
}
