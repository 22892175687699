/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'

const IconRight = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-right'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="M10.008 6l-1.416 1.416 4.584 4.584-4.584 4.584 1.416 1.416 6-6z" />
    </svg>
  )
}

export default IconRight
