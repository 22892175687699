/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */

/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react'
import { css, jsx } from '@emotion/react'
import formatPrice from '@monorepo/shared/formatPrice.js'
import uuid from '@monorepo/shared/uuid.js'
import { useTranslation } from '@multilocale/react/index.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import card from '@stiloso/styles/card.js'
import clickable from '@stiloso/styles/clickable.js'
import input from '@stiloso/styles/input.js'
import getTotalPriceForProcedures from '../getTotalPriceForProcedures.js'
import useProcedures from '../hooks/useProcedures.js'

const DropdownProcedures = ({ clinicId, currency, onSelectProcedure }) => {
  const { t } = useTranslation()
  const wrapperRef = useRef(null)
  let procedures = useProcedures({
    clinicId,
  })

  let [searchQuery, setSearchQuery] = useState('')
  let [visible, setVisible] = useState(false)

  procedures = procedures.filter(procedure =>
    procedure?.name?.toLowerCase().includes(searchQuery?.toLowerCase()),
  )

  let onSelectProcedure_ = procedure => {
    onSelectProcedure(procedure)
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setVisible(false)
        setSearchQuery('')
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    procedures && (
      <div ref={wrapperRef}>
        <input
          id="searchQueryInput"
          css={input}
          value={searchQuery}
          onChange={event => setSearchQuery(event.target.value)}
          onClick={() => setVisible(true)}
          autoComplete="off"
          placeholder={t('search a procedure')}
        />

        {visible && (
          <div css={{ position: 'relative' }}>
            <div
              css={{
                position: 'absolute',
                background: 'white',
                width: '100%',
              }}
            >
              {procedures.map(procedure => (
                <div
                  key={procedure._id}
                  css={[
                    clickable,
                    {
                      border: '1px solid #D9E0ED',
                      padding: '12px 8px',
                      display: 'flex',
                    },
                  ]}
                  onClick={() => onSelectProcedure_(procedure)}
                >
                  <span>{procedure.name}</span>
                  <span css={{ flexGrow: 1 }} />
                  <span>{formatPrice(procedure.price, currency, true)}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    )
  )
}

const ProceduresSection = ({
  clinicId,
  currency,
  procedures,
  changeProcedures,
}) => {
  procedures ||= []
  const { t } = useTranslation()

  const onSelectProcedure = procedure => {
    changeProcedures([
      ...procedures,
      {
        _id: uuid(),
        procedureId: procedure._id,
        name: procedure.name,
        price: procedure.price,
      },
    ])
  }

  const removeProcedure = procedure => {
    changeProcedures(procedures.filter(({ _id }) => _id !== procedure._id))
  }

  return (
    <>
      <AppBar>
        <AppBarTitle>{t('Procedures')}</AppBarTitle>
        <div css={{ flexGrow: 1 }} />
      </AppBar>
      <div css={[card, { paddingTop: 16 }]}>
        <DropdownProcedures
          clinicId={clinicId}
          currency={currency}
          onSelectProcedure={onSelectProcedure}
        />
        {procedures?.map(procedure => (
          <div
            key={procedure?._id}
            css={[clickable, { padding: '16 0 16 0', display: 'flex' }]}
            onClick={() => removeProcedure(procedure)}
          >
            {procedure?.name}
            <div css={{ flexGrow: 1 }} />
            {formatPrice(procedure?.price, currency, false)}
          </div>
        ))}
        {procedures.length > 0 && (
          <hr
            css={{
              border: 'none',
              height: 1,
              backgroundColor: 'var(--color-separator)',
              width: '100%',
              padding: 0,
              margin: 0,
            }}
          />
        )}
        <div css={{ marginTop: 16, display: 'flex' }}>
          <span css={{ fontWeight: 'bold' }}>{t('Total')}</span>
          <span css={{ flexGrow: 1 }} />
          <span>
            {formatPrice(
              getTotalPriceForProcedures(procedures, currency, true),
              currency,
              true,
            )}
          </span>
        </div>
      </div>
    </>
  )
}

export default ProceduresSection
