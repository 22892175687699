/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  flag: '🇧🇸',
  countryName: 'Bahamas',
  phonePrefix: '+1242',
  administrativeDivisionsNames: [
    'Abaco Islands',
    'Acklins',
    'Andros Island',
    'Berry Islands',
    'Bimini',
    'Cat Island',
    'Crooked Island',
    'Eleuthera',
    'Exuma and Cays',
    'Grand Bahama',
    'Harbour Island',
    'Inagua',
    'Long Island',
    'Mayaguana',
    'New Providence',
    'Ragged Island',
    'Rum Cay',
    'San Salvador',
    'Spanish Wells',
  ],
  administrativeDivisionsType: 'island',
  countryCode: 'BS',
  languages: ['en'],
}
