/* Copyright 2013 - 2024 Waiterio LLC */
import { produce } from '@monorepo/context/index.js'

const updateConnectionState = () => {
  produce(draft => {
    draft.connection.online = navigator.onLine
  })
}

const listenForConnectionChanges = () => {
  updateConnectionState()

  window.addEventListener('online', updateConnectionState, false)
  window.addEventListener('offline', updateConnectionState, false)
}

export default listenForConnectionChanges
