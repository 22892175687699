/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  flag: '🇨🇴',
  countryName: 'Colombia',
  phonePrefix: '+57',
  administrativeDivisionsType: 'department',
  administrativeDivisionsNames: [
    'Amazonas',
    'Antioquia',
    'Arauca',
    'Atlántico',
    'Bogotá',
    'Bolívar',
    'Boyacá',
    'Caldas',
    'Caquetá',
    'Casanare',
    'Cauca',
    'Cesar',
    'Chocó',
    'Córdoba',
    'Cundinamarca',
    'Guainía',
    'Guaviare',
    'Huila',
    'La Guajira',
    'Magdalena',
    'Meta',
    'Nariño',
    'Norte de Santander',
    'Putumayo',
    'Quindío',
    'Risaralda',
    'San Andrés y Providencia',
    'Santander',
    'Sucre',
    'Tolima',
    'Valle del Cauca',
    'Vaupés',
    'Vichada',
  ],
  countryCode: 'CO',
  zipcodePattern: '\\d{6}',
}
