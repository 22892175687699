/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import elevated from '@stiloso/styles/elevated.js'
import unselectable from '@stiloso/styles/unselectable.js'

const tabs = css([
  {
    width: '100%',
    display: 'flex',
    overflowX: 'auto',
    overflowY: 'hidden',
    minHeight: 48,
    boxSizing: 'border-box',
    background: 'white',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  elevated,
  unselectable,
])

const Tabs = ({ children, styles }) => (
  <div css={[tabs, styles]}>{children}</div>
)

export default Tabs
