/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useState, useRef, useEffect } from 'react'
import { jsx, css } from '@emotion/react'
import languages_ from '@monorepo/shared/languages.js'
import { useTranslation } from '@multilocale/react/index.js'
import input from '@stiloso/styles/input.js'
import label from '@stiloso/styles/label.js'

const arrowDown = css`
  border-bottom: 3px solid rgba(0, 0, 0, 0);
  border-left: 6px solid rgba(0, 0, 0, 0);
  border-right: 6px solid rgba(0, 0, 0, 0);
  border-top: 6px solid #6a768a;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  bottom: 18px;
  right: 14px;
`

const arrowUp = css`
  border-top: 3px solid rgba(0, 0, 0, 0);
  border-left: 6px solid rgba(0, 0, 0, 0);
  border-right: 6px solid rgba(0, 0, 0, 0);
  border-bottom: 6px solid var(--color-primary-lightest);
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  bottom: 22px;
  right: 14px;
`

const LanguageAutocomplete = ({
  languages,
  selectedLanguage,
  onSelectLanguage,
  inputLabel,
  autoFocused,
}) => {
  const { t } = useTranslation()
  const ref = useRef()
  const [searchQuery, setSearchQuery] = useState('')
  const [open, setOpen] = useState(false)

  if (!languages) {
    languages = Object.keys(languages_).map(languageCode => ({
      code: languageCode,
      name: languages_[languageCode],
    }))
  }

  const currentLanguage = languages.find(
    language => language.code === selectedLanguage,
  )

  const filteredLanguages = languages.filter(language =>
    language.name.match(new RegExp(searchQuery, 'gi')),
  )

  const onSearchLanguage = event => {
    const { value } = event.target
    setSearchQuery(value)
  }

  const onKeyDown = event => {
    if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  const onChangeLanguage = languageCode => {
    onSelectLanguage(languageCode)
    setSearchQuery('')
    setOpen(false)
  }

  useEffect(() => {
    function onClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setSearchQuery('')
        setOpen(false)
      }
    }
    document.addEventListener('mousedown', onClickOutside)

    return function cleanup() {
      document.removeEventListener('mousedown', onClickOutside)
    }
  }, [])

  return (
    <div css={{ paddingTop: 16 }}>
      <div ref={ref} css={{ position: 'relative' }}>
        {inputLabel !== false && (
          <label
            htmlFor={`input-${inputLabel}`}
            css={{
              fontSize: 12,
              background: 'white',
              position: 'absolute',
              top: '-7px',
              insetInlineStart: '8px',
              padding: '0 4px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textTransform: 'capitalize',
              visibility: inputLabel ? 'inherit' : 'hidden',
            }}
          >
            {t(inputLabel) || t('Language')}
          </label>
        )}
        <input
          id="languageInput"
          css={[
            input,
            !open && {
              '::placeholder': { color: '#6A768A' },
            },
          ]}
          type="text"
          placeholder={
            currentLanguage ? currentLanguage.name : t('Select language')
          }
          value={searchQuery}
          onFocus={() => setOpen(true)}
          onChange={event => {
            onSearchLanguage(event)
            setOpen(true)
          }}
          required={!selectedLanguage && true}
          onKeyDown={onKeyDown}
          autoComplete="off"
          autoFocus={autoFocused}
        />
        <label htmlFor="languageInput" css={open ? arrowUp : arrowDown} />
        {open && (
          <div
            css={{
              position: 'absolute',
              zIndex: 10000,
              maxHeight: 208,
              width: '100%',
              overflowY: 'auto',
              backgroundColor: 'var(--color-background)',
              border: '1px solid #D8E0ED',
              borderTop: 0,
            }}
          >
            {filteredLanguages?.map(language => (
              <div
                key={language.code}
                onClick={() => onChangeLanguage(language.code)}
                css={{
                  padding: 10,
                  ':hover, :focus-visible': {
                    backgroundColor: 'var(--color-primary-lightest)',
                    color: 'white',
                    cursor: 'pointer',
                  },
                }}
              >
                {language.name}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default LanguageAutocomplete
