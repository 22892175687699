/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useState, useRef, useEffect } from 'react'
import { jsx, css } from '@emotion/react'
import languages_ from '@monorepo/shared/languages.js'
import { useTranslation } from '@multilocale/react/index.js'
import IconLanguage from '@stiloso/icons/IconLanguage.js'
import clickable from '@stiloso/styles/clickable.js'

const LanguageAutocomplete = ({
  languages,
  selectedLanguage,
  onSelectLanguage,
}) => {
  const { t } = useTranslation()
  const ref = useRef()
  const [searchQuery, setSearchQuery] = useState('')
  const [open, setOpen] = useState(false)

  if (!languages) {
    languages = Object.keys(languages_).map(languageCode => ({
      code: languageCode,
      name: languages_[languageCode],
    }))
  }

  // const currentLanguage = languages.find(
  //   language => language.code === selectedLanguage,
  // )

  const filteredLanguages = languages.filter(language =>
    language.name.match(new RegExp(searchQuery, 'gi')),
  )

  const onSearchLanguage = event => {
    const { value } = event.target
    setSearchQuery(value)
  }

  const onKeyDown = event => {
    if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  const onChangeLanguage = languageCode => {
    onSelectLanguage(languageCode)
    setSearchQuery('')
    setOpen(false)
    const currentUrl = new URL(window.location.href)
    currentUrl.searchParams.set('language', languageCode)
    window.history.replaceState({}, '', currentUrl)
  }

  useEffect(() => {
    function onClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setSearchQuery('')
        setOpen(false)
      }
    }
    document.addEventListener('mousedown', onClickOutside)

    return function cleanup() {
      document.removeEventListener('mousedown', onClickOutside)
    }
  }, [])

  return (
    <div ref={ref} css={{ position: 'relative' }}>
      <div
        css={[
          clickable,
          {
            padding: 16,
            borderRadius: 4,
            display: 'flex',
            alignItems: ' center',
            gap: 8,
          },
        ]}
        onClick={() => {
          setOpen(!open)
        }}
      >
        {/* <div>{currentLanguage.name}</div> */}
        <IconLanguage />
      </div>
      {open && (
        <div
          css={{
            position: 'absolute',
            zIndex: 10000,
            overflowY: 'auto',
            backgroundColor: 'var(--color-background)',
          }}
        >
          <input
            type="text"
            placeholder={t('Search') + ' 🔍'}
            css={{
              width: '100%',
              padding: '12px 16px',
              border: 'none',
              color: 'var(--color-base)',
            }}
            value={searchQuery}
            onFocus={() => setOpen(true)}
            onChange={event => {
              onSearchLanguage(event)
              setOpen(true)
            }}
            required={!selectedLanguage && true}
            onKeyDown={onKeyDown}
            autoComplete="off"
            autoFocus
          />
          {filteredLanguages?.map(language => (
            <div
              key={language.code}
              onClick={() => onChangeLanguage(language.code)}
              css={{
                padding: '12 16px',
                ':hover, :focus-visible': {
                  backgroundColor: 'var(--color-primary-lightest)',
                  color: 'white',
                  cursor: 'pointer',
                },
              }}
            >
              {language.name}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default LanguageAutocomplete
