/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import IconDelete from '@stiloso/icons/IconDelete.js'
import IconDiscount from '@stiloso/icons/IconDiscount.js'
import IconPrint from '@stiloso/icons/IconPrint.js'
import IconSearch from '@stiloso/icons/IconSearch.js'
import clickable from '@stiloso/styles/clickable.js'

let actionbarbutton = css([
  clickable,
  {
    width: 64,
    height: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
])

const ActionBarButton = ({ action }) => {
  const navigate = useNavigate()
  return (
    <div css={actionbarbutton} onClick={() => navigate(`?action=${action}`)}>
      {action === 'delete' && <IconDelete />}
      {action === 'discount' && <IconDiscount />}
      {action === 'print' && <IconPrint />}
      {action === 'search' && <IconSearch />}
    </div>
  )
}

export default ActionBarButton
