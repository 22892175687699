/* Copyright 2013 - 2024 Waiterio LLC */
import {
  setAccessTokenForClient,
  setAccessTokenCallbackForClient,
} from '@monorepo/client/accessToken.js'
import { setRefreshTokenForClient } from '@monorepo/client/refreshToken.js'
import { getContext, produce } from '@monorepo/context/index.js'
import picks from '@monorepo/forage/picks.js'
import getAccessToken from '@monorepo/session/getAccessToken.js'
import getOrganizationId from '@monorepo/session/getOrganizationId.js'
import getRefreshToken from '@monorepo/session/getRefreshToken.js'
import getUserId from '@monorepo/session/getUserId.js'
import setAccessToken from '@monorepo/session/setAccessToken.js'

function arrayToMapOfIds(array) {
  return array.reduce((mapp, current) => {
    mapp[current._id] = current
    return mapp
  }, {})
}

export default async function rehydrateSession(classes) {
  const accessToken = getAccessToken()
  const refreshToken = getRefreshToken()

  setAccessTokenForClient(accessToken)
  setRefreshTokenForClient(refreshToken)
  setAccessTokenCallbackForClient(setAccessToken)

  let collectionsDocuments = await Promise.all(
    classes.map(Class => picks(Class)),
  )

  let { data } = getContext()

  produce(draft => {
    collectionsDocuments.forEach(documents => {
      if (documents.length) {
        let document = documents[0]
        const prototype = Object.getPrototypeOf(document)
        let { collection } = prototype.constructor
        draft.data[collection] = {
          ...data[collection],
          ...arrayToMapOfIds(documents),
        }
      }
    })

    draft.session.currentOrganizationId = getOrganizationId()
    draft.session.currentUserId = getUserId()
    draft.session.rehydrated = true
  })
}
