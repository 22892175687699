/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import getUserId from '@monorepo/session/getUserId.js'
import { setDefaultLanguage } from '@multilocale/react/index.js'
import useUser from './hooks/useUser.js'

const LanguageSetter = () => {
  const userId = getUserId()
  const user = useUser(userId)
  setDefaultLanguage(user?.language)

  return null
}

export default LanguageSetter
