/* Copyright 2013 - 2024 Waiterio LLC */
import uuid from '@monorepo/shared/uuid.js'

function Treatment(source) {
  if (source instanceof Treatment) {
    return source
  } else {
    Object.keys(source || {}).forEach(key => {
      this[key] = source[key]
    })

    if (!this._id) {
      this._id = uuid()
    }

    if (!this.creationTime) {
      this.creationTime = new Date().toISOString()
    }

    if (!this.lastEditTime) {
      this.lastEditTime = this.creationTime
    }

    return this
  }
}

Treatment.prototype.constructor = Treatment

Treatment.name_ = 'Treatment'
Treatment.collection = Treatment.name_.toLowerCase() + 's'

export default Treatment
