/* Copyright 2013 - 2024 Waiterio LLC */
import post from './post.js'

export default function postClass(Class, document) {
  return post({
    url: `${Class.collection}`,
    body: document,
  }).catch(error => {
    let newError = new Error("Couldn't add {document}", {
      cause: error,
    })
    newError.variables = { document: Class.name_.toLowerCase() }
    throw newError
  })
}
