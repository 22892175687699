/* Copyright 2013 - 2024 Waiterio LLC */
import uploadUserAvatar from '@monorepo/client/uploadUserAvatar.js'
import plant from '@monorepo/forage/plant.js'
import User from '@dododentist/model/User.js'

const updateUserWithAvatar = async ({ file, userId }) => {
  // console.log('updateUserWithAvatar', userId, file)
  let user = await uploadUserAvatar({ file, userId })

  return plant(User, user)
}

export default updateUserWithAvatar
