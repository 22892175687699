/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  flag: '🇨🇻',
  countryName: 'Cape Verde',
  phonePrefix: '+238',
  administrativeDivisionsType: 'island',
  countryCode: 'CV',
  languages: ['pt'],
  zipcodePattern: '\\d{4}',
}
