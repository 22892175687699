/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useState, Suspense, useMemo } from 'react'
import { css, jsx } from '@emotion/react'
import { Link, useParams } from 'react-router-dom'
import useLoggedInSession from '@monorepo/session/useLoggedInSession.js'
import convertStringToColor from '@monorepo/shared/convertStringToColor.js'
import generatePermutations from '@monorepo/shared/generatePermutations.js'
import { useTranslation } from '@multilocale/react/index.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarButton from '@stiloso/components/AppBarButton.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import ErrorBoundary from '@stiloso/components/ErrorBoundary.js'
import SearchBar from '@stiloso/components/SearchBar.js'
import Spinner from '@stiloso/components/Spinner.js'
import card from '@stiloso/styles/card.js'
import clickable from '@stiloso/styles/clickable.js'
import section from '@stiloso/styles/section.js'
import Layout from '../components/Layout.js'
import usePatients from '../hooks/usePatients.js'

let patientCard = css([
  clickable,
  card,
  {
    lineHeight: '48px',
    width: '100%',
    padding: '0 16',
  },
])

const avatarContainer = css`
  display: flex;
  color: white;
  fontsize: 28px;
  texttransform: capitalize;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  min-width: 32px;
  border-radius: 32px;
  margin: 8px 8px 8px 0;
`

export const paths = ['/clinics/:clinicId/patients']

const PatientsPage = props => {
  const { t } = useTranslation()
  let { clinicId, patients } = props
  const [searchQuery, setSearchQuery] = useState('')

  // console.log('patients', patients)

  patients = useMemo(() => {
    if (patients && searchQuery) {
      return patients?.filter(({ name, phone }) => {
        name &&= name?.trim()?.toLowerCase() ?? ''
        phone &&= phone?.replaceAll(' ', '')?.toLowerCase() ?? ''

        let permutations = generatePermutations(name.split(' ')).map(
          permutation => permutation?.join(' '),
        )
        permutations.push(phone)
        if (
          permutations.some(permutation => permutation?.includes(searchQuery))
        ) {
          return true
        } else {
          return false
        }
      })
    } else {
      return patients
    }
  }, [patients, searchQuery])

  const onSearch = searchQuery_ => {
    setSearchQuery(searchQuery_)
  }

  const onCancelSearch = () => {
    setSearchQuery('')
  }

  return (
    <div
      css={{
        margin: '16 0',
        display: 'flex',
        flexWrap: 'wrap',
        gap: 16,
      }}
    >
      {(patients.length > 0 || searchQuery?.length > 0) && (
        <SearchBar
          placeholder={t('Search patient')}
          onSearch={onSearch}
          onCancel={onCancelSearch}
          flat
        />
      )}
      {patients?.map(({ _id, color, initial, name }) => (
        <Link
          key={_id}
          css={patientCard}
          to={`/clinics/${clinicId}/patients/${_id}`}
        >
          <div
            css={[
              avatarContainer,
              {
                background: color,
              },
            ]}
          >
            {initial}
          </div>
          {name}
        </Link>
      ))}
    </div>
  )
}

const PatientsPageConnected = () => {
  useLoggedInSession()
  const { t } = useTranslation()
  const { clinicId } = useParams()
  let patients = usePatients({ clinicId })

  patients = patients?.sort((a, b) => {
    let sort = 0

    sort = a.name > b.name ? 1 : -1

    if (sort === 0) {
      sort = a._id > b._id ? 1 : -1
    }

    return sort
  })

  patients = patients?.map(patient => ({
    ...patient,
    color: convertStringToColor(patient?._id),
    initial: patient?.name?.charAt(0) || patient?.email?.charAt(0) || '?',
  }))

  let title = t('Patients')
  title = patients.length > 1 ? `${title} (${patients.length})` : title

  return (
    <div css={[section]}>
      <AppBar>
        <AppBarTitle>{title}</AppBarTitle>
        <div css={{ flexGrow: 1 }} />
        <AppBarButton
          label={t('New patient')}
          to={`/clinics/${clinicId}/patients/new`}
        />
      </AppBar>
      <PatientsPage clinicId={clinicId} patients={patients} />
    </div>
  )
}

const PatientsPageWrapper = () => {
  const { t } = useTranslation()

  return (
    <Layout title={t('Patients')}>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <PatientsPageConnected />
        </Suspense>
      </ErrorBoundary>
    </Layout>
  )
}

export default PatientsPageWrapper
