/* Copyright 2013 - 2024 Waiterio LLC */
import uuid from '@monorepo/shared/uuid.js'

function Clinic(source) {
  if (source instanceof Clinic) {
    return source
  } else {
    Object.keys(source || {}).forEach(key => {
      this[key] = source[key]
    })

    if (!this._id) {
      this._id = uuid()
    }

    if (!this.creationTime) {
      this.creationTime = new Date().toISOString()
    }

    if (!this.lastEditTime) {
      this.lastEditTime = this.creationTime
    }

    if (!this.firstDayOfTheWeek) {
      if (this.countryCode === 'US') {
        this.firstDayOfTheWeek = 'Sunday'
      } else {
        this.firstDayOfTheWeek = 'Monday'
      }
    }

    if (!this.openingHours) {
      this.openingHours = []
    }

    if (!this.reminders) {
      this.reminders = []
    }

    if (!this.appointmentDuration) {
      this.appointmentDuration = 30
    }

    return this
  }
}

Clinic.prototype.constructor = Clinic

Clinic.name_ = 'Clinic'
Clinic.collection = Clinic.name_.toLowerCase() + 's'

export default Clinic
