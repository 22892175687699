/* Copyright 2013 - 2024 Waiterio LLC */
import uuid from '@monorepo/shared/uuid.js'
import Reminder from './Reminder.js'

function Appointment(source) {
  if (source instanceof Appointment) {
    return source
  } else {
    Object.keys(source || {}).forEach(key => {
      this[key] = source[key]
    })

    if (!this._id) {
      this._id = uuid()
    }

    if (!this.creationTime) {
      this.creationTime = new Date().toISOString()
    }

    if (!this.lastEditTime) {
      this.lastEditTime = this.creationTime
    }

    if (!this.reminders) {
      this.reminders = []
    }

    if (this.reminders.length) {
      this.reminders = this.reminders.map(reminder => new Reminder(reminder))
    }

    return this
  }
}

Appointment.prototype.constructor = Appointment

Appointment.name_ = 'Appointment'
Appointment.collection = Appointment.name_.toLowerCase() + 's'

export default Appointment
