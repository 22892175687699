/* Copyright 2013 - 2024 Waiterio LLC */
import localForage from 'localforage'
import PRODUCT from '@monorepo/env/PRODUCT.js'

function picks(Class) {
  const { collection } = Class
  return localForage
    .keys()
    .then(keys_ =>
      Promise.all(
        keys_
          .filter(key => key.indexOf(`${PRODUCT}.${collection}.`) > -1)
          .map(key =>
            localForage.getItem(key).then(document => new Class(document)),
          ),
      ),
    )
}

export default picks
