/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */

/* eslint-disable max-len */
import React, { Suspense, useEffect, useRef, useState } from 'react'
import { css, jsx } from '@emotion/react'
import {
  Link,
  unstable_useBlocker as useBlocker,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { format } from 'date-fns'
import countries from '@monorepo/countries/index.js'
import getUserId from '@monorepo/session/getUserId.js'
import useLoggedInSession from '@monorepo/session/useLoggedInSession.js'
import { toastError } from '@monorepo/shared/AndroidToast.js'
import preferCapitalizedEachWord from '@monorepo/shared/preferCapitalizedEachWord.js'
import uuid from '@monorepo/shared/uuid.js'
import addOrUpdate from '@monorepo/tractor/addOrUpdate.js'
import remove from '@monorepo/tractor/remove.js'
import removeByQuery from '@monorepo/tractor/removeByQuery.js'
import Appointment from '@dododentist/model/Appointment.js'
import Patient from '@dododentist/model/Patient.js'
import Treatment from '@dododentist/model/Treatment.js'
import { useTranslation } from '@multilocale/react/index.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarButton from '@stiloso/components/AppBarButton.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import ButtonBar from '@stiloso/components/ButtonBar.js'
import DeleteDialog from '@stiloso/components/DeleteDialog.js'
import ErrorBoundary from '@stiloso/components/ErrorBoundary.js'
import Input from '@stiloso/components/Input.js'
import PhoneInput from '@stiloso/components/PhoneInput.js'
import Spinner from '@stiloso/components/Spinner.js'
import IconDelete from '@stiloso/icons/IconDelete.js'
import colMd6 from '@stiloso/styles/bootstrap/colMd6.js'
import colSm12 from '@stiloso/styles/bootstrap/colSm12.js'
import container from '@stiloso/styles/bootstrap/container.js'
import dLgBlock from '@stiloso/styles/bootstrap/dLgBlock.js'
import dNone from '@stiloso/styles/bootstrap/dNone.js'
import row from '@stiloso/styles/bootstrap/row.js'
import card from '@stiloso/styles/card.js'
import clickable from '@stiloso/styles/clickable.js'
import AnamnesisSection from '../components/AnamnesisSection.js'
import DentalHistorySection from '../components/DentalHistorySection.js'
import ImagesSection from '../components/ImagesSection.js'
import InvoicesSection from '../components/InvoicesSection.js'
import Layout from '../components/Layout.js'
import PaymentsSection from '../components/PaymentsSection.js'
import SectionsBar, { Holds } from '../components/SectionsBar.js'
import TreatmentsSection from '../components/TreatmentsSection.js'
import SaveChangesPrompt from '../dialogs/SaveChangesPrompt.js'
import useAppointments from '../hooks/useAppointments.js'
import useClinic from '../hooks/useClinic.js'
import usePatient from '../hooks/usePatient.js'
import useUser from '../hooks/useUser.js'

export const paths = ['/clinics/:clinicId/patients/:patientId']

let appointmentCard = css([
  clickable,
  card,
  {
    lineHeight: '48px',
    width: '100%',
    marginTop: 16,
    padding: '0 16px',
    display: 'flex',
    gap: 8,
  },
])

const PatientPage = props => {
  let { action, appointments, clinic, patient, user } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [edited, setEdited] = useState(false)
  const [isLoading, setLoading] = useState(false)
  let [_id, setId] = useState(patient?._id || uuid())
  let [name, setName] = useState('')
  let [phone, setPhone] = useState('')
  let [email, setEmail] = useState('')
  let [anamnesis, setAnamnesis] = useState({})
  let [teeth, setTeeth] = useState({})
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] =
    useState(false)

  let appointmentsRef = useRef(null)
  let paymentsRef = useRef(null)
  let invoicesRef = useRef(null)
  let patientRef = useRef(null)
  let dentalHistoryRef = useRef(null)
  let imagesRef = useRef(null)
  let treatmentsRef = useRef(null)
  let anamnesisRef = useRef(null)

  let sections = [
    { name: t('Patient'), ref: patientRef },
    { name: t('Images'), ref: imagesRef },
    { name: t('Dental history'), ref: dentalHistoryRef },
    { name: t('Appointments'), ref: appointmentsRef },
    { name: t('Payments'), ref: paymentsRef },
    // { name: t('Invoices'), ref: invoicesRef },
    { name: t('Treatments'), ref: treatmentsRef },
    { name: t('Anamnesis'), ref: anamnesisRef },
  ]

  const changeName = event => {
    setEdited(true)
    setName(event.target.value)
  }

  const changeEmail = event => {
    setEdited(true)
    setEmail(event.target.value)
  }

  const changePhone = phone => {
    setEdited(true)
    setPhone(phone)
  }

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      edited && currentLocation.pathname !== nextLocation.pathname,
  )

  useEffect(() => {
    if (!edited) {
      blocker?.proceed?.()
    }
  }, [edited])

  const addOrUpdatePatient = async event => {
    event?.preventDefault?.()

    try {
      name = name?.trim() ?? ''
      name = preferCapitalizedEachWord(name)
      phone = phone?.replace(/[ \\(\\)\\-]/g, '')
      phone = phone?.length > 4 ? phone : null
      const newPatient = new Patient({
        _id,
        ...patient,
        lastEditTime: new Date().toISOString(),
        organizationId: clinic.organizationId,
        clinicId: clinic._id,
        name,
        phone,
        email,
        teeth,
        anamnesis,
      })

      if (edited) {
        setLoading(true)
        await addOrUpdate(newPatient, patient)
        setLoading(false)
        setEdited(false)
        navigate(`/clinics/${clinic._id}/patients`)
      }

      return newPatient
    } catch (error) {
      setLoading(false)
      setEdited(true)
      toastError(t(error))
    }
  }

  let closePrompt = () => {
    blocker.reset()
  }

  let discardChangesPrompt = () => {
    blocker.proceed()
  }

  const showDeleteConfirmationDialog = () => {
    setDeleteConfirmationDialog(true)
  }

  const hideDeleteConfirmationDialog = () => {
    setDeleteConfirmationDialog(false)
  }

  let onDelete = async event => {
    event?.preventDefault?.()

    try {
      if (patient) {
        await Promise.all([
          remove(patient),
          removeByQuery(Appointment, { patientId: patient._id }),
          removeByQuery(Treatment, { patientId: patient._id }),
        ])
      }

      setEdited(false)
      navigate(`/clinics/${clinic._id}/patients`)
    } catch (error) {
      toastError(t(error))
    }
  }

  let saveChangesPrompt = async () => {
    await addOrUpdatePatient()
    blocker.proceed()
  }

  let changeAnamnesis = anamnesis => {
    setEdited(true)
    setAnamnesis(anamnesis)
  }

  let changeTeeth = teeth => {
    console.log('changeTeeth', teeth)
    setEdited(true)
    setTeeth(teeth)
  }

  useEffect(() => {
    if (patient) {
      setId(patient._id)
      setName(patient.name)
      setPhone(patient.phone)
      setEmail(patient.email)
      setTeeth(patient.teeth || {})
      setAnamnesis(patient.anamnesis || {})
    }
  }, [patient])

  useEffect(() => {
    if (clinic?.countryCode) {
      let patientPhone = patient?.phone || ''

      if (!patientPhone.includes('+') && clinic.countryCode) {
        let prefix = countries[clinic.countryCode]?.phonePrefix || ''
        patientPhone = prefix + patientPhone
        setPhone(patientPhone)
      }

      setPhone(patientPhone)
    }
  }, [clinic?.countryCode])

  useEffect(() => {
    if (action) {
      navigate('?action=')
    }

    if (action === 'delete') {
      showDeleteConfirmationDialog()
    }
  }, [action])

  let days

  if (appointments) {
    appointments = appointments?.sort((a, b) =>
      a.startTime > b.startTime ? -1 : 1,
    )

    appointments = appointments?.map(appointment => ({
      ...appointment,
      patient,
    }))
    let days2appointments = appointments.reduce(
      (days2appointments, appointment) => {
        let day = appointment.startTime.split('T')[0]

        if (!days2appointments[day]) {
          days2appointments[day] = []
        }

        days2appointments[day].push(appointment)

        return days2appointments
      },
      {},
    )

    let today = new Date().toISOString().split('T')[0]
    let tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      .toISOString()
      .split('T')[0]

    days = Object.keys(days2appointments)
      .sort((a, b) => (a.startTime > b.startTime ? -1 : 1))
      .map(day => {
        let label = new Date(day).toLocaleDateString(user?.language || 'en', {
          weekday: 'long',
          month: 'long',
          day: 'numeric',
        })

        if (day === today) {
          label = t('Today') + ', ' + label
        } else if (day === tomorrow) {
          label = t('Tomorrow') + ', ' + label
        }

        return { label, appointments: days2appointments[day] }
      })
  }

  return (
    <form onSubmit={addOrUpdatePatient}>
      <SectionsBar sections={sections} />
      <div css={container}>
        <div css={row}>
          <div css={[colSm12, colMd6]}>
            <Holds ref={patientRef} />
            <div css={[dNone, dLgBlock]}>
              <AppBar>
                <AppBarTitle>
                  {patient ? t('Patient') + ' ' + name : t('New patient')}
                </AppBarTitle>
                <div css={{ flexGrow: 1 }} />
                {patient && (
                  <AppBarButton
                    appearance="secondary"
                    Icon={IconDelete}
                    label={t('Delete')}
                    onClick={showDeleteConfirmationDialog}
                  />
                )}
              </AppBar>
            </div>
            <div css={[card, { marginTop: 16 }]}>
              <Input
                label={t('Name')}
                value={name}
                onChange={changeName}
                autoFocus
                autoComplete="off"
              />
              <PhoneInput
                phone={phone}
                onChange={changePhone}
                required={false}
              />
              <Input
                label={t('email')}
                value={email}
                type="email"
                onChange={changeEmail}
                autoComplete="off"
              />
            </div>
            <Holds ref={imagesRef} />
            <ImagesSection
              addOrUpdate={addOrUpdatePatient}
              clinicId={clinic._id}
              patientId={patient?._id}
            />
            <Holds ref={dentalHistoryRef} />
            <DentalHistorySection changeTeeth={changeTeeth} teeth={teeth} />
            <Holds ref={anamnesisRef} />
            <AnamnesisSection
              anamnesis={anamnesis}
              setAnamnesis={changeAnamnesis}
              clinicId={clinic._id}
              patientId={patient?._id}
            />
          </div>

          <div css={[colSm12, colMd6]}>
            <Holds ref={appointmentsRef} />
            <AppBar>
              <AppBarTitle>{t('Appointments')}</AppBarTitle>
              <div css={{ flexGrow: 1 }} />
              <AppBarButton
                appearance="secondary"
                label={t('New appointment')}
                onClick={async () => {
                  await addOrUpdatePatient()
                  navigate(
                    `/clinics/${clinic._id}/appointments/new?patientId=${_id || ''}`,
                  )
                }}
              />
            </AppBar>
            <div
              css={{
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              {days?.map(({ label, appointments }, i) => (
                <div key={label} css={{ width: '100%' }}>
                  <div css={{ paddingTop: i > 0 ? 16 : 0 }}>{label}</div>
                  {appointments?.map(({ _id, startTime, note, procedures }) => (
                    <Link
                      key={_id}
                      css={appointmentCard}
                      to={`/clinics/${clinic._id}/appointments/${_id}`}
                    >
                      <span css={{ flexGrow: 1 }}>
                        {format(startTime, 'HH:mm')}&nbsp;&nbsp;
                      </span>
                      {note && <span>{note}</span>}
                      {procedures?.map(({ name }) => (
                        <span>{name}</span>
                      ))}
                    </Link>
                  ))}
                </div>
              ))}
            </div>
            <Holds ref={treatmentsRef} />
            <TreatmentsSection
              addOrUpdate={addOrUpdatePatient}
              clinicId={clinic._id}
              patientId={patient?._id}
            />
            <Holds ref={paymentsRef} />
            <PaymentsSection
              addOrUpdate={addOrUpdatePatient}
              clinicId={clinic._id}
              currency={clinic.currency}
              patientId={patient?._id}
            />
            <Holds ref={invoicesRef} />
            <InvoicesSection
              addOrUpdate={addOrUpdatePatient}
              clinicId={clinic._id}
              currency={clinic.currency}
              patientId={patient?._id}
            />
          </div>
        </div>
        {(edited || !patient) && (
          <ButtonBar appearance="secondary" type="submit" isLoading={isLoading}>
            {patient ? t('Save patient') : t('Add patient')}
          </ButtonBar>
        )}
      </div>
      {blocker?.state === 'blocked' && (
        <SaveChangesPrompt
          close={closePrompt}
          discardChanges={discardChangesPrompt}
          saveChanges={saveChangesPrompt}
        />
      )}

      {deleteConfirmationDialog && (
        <DeleteDialog
          title={t('Delete patient')}
          message={t(
            'Do you want to delete patient {patient_name}’s appointments, treatments, and related records?',
          ).replace('{patient_name}', name)}
          onDelete={onDelete}
          close={hideDeleteConfirmationDialog}
          loading={isLoading}
        />
      )}
    </form>
  )
}

const PatientPageConnected = () => {
  useLoggedInSession()
  const { clinicId, patientId } = useParams()
  const [searchParams] = useSearchParams()
  const action = searchParams.get('action')
  const clinic = useClinic(clinicId)
  let user = useUser(getUserId())
  const patient = usePatient(patientId)
  let appointments = useAppointments({ clinicId, patientId })
  // console.log({ appointments })

  return (
    <PatientPage
      action={action}
      appointments={appointments}
      clinic={clinic}
      patient={patient}
      user={user}
    />
  )
}

const PatientPageWrapper = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { clinicId, patientId } = useParams()
  const patient = usePatient(patientId)
  const title = patient ? t('Patient') + ' ' + patient.name : t('New patient')
  const onClickTitle = () => {
    navigate(`/clinics/${clinicId}/patients`)
  }
  const actions = [patient && 'delete'].filter(_ => _)
  return (
    <Layout
      actions={actions}
      back
      main={false}
      onClickTitle={onClickTitle}
      title={title}
    >
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <PatientPageConnected />
        </Suspense>
      </ErrorBoundary>
    </Layout>
  )
}

export default PatientPageWrapper
