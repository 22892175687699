/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  flag: '🇦🇺',
  countryName: 'Australia',
  phonePrefix: '+61',
  administrativeDivisionsNames: [
    'Australian Capital Territory',
    'Jervis Bay Territory',
    'New South Wales',
    'Northern Territory',
    'Queensland',
    'South Australia',
    'Tasmania',
    'Victoria',
    'Western Australia',
  ],
  administrativeDivisionsType: 'state',
  countryCode: 'AU',
  languages: ['en'],
  streetNumberBeforeStreetName: true,
  zipcodePattern: '\\d{4}',
}
