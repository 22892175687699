/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useState } from 'react'
import { jsx, css } from '@emotion/react'
import normalizeEmail from '@monorepo/shared/normalizeEmail.js'
import { useTranslation } from '@multilocale/react/index.js'
import Button from '@stiloso/components/Button.js'
import Dropdown from '@stiloso/components/Dropdown.js'
import LanguageAutocomplete from '@stiloso/components/LanguageAutocomplete.js'
import Modal from '@stiloso/components/Modal.js'
import input from '@stiloso/styles/input.js'
import label from '@stiloso/styles/label.js'

const InviteTeamMemberDialog = ({ addInvite, close, roles, userId }) => {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [role, setRole] = useState(roles[0])
  const [error, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState(null)

  const changeEmail = event => {
    const email = event.target.value
    setEmail(email)
  }

  const onSelectRole = selectedRole => {
    setRole(selectedRole)
  }

  const onSelectLanguage = language => {
    setSelectedLanguage(language)
  }

  const inviteTeamMember = async event => {
    event.preventDefault()

    const normalizedEmail = normalizeEmail(email?.trim())

    const invite = {
      roleType: role,
      email: normalizedEmail,
      inviterUserId: userId,
      localeMotherTongue: selectedLanguage || undefined,
    }

    setLoading(true)
    addInvite(invite)
      .then(() => {
        setLoading(false)
        close()
      })
      .catch(error => {
        setLoading(false)
        setError(error)
      })
  }

  return (
    <Modal close={close} size="medium">
      <div css={{ margin: 24 }}>
        <div css={{ fontWeight: 'bold', color: 'var(--color-base)' }}>
          {t('Invite team member')}
        </div>
        <form css={{ marginTop: 16 }} onSubmit={inviteTeamMember}>
          <div>
            <label css={label} htmlFor="email">
              {t('email')}
            </label>
            <input
              id="email"
              css={input}
              type="email"
              value={email}
              onChange={changeEmail}
              required
              autoComplete="off"
              autoFocus
            />
          </div>
          <Dropdown
            name="role"
            labels={roles}
            values={roles}
            currentValue={role}
            onSelect={onSelectRole}
          />
          {role === 'translator' && (
            <LanguageAutocomplete
              selectedLanguage={selectedLanguage}
              onSelectLanguage={onSelectLanguage}
            />
          )}
          <div
            css={{
              marginTop: 32,
              marginBottom: 32,
              height: 2,
              backgroundColor: '#D8E0ED',
            }}
          />
          <Button type="submit" isLoading={isLoading} fullWidth>
            {t('Invite')}
          </Button>

          {error && (
            <div
              css={{
                backgroundColor: 'var(--color-red-lightest)',
                padding: 8,
                marginTop: 16,
                fontSize: 14,
              }}
            >
              {t(error?.message)}
            </div>
          )}
        </form>
      </div>
    </Modal>
  )
}

export default InviteTeamMemberDialog
