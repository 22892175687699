/* Copyright 2013 - 2024 Waiterio LLC */

function generatePermutations(arr) {
  const result = []

  function permute(current, remaining) {
    if (remaining.length === 0) {
      result.push(current)
    } else {
      for (let i = 0; i < remaining.length; i += 1) {
        // Choose the current element
        const next = remaining[i]
        // Create a new remaining array without the chosen element
        const newRemaining = remaining
          .slice(0, i)
          .concat(remaining.slice(i + 1))
        // Recur with the chosen element added to current
        permute(current.concat(next), newRemaining)
      }
    }
  }

  permute([], arr)
  return result
}

export default generatePermutations
