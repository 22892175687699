/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { css, jsx } from '@emotion/react'
import countries_ from '@monorepo/countries/index.js'
import { useTranslation } from '@multilocale/react/index.js'
import IconClose from '@stiloso/icons/IconClose.js'
import clickable from '@stiloso/styles/clickable.js'

function getPhonePrefixFromPhone(phone) {
  let phonePrefix = ''

  if (phone) {
    let countries = Object.values(countries_).sort(
      (a, b) => b.phonePrefix.length - a.phonePrefix.length,
    )
    let i = 0
    while (!phonePrefix && i < countries.length) {
      const country = countries[i]
      if (phone.startsWith(country.phonePrefix)) {
        phonePrefix = country.phonePrefix
      }
      i += 1
    }
  }

  return phonePrefix
}

const arrowDown = css`
  border-bottom: 3px solid rgba(0, 0, 0, 0);
  border-left: 6px solid rgba(0, 0, 0, 0);
  border-right: 6px solid rgba(0, 0, 0, 0);
  border-top: 6px solid #6a768a;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  margin-inline-start: 8px;
  position: 'relative';
`

const arrowUp = css`
  border-top: 3px solid rgba(0, 0, 0, 0);
  border-left: 6px solid rgba(0, 0, 0, 0);
  border-right: 6px solid rgba(0, 0, 0, 0);
  border-bottom: 6px solid var(--color-primary-lightest);
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  margin-left: 8px;
  position: 'relative';
  margin-bottom: 3px;
`

const PhoneInput = ({ label, onChange, phone, placeholder, required }) => {
  required ||= false

  const wrapperRef = useRef(null)
  const { t } = useTranslation()
  const [searchQuery, setSearchQuery] = useState('')
  const prefix = useMemo(() => getPhonePrefixFromPhone(phone), [phone])

  label ||= t('phone')
  placeholder ||= label

  const countries = useMemo(
    () =>
      Object.values(countries_)
        .filter(
          ({ countryName }) =>
            countryName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            t(countryName)?.toLowerCase().includes(searchQuery.toLowerCase()),
        )
        .map(country => ({
          ...country,
          countryName: t(country.countryName),
        }))
        .sort((a, b) => a.countryName.localeCompare(b.countryName)),
    [searchQuery],
  )

  const [isVisible, setVisible] = useState(false)

  const indexCurrentValue = countries.findIndex(
    country => country.phonePrefix === prefix,
  )
  const currentFlag =
    countries && countries.length > indexCurrentValue
      ? countries[indexCurrentValue]?.flag
      : prefix

  const toggleDropdown = () => {
    setVisible(prevState => !prevState)
  }

  const onChangePhone = event => {
    onChange(event.target.value)
  }

  const onSelectPhonePrefix = newPhonePrefix => {
    let newPhone = phone || ''
    if (prefix) {
      newPhone = newPhone.replace(prefix, newPhonePrefix)
    } else {
      newPhone = newPhonePrefix + newPhone
    }

    onChange(newPhone)
    setSearchQuery('')
    toggleDropdown()
  }
  let borderColor = prefix || !phone?.length ? '#d8e0ed' : 'var(--color-red)'

  if (isVisible) {
    borderColor = 'var(--color-primary-lightest)'
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setVisible(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  // Don't edit this part without running:
  // jest PhoneInput --watch
  let prefixLength = 3
  let phoneLength = phone?.length || 0
  let patternFull = `\\+[0-9 \\-\\(\\)]{${prefixLength + 1},}`
  let patternEmpty = '\\+[0-9 \\-\\(\\)]{0,}'
  required ||= phoneLength > prefixLength
  let pattern = required ? patternFull : patternEmpty

  return (
    <div css={{ paddingTop: 16 }}>
      <div ref={wrapperRef} css={{ position: 'relative', width: '100%' }}>
        <label
          htmlFor={`input-${label}`}
          css={{
            fontSize: 12,
            background: 'white',
            position: 'absolute',
            top: '-7px',
            insetInlineStart: '76px',
            padding: '0 4px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textTransform: 'capitalize',
            visibility: phone ? 'inherit' : 'hidden',
          }}
        >
          {label}
        </label>
        <div
          id="phoneInputParent"
          css={{
            width: '100%',
            boxShadow: isVisible
              ? 'inset 0px 0px 0px 2px var(--color-primary-lightest)'
              : 'inset 0px 0px 0px 1px #d8e0ed',
            borderRadius: 4,
            height: 48,
            textAlign: 'start',
            display: 'flex',

            color: isVisible ? 'var(--color-primary-lightest)' : '#6a768a',
            ':focus-within': {
              boxShadow: 'inset 0px 0px 0px 2px var(--color-primary-lightest)',
              '& button': {
                boxShadow:
                  'inset 0px 0px 0px 1px var(--color-primary-lightest)',
                border: '1px solid var(--color-primary-lightest)',
              },
            },
          }}
        >
          <button
            type="button"
            css={{
              cursor: 'pointer',
              padding: 12,
              backgroundColor: 'transparent',
              border: '1px solid',
              borderColor,
              borderRadius: 4,
              flexShrink: 0,
              '&:not(:disabled):hover': {
                backgroundImage: `linear-gradient(
                var(--color-hover, rgba(0, 0, 0, 0.1)),
                var(--color-hover, rgba(0, 0, 0, 0.1))
              )`,
              },
            }}
            onClick={toggleDropdown}
          >
            {currentFlag || '🏳️'}
            <span css={isVisible ? arrowUp : arrowDown} />
          </button>
          <input
            id="phoneInput"
            css={{
              lineHeight: '48px',
              flexGrow: 1,
              padding: '0 16',
              '&::placeholder': {
                textTransform: 'capitalize',
              },
              '@media (hover: hover)': {
                '&:not(:disabled):not(:focus-within):hover': {
                  boxShadow:
                    'inset 0px 0px 0px 2px var(--color-primary-lighter)',
                  borderRadius: 4,
                },
              },
            }}
            type="tel"
            value={phone}
            placeholder={placeholder}
            onChange={onChangePhone}
            pattern={pattern}
            required={required}
          />
        </div>
        {isVisible && (
          <div
            css={{
              position: 'absolute',
              top: '100%',
              insetInlineStart: 0,
              width: '100%',
              zIndex: 99999,
              overflowY: 'auto',
              backgroundColor: 'var(--color-background)',
              boxShadow: 'inset 0px 0px 0px 1px #D8E0ED',
              borderTop: 0,
              borderRadius: 4,
            }}
          >
            <div css={{ display: 'flex', width: '100%' }}>
              <input
                css={{ padding: 12, flexGrow: 1 }}
                placeholder={`🔍 ${t('Search country')}`}
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
                autoFocus
              />
              {searchQuery && (
                <div
                  css={[clickable, { width: 48, height: 48 }]}
                  onClick={() => setSearchQuery('')}
                >
                  <IconClose css={{ margin: 12 }} />
                </div>
              )}
            </div>
            {countries?.map(({ countryName, flag, phonePrefix }) => (
              <button
                key={countryName}
                onClick={() => onSelectPhonePrefix(phonePrefix)}
                type="button"
                css={{
                  padding: 12,
                  background: 'transparent',
                  width: '100%',
                  border: 0,
                  cursor: 'pointer',
                  ':hover, :focus': {
                    backgroundColor: 'var(--color-primary-lightest)',
                    color: 'white',
                  },
                  textAlign: 'start',
                }}
              >
                {`${flag || ''} ${t(countryName)} ${phonePrefix}`}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default PhoneInput
