/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useEffect, useRef, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import clickable from '@stiloso/styles/clickable.js'

const arrowDown = css`
  border-bottom: 3px solid rgba(0, 0, 0, 0);
  border-left: 6px solid rgba(0, 0, 0, 0);
  border-right: 6px solid rgba(0, 0, 0, 0);
  border-top: 6px solid #6a768a;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  bottom: 18px;
  inset-inline-end: 14px;
`

const arrowUp = css`
  border-top: 3px solid rgba(0, 0, 0, 0);
  border-left: 6px solid rgba(0, 0, 0, 0);
  border-right: 6px solid rgba(0, 0, 0, 0);
  border-bottom: 6px solid var(--color-primary-lightest);
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  bottom: 22px;
  inset-inline-end: 14px;
`

const Dropdown = ({
  name,
  labels,
  values,
  onSelect,
  currentValue,
  transparent = true,
  placeholder,
}) => {
  const wrapperRef = useRef(null)
  const { t } = useTranslation()
  const [isVisible, setVisible] = useState(false)

  name = name ? t(name) : null
  placeholder ||= name
  values ||= []
  labels ||= []

  const indexCurrentValue = values.findIndex(
    element => element === currentValue,
  )
  let currentLabel =
    labels && labels.length > indexCurrentValue
      ? labels[indexCurrentValue]
      : currentValue

  currentLabel =
    typeof currentLabel === 'number' ? '' + currentLabel : currentLabel

  let isString = typeof currentLabel === 'string'
  currentLabel = isString ? t(currentLabel) : currentLabel

  let color = isVisible ? 'var(--color-primary-lightest)' : '#6a768a'
  color = !currentValue && placeholder ? 'var(--color-placeholder)' : color

  const toggleDropdown = () => {
    if (values.length > 1) {
      setVisible(prevState => !prevState)
    }
  }

  const onSelectValue = value => {
    onSelect(value)
    toggleDropdown()
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setVisible(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div css={{ paddingTop: 16, width: '100%' }}>
      <div ref={wrapperRef} css={{ position: 'relative' }}>
        {name && (
          <label
            htmlFor={`input-${name}`}
            onClick={toggleDropdown}
            css={{
              fontSize: 12,
              background: 'white',
              position: 'absolute',
              top: '-7px',
              insetInlineStart: '8px',
              padding: '0 4px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textTransform: 'capitalize',
              visibility: currentValue ? 'inherit' : 'hidden',
              borderRadius: 4,
            }}
          >
            {name}
          </label>
        )}

        <button
          type="button"
          css={[
            values.length > 1 ? clickable : {},
            {
              width: '100%',
              boxShadow: isVisible
                ? 'inset 0px 0px 0px 2px var(--color-primary-lightest)'
                : 'inset 0px 0px 0px 1px #d8e0ed',
              borderRadius: 4,
              padding: isString || (!currentLabel && placeholder) ? 12 : 0,
              height: 50,
              color,
              cursor: values.length > 1 ? 'pointer' : 'default',
              backgroundColor: !transparent ? 'white' : 'transparent',
              textAlign: 'start',
              border: 0,
            },
          ]}
          onClick={toggleDropdown}
        >
          {currentLabel || placeholder}
          {values.length > 1 && <span css={isVisible ? arrowUp : arrowDown} />}
        </button>
        {isVisible && (
          <div
            css={{
              position: 'absolute',
              top: '100%',
              left: 0,
              width: '100%',
              zIndex: 99999,
              overflowY: 'auto',
              backgroundColor: transparent
                ? 'var(--color-background)'
                : 'white',
              boxShadow: 'inset 0px 0px 0px 1px #D8E0ED',
              borderTop: 0,
              borderRadius: 4,
            }}
          >
            {values?.map((value, index) => {
              let label = labels[index]
              label = typeof label === 'number' ? '' + label : label
              let isString = typeof label === 'string'
              if (isString) {
                label = t(label)
              }

              return (
                value !== currentValue && (
                  <button
                    key={value}
                    onClick={() => onSelectValue(value)}
                    type="button"
                    css={{
                      padding: isString ? 12 : 0,
                      background: 'transparent',
                      width: '100%',
                      border: 0,
                      cursor: 'pointer',
                      ':hover, :focus': {
                        backgroundColor: 'var(--color-primary-lightest)',
                        color: 'white',
                      },
                      textAlign: 'start',
                    }}
                  >
                    {label}
                  </button>
                )
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default Dropdown
