/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useEffect, useRef, useState } from 'react'
import { css, jsx } from '@emotion/react'
import getWeekdays from '@monorepo/shared/getWeekdays.js'
import { useTranslation } from '@multilocale/react/index.js'
import IconAdd from '@stiloso/icons/IconAdd.js'
import IconClose from '@stiloso/icons/IconClose.js'
import clickable from '@stiloso/styles/clickable.js'

const input = css({
  boxShadow: 'inset 0px 0px 0px 1px #d8e0ed',
  borderRadius: 4,
  padding: 12,
  background: 'white',
  width: 'inherit',
  minWidth: 140,
  height: 46,
  '@media (hover: hover)': {
    '&:not(:disabled):hover': {
      boxShadow: 'inset 0px 0px 0px 2px var(--color-primary-lighter)',
      // TODO works but can't be passed directly a color
      // '&::-webkit-calendar-picker-indicator': {
      //   filter:
      //     'invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%)',
      // },
    },
  },
  '&:not(:disabled):focus-visible': {
    boxShadow: 'inset 0px 0px 0px 2px var(--color-primary-lightest)',
    cursor: 'text',
  },
  '&:not(:disabled):focus-visible-within': {
    boxShadow: 'inset 0px 0px 0px 2px var(--color-primary-lightest)',
    cursor: 'text',
  },
})

const DropdownWeekdays = ({ firstDayOfTheWeek, last, onSelect, name }) => {
  const wrapperRef = useRef(null)
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const weekdays = getWeekdays(firstDayOfTheWeek)

  const toggle = () => setVisible(!visible)

  const select = value => {
    onSelect(value)
    setVisible(false)
  }

  let button = css([
    clickable,
    {
      padding: 12,
      background: 'transparent',
      width: '100%',
      border: 0,
      color: 'var(--color-base)',
      cursor: 'pointer',
      ':hover, :focus': {
        backgroundColor: 'var(--color-primary-lightest)',
        color: 'white',
      },
      textAlign: 'start',
    },
  ])

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setVisible(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div
      ref={wrapperRef}
      css={{
        position: 'relative',
        display: 'inline',
      }}
    >
      <button
        type="button"
        onClick={toggle}
        css={[
          clickable,
          {
            border: 0,
            background: 'transparent',
            boxShadow: 'inset 0px 0px 0px 1px #d8e0ed',
            borderRadius: 4,
            padding: 12,
            color: last ? 'var(--color-placeholder)' : 'var(--color-base)',
            marginInlineEnd: 8,
            minWidth: 120,
            textAlign: 'start',
          },
        ]}
      >
        {t(name)}
      </button>
      {visible && (
        <div
          css={{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            background: 'white',
            top: '100%',
            insetInlineStart: 0,
            width: 'auto',
            zIndex: 99999,
            overflowY: 'auto',
            boxShadow: 'inset 0px 0px 0px 1px #D8E0ED',
            borderTop: 0,
            borderRadius: 4,
          }}
        >
          {weekdays.map(day => (
            <button
              key={day}
              type="button"
              onClick={() => select(day)}
              css={button}
            >
              {t(day)}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

const OpeningHour = ({
  firstDayOfTheWeek,
  index,
  day,
  open,
  close,
  last,
  onChange,
  onRemove,
}) => {
  // console.log('OpeningHour', { index, day, open, close })
  let { t } = useTranslation()
  day ||= 'Monday'

  const changeDay = day => onChange({ index, day, open, close })

  const changeOpen = event =>
    onChange({ index, day, open: event.target.value, close })

  const changeClose = event =>
    onChange({ index, day, open, close: event.target.value })

  let color = last ? 'var(--color-placeholder)' : 'var(--color-base)'

  return (
    <div
      css={{
        marginTop: 16,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color,
        fill: color,
      }}
    >
      {last ? (
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '48px',
            paddingRight: 8,
          }}
        >
          <IconAdd />
        </div>
      ) : (
        <div
          css={[
            clickable,
            {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '48px',
              paddingRight: 8,
            },
          ]}
          onClick={() => onRemove(index)}
        >
          <IconClose />
        </div>
      )}
      <DropdownWeekdays
        firstDayOfTheWeek={firstDayOfTheWeek}
        name={day}
        onSelect={changeDay}
        last={last}
      />
      <div css={{ display: 'flex', flexWrap: 'wrap', gap: 10 }}>
        <div
          css={{
            position: 'relative',
            color: !open && !last ? 'red' : 'inherit',
          }}
        >
          <div
            css={{
              fontSize: 12,
              background: 'white',
              position: 'absolute',
              top: '-7px',
              insetInlineStart: '8px',
              padding: '0 4px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textTransform: 'lowercase',
            }}
          >
            {t('opens')}
          </div>
          <input
            type="time"
            value={open}
            placeholder="00:00"
            css={input}
            onChange={changeOpen}
          />
        </div>
        <div
          css={{
            position: 'relative',
            color: !close && !last ? 'red' : 'inherit',
          }}
        >
          <div
            css={{
              fontSize: 12,
              background: 'white',
              position: 'absolute',
              top: '-7px',
              insetInlineStart: '8px',
              padding: '0 4px',
              textTransform: 'lowercase',
            }}
          >
            {t('closes')}
          </div>
          <input
            type="time"
            value={close}
            placeholder="00:00"
            css={input}
            onChange={changeClose}
          />
        </div>
      </div>
    </div>
  )
}

const OpeningHours = ({ firstDayOfTheWeek, openingHours, onChange }) => {
  let weekdays = getWeekdays(firstDayOfTheWeek)
  let daysOrders = weekdays.reduce((acc, day, index) => {
    acc[day] = index + 1
    return acc
  }, {})

  const changeOpeningHour = ({ index, day, open, close }) => {
    let newOpeningHours = [...openingHours]
    if (index < newOpeningHours.length) {
      newOpeningHours[index] = { day, open, close }
    } else {
      newOpeningHours.push({ day, open, close })
    }

    newOpeningHours = newOpeningHours.map(openingHour => {
      if (!openingHour.open) {
        openingHour.open = open
      }

      if (!openingHour.close) {
        openingHour.close = close
      }

      return openingHour
    })

    newOpeningHours = newOpeningHours.sort((a, b) => {
      let result = daysOrders[a.day] - daysOrders[b.day]

      if (result === 0) {
        result = a.open.localeCompare(b.open)
      }
      return result
    })

    onChange(newOpeningHours)
  }

  const removeOpeningHour = index => {
    let newOpeningHours = [...openingHours]

    if (index >= 0 && index < newOpeningHours.length) {
      newOpeningHours.splice(index, 1)
    }
    onChange(newOpeningHours)
  }

  let lastOpeningHour = openingHours[openingHours.length - 1]
  let nextDay = lastOpeningHour?.day
  if (lastOpeningHour) {
    let { day, open, close } = lastOpeningHour
    open = parseInt(lastOpeningHour.open?.replace(':', '') ?? '0000', 10)
    close = parseInt(lastOpeningHour.close?.replace(':', '') ?? '0000', 10)
    if (open >= 1600 || close >= 1600) {
      let dayIndex = weekdays.indexOf(day)

      nextDay = weekdays[(dayIndex + 1) % weekdays.length]
    }
  }

  let openingHoursPlusNew = [
    ...openingHours,
    { day: nextDay, open: '', close: '' },
  ]

  return (
    <div>
      {openingHoursPlusNew?.map(({ day, open, close }, index) => (
        <OpeningHour
          key={index} // eslint-disable-line react/no-array-index-key
          firstDayOfTheWeek={firstDayOfTheWeek}
          index={index}
          day={day}
          open={open}
          close={close}
          onChange={changeOpeningHour}
          onRemove={removeOpeningHour}
          last={index === openingHoursPlusNew.length - 1}
        />
      ))}
    </div>
  )
}

export default OpeningHours
