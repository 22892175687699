/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { css, jsx } from '@emotion/react'
import countries_ from '@monorepo/countries/index.js'
import { useTranslation } from '@multilocale/react/index.js'
import IconClose from '@stiloso/icons/IconClose.js'
import clickable from '@stiloso/styles/clickable.js'

const arrowDown = css`
  border-bottom: 3px solid rgba(0, 0, 0, 0);
  border-left: 6px solid rgba(0, 0, 0, 0);
  border-right: 6px solid rgba(0, 0, 0, 0);
  border-top: 6px solid #6a768a;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  margin-inline-start: 8px;
  position: 'relative';
`

const arrowUp = css`
  border-top: 3px solid rgba(0, 0, 0, 0);
  border-left: 6px solid rgba(0, 0, 0, 0);
  border-right: 6px solid rgba(0, 0, 0, 0);
  border-bottom: 6px solid var(--color-primary-lightest);
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  margin-left: 8px;
  position: 'relative';
  margin-bottom: 3px;
`

const CountryInput = ({ country, onChange, required }) => {
  required ||= false
  const wrapperRef = useRef(null)
  const { t } = useTranslation()
  const [searchQuery, setSearchQuery] = useState('')

  const countries = useMemo(
    () =>
      Object.values(countries_)
        .filter(
          ({ countryName }) =>
            countryName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            t(countryName)?.toLowerCase().includes(searchQuery.toLowerCase()),
        )
        .map(country => ({
          ...country,
          countryName: t(country.countryName),
        }))
        .sort((a, b) => a.countryName.localeCompare(b.countryName)),
    [searchQuery],
  )

  const [isVisible, setVisible] = useState(false)

  const indexCurrentValue = countries.findIndex(
    ({ countryCode }) => countryCode === country,
  )
  const currentFlag =
    countries && countries.length > indexCurrentValue
      ? countries[indexCurrentValue]?.flag
      : country

  const toggleDropdown = () => {
    setVisible(prevState => !prevState)
  }

  const onSelectCountry = newCountry => {
    onChange(newCountry || '')
    setSearchQuery('')
    toggleDropdown()
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setVisible(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  let countryName = countries_[country]?.countryName ?? ''

  return (
    <div css={{ paddingTop: 16 }}>
      <div ref={wrapperRef} css={{ position: 'relative', width: '100%' }}>
        <label
          htmlFor="input-country"
          css={{
            fontSize: 12,
            background: 'white',
            position: 'absolute',
            top: '-7px',
            insetInlineStart: '8px',
            padding: '0 4px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textTransform: 'capitalize',
            visibility: country ? 'inherit' : 'hidden',
          }}
        >
          {t('country')}
        </label>
        <div
          css={[
            clickable,
            {
              width: '100%',
              boxShadow: isVisible
                ? 'inset 0px 0px 0px 2px var(--color-primary-lightest)'
                : 'inset 0px 0px 0px 1px #d8e0ed',
              borderRadius: 4,
              height: 48,
              color: isVisible ? 'var(--color-primary-lightest)' : '#6a768a',
              ':focus-within': {
                boxShadow:
                  'inset 0px 0px 0px 2px var(--color-primary-lightest)',
                '& button': {
                  boxShadow:
                    'inset 0px 0px 0px 1px var(--color-primary-lightest)',
                  border: '1px solid var(--color-primary-lightest)',
                },
              },
              textAlign: 'start',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 16,
              padding: '0 16',
              lineHeight: '48px',
            },
          ]}
          onClick={() => toggleDropdown()}
        >
          {currentFlag || '🏳️'}

          <div
            id="countryInput"
            css={{
              flexGrow: 1,
              color: countryName ? 'inherit' : 'var(--color-placeholder)',
            }}
          >
            {t(countryName || 'country')}
          </div>
          <span css={isVisible ? arrowUp : arrowDown} />
        </div>
        {isVisible && (
          <div
            css={{
              position: 'absolute',
              top: '100%',
              left: 0,
              width: '100%',
              zIndex: 99999,
              overflowY: 'auto',
              backgroundColor: 'var(--color-background)',
              boxShadow: 'inset 0px 0px 0px 1px #D8E0ED',
              borderTop: 0,
              borderRadius: 4,
            }}
          >
            <div css={{ display: 'flex', width: '100%' }}>
              <input
                css={{ padding: 12, flexGrow: 1 }}
                placeholder={`🔍 ${t('Search country')}`}
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
                autoFocus
              />
              {searchQuery && (
                <div
                  css={[clickable, { width: 48, height: 48 }]}
                  onClick={() => setSearchQuery('')}
                >
                  <IconClose css={{ margin: 12 }} />
                </div>
              )}
            </div>
            {countries?.map(({ countryName, flag, countryCode }) => (
              <button
                key={countryName}
                onClick={() => onSelectCountry(countryCode)}
                type="button"
                css={{
                  padding: 12,
                  background: 'transparent',
                  width: '100%',
                  border: 0,
                  display: 'flex',
                  gap: 4,
                  cursor: 'pointer',
                  ':hover, :focus': {
                    backgroundColor: 'var(--color-primary-lightest)',
                    color: 'white',
                  },
                  textAlign: 'start',
                }}
              >
                <span>{flag || ''}</span>
                <span>{t(countryName)}</span>
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default CountryInput
