/* Copyright 2013 - 2024 Waiterio LLC */
import React, { useEffect, useState } from 'react'
import { useContext } from '@monorepo/context/index.js'
import subscribesPusher from '@monorepo/services/subscribesPusher.js'
import fetchAllData from '@monorepo/tractor/fetchAllData.js'
import rehydrateSession from '@monorepo/session/rehydrateSession.js'
import spinner from '@stiloso/styles/spinner.js'

let lastOnlineState = null
let alreadyIgnoredJustLoggedIn = null

const SessionRehydrator = ({ children, classes, identify, pusherAppKey }) => {
  const [rehydrated, setRehydrated] = useState()

  let [context] = useContext()
  const online = context.connection.online
  const pusherStates = context.connection.pusherStates
  const justLoggedIn = context.session.justLoggedIn

  if (pusherStates) {
    if (
      pusherStates &&
      pusherStates.current === 'connected' &&
      (!lastOnlineState || pusherStates.previous_previous) &&
      (!justLoggedIn || alreadyIgnoredJustLoggedIn)
    ) {
      // console.log(`SessionRehydrator causing fetchAllData online=${online} pusherStates=${JSON.stringify(pusherStates)}`)
      fetchAllData(classes)
    }

    lastOnlineState = online
    alreadyIgnoredJustLoggedIn = justLoggedIn
  }

  useEffect(() => {
    subscribesPusher({ classes, pusherAppKey })
    rehydrateSession(classes)
    identify?.()
    setRehydrated(true)
  }, [])

  return rehydrated ? children : <div css={spinner} />
}

export default SessionRehydrator
