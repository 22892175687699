/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  flag: '🇭🇰',
  countryName: 'Hong Kong',
  phonePrefix: '+852',
  administrativeDivisionsNames: ['九龍', '香港島', '新界'],
  administrativeDivisionsType: 'area',
  countryCode: 'HK',
  languages: ['zh-Hant', 'en'],
}
