/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */

/* eslint-disable max-len */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { jsPDF } from 'jspdf'
import IS_DEVELOPMENT from '@monorepo/env/IS_DEVELOPMENT.js'
import { useTranslation } from '@multilocale/react/index.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import IconPrint from '@stiloso/icons/IconPrint.js'
import card from '@stiloso/styles/card.js'
import clickable from '@stiloso/styles/clickable.js'
import input from '@stiloso/styles/input.js'

const anamnesisList = [
  {
    name: 'undergoing medical treatment',
    field: 'undergoingMedicalTreatment',
    type: 'string',
    question: 'Are you undergoing medical treatment?',
  },
  {
    name: 'medications',
    field: 'medications',
    type: 'string',
    question: 'Are you taking any medications?',
  },
  {
    name: 'hypersensitivity certain medications',
    field: 'hypersensitivityCertainMedications',
    type: 'string',
    question: 'Do you have hypersensitivity to certain medications?',
  },
  {
    name: 'allergies',
    field: 'allergies',
    type: 'string',
    question: 'Do you have any allergies?',
  },
  {
    name: 'problems local anesthesia',
    field: 'problemsLocalAnesthesia',
    type: 'string',
    question: 'Have you ever had any problems with local anesthesia?',
  },
  {
    name: 'consequences anesthesia or analgesics',
    field: 'consequencesAnesthesiaOrAnalgesics',
    type: 'string',
    question:
      'Did you experience any special consequences from anesthesia or analgesics?',
  },
  {
    name: 'cardiac conditions',
    field: 'cardiacConditions',
    type: 'string',
    question: 'Do you have any cardiac conditions?',
  },
  {
    name: 'bleeding disorders',
    field: 'bleedingDisorders',
    type: 'string',
    question: 'Do you have any bleeding disorders?',
  },
  {
    name: 'blood pressure changes',
    field: 'bloodPressureChanges',
    type: 'string',
    question: 'Do you have any changes in blood pressure?',
  },
  {
    name: 'diabetes',
    field: 'diabetes',
    type: 'boolean',
    question: 'Do you have diabetes?',
  },
  {
    name: 'pregnant',
    field: 'pregnant',
    type: 'boolean',
    question: 'Are you pregnant?',
  },

  {
    name: 'hepatitis',
    field: 'hepatitis',
    question: 'Do you have hepatitis?',
  },
  {
    name: 'HIV or STDs',
    field: 'hivOrStds',
    type: 'string',
    question: 'Do you have HIV or any STDs?',
  },
  {
    name: 'liver diseases',
    field: 'liverDiseases',
    type: 'string',
    question: 'Do you have any liver diseases?',
  },
  {
    name: 'kidney diseases',
    field: 'kidneyDiseases',
    type: 'string',
    question: 'Do you have any kidney diseases?',
  },
  {
    name: 'respiratory diseases',
    field: 'respiratoryDiseases',
    type: 'string',
    question: 'Do you have any respiratory diseases?',
  },
  {
    name: 'psychiatric illnesses',
    field: 'psychiatricIllnesses',
    type: 'string',
    question: 'Do you have any psychiatric illnesses?',
  },
  {
    name: 'nervous system diseases',
    field: 'nervousSystemDiseases',
    type: 'string',
    question: 'Do you have any nervous system diseases?',
  },
  {
    name: 'ulcers',
    field: 'ulcers',
    type: 'boolean',
    question: 'Do you have gastric or duodenal ulcers?',
  },
  {
    name: 'health conditions',
    field: 'healthConditions',
    type: 'string',
    question: 'Do you have any other health conditions?',
  },
  {
    name: 'disability',
    field: 'disability',
    type: 'string',
    question: 'Do you have any type of disability?',
  },
  {
    name: 'current doctor name',
    field: 'currentDoctorName',
    type: 'string',
    question: 'Name of your current doctor',
  },
  {
    name: 'current doctor phone',
    field: 'currentDoctorPhone',
    type: 'phone',
    question: 'Phone of your current doctor',
  },
  {
    name: 'previous dentist name',
    field: 'previousDentistName',
    type: 'string',
    question: 'Name of your previous dentist',
  },
  {
    name: 'previous dentist phone',
    field: 'previousDentistPhone',
    type: 'phone',
    question: 'Phone of your previous dentist',
  },
]

const StringField = ({ name, field, onChange, type, parameters }) => {
  let { t } = useTranslation()
  parameters ||= {}
  let { value } = parameters
  let onToggle = () => {
    if (value === undefined || value === null) {
      value = false
    } else {
      value = !value
    }

    let newParameters = {
      ...parameters,
      value,
      lastEditTime: new Date().getTime(),
    }
    onChange(field, newParameters)
  }
  let changeField = (field, value) => {
    let newParameters = {
      ...parameters,
      value,
      lastEditTime: new Date().getTime(),
    }
    onChange(field, newParameters)
  }
  let string
  if ((type === 'string' || type === 'phone') && value === true) {
    string = ''
  } else if (type === 'string' || type === 'phone') {
    string = value
  }

  let color =
    value === undefined || value === null
      ? 'let(--color-placeholder)'
      : 'let(--color-base)'

  return (
    <div css={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <div
        key={field}
        css={[
          clickable,
          {
            padding: '0 16px',
            display: 'flex',
            width: '100%',
            height: 48,
            alignItems: 'center',
            cursor: 'pointer',
            color,
          },
        ]}
        onClick={onToggle}
      >
        <div
          css={{
            flexGrow: 1,
          }}
        >
          {t(name) || t(field)}
        </div>
        {(value === undefined || value === null) && '?'}
        {value === false && (
          <span css={{ textTransform: 'lowercase' }}>{t('No')}</span>
        )}
        {value && <span css={{ textTransform: 'uppercase' }}>{t('Yes')}</span>}
      </div>
      {value && (type === 'string' || type === 'phone') && (
        <div css={{ padding: '0 16' }}>
          <input
            css={[input, { height: 48 }]}
            value={string}
            onChange={e => changeField(field, e.target.value)}
            placeholder={t(name)}
          />
        </div>
      )}
    </div>
  )
}

const AnamnesisSection = ({ anamnesis, setAnamnesis }) => {
  anamnesis ||= {}
  const { t } = useTranslation()

  const onChange = (field, value) => {
    setAnamnesis({ ...anamnesis, [field]: value })
  }

  const printForm = () => {
    // Default export is a4 paper, portrait, using millimeters for units
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF()
    let rem = 16
    pdf.text(`${t('Anamnesi')}`, rem, rem)
    anamnesisList.forEach(({ question }, i) => {
      pdf.text(`${t(question)}`, rem, rem * (i + 3))
      pdf.text(`${t('No')} ( ) ${t('Yes')} ( )`, rem + 120, rem * (i + 3))
    })

    pdf.save(`${t('Anamnesis')}.pdf`)
  }

  return (
    <>
      <AppBar>
        <AppBarTitle>{t('Anamnesis')}</AppBarTitle>
        <div css={{ flexGrow: 1 }} />
        {IS_DEVELOPMENT && (
          <button
            type="button"
            css={[
              clickable,
              {
                height: 48,
                background: 'white',
                color: 'let(--color-base)',
                fill: 'let(--color-base)',
                fontSize: 16,
                fontWeight: 'bold',
                border: 0,
                marginLeft: 16,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0 16px',
                borderRadius: 4,
                '@media print': {
                  display: 'none',
                },
              },
            ]}
            onClick={printForm}
          >
            <IconPrint css={{ height: 22, marginInlineEnd: 12 }} />
            {t('Print')}
          </button>
        )}
      </AppBar>
      {anamnesisList.length > 0 && (
        <div
          css={[
            card,
            {
              padding: 0,
              marginBottom: 16,
              display: 'flex',
              flexWrap: 'wrap',
            },
          ]}
        >
          {anamnesisList?.map(props => (
            <StringField
              key={props.field}
              {...props}
              parameters={anamnesis[props.field]}
              onChange={onChange}
            />
          ))}
        </div>
      )}
    </>
  )
}

export default AnamnesisSection
