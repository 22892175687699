/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  flag: '🇦🇩',
  countryName: 'Andorra',
  phonePrefix: '+376',
  administrativeDivisionsNames: [
    'Andorra la Vella',
    'Canillo',
    'Encamp',
    'Escaldes-Engordany',
    'La Massana',
    'Ordino',
    'Sant Julià de Lòria',
  ],
  administrativeDivisionsType: 'parish',
  countryCode: 'AD',
  languages: ['ca'],
  zipcodePattern: 'AD[1-7]0\\d',
}
