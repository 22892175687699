/* Copyright 2013 - 2024 Waiterio LLC */
import uuid from '@monorepo/shared/uuid.js'

function Organization(source) {
  if (source instanceof Organization) {
    return source
  } else {
    Object.keys(source || {}).forEach(key => {
      this[key] = source[key]
    })

    if (!this._id) {
      this._id = uuid()
    }

    if (!this.creationTime) {
      this.creationTime = new Date().toISOString()
    }

    if (!this.lastEditTime) {
      this.lastEditTime = this.creationTime
    }

    return this
  }
}

Organization.prototype.constructor = Organization

Organization.name_ = 'Organization'
Organization.collection = Organization.name_.toLowerCase() + 's'

export default Organization
