/* Copyright 2013 - 2024 Waiterio LLC */
import {
  addDays,
  addHours,
  addMinutes,
  addWeeks,
  addMonths,
  formatISO,
  setHours,
  setMinutes,
} from 'date-fns'

const getReminderScheduledTime = (reminder, instance) => {
  let scheduledTime

  if (instance?.[reminder.event]) {
    let date = new Date(instance?.[reminder.event])
    if (
      reminder.timeRelation === 'after' ||
      reminder.timeRelation === 'before'
    ) {
      let operator = reminder.timeRelation === 'after' ? 1 : -1

      if (reminder.timeUnit === 'minutes') {
        date = addMinutes(date, operator * reminder.timeAmount)
      } else if (reminder.timeUnit === 'hours') {
        date = addHours(date, operator * reminder.timeAmount)
      } else if (reminder.timeUnit === 'days') {
        date = addDays(date, operator * reminder.timeAmount)
      } else if (reminder.timeUnit === 'weeks') {
        date = addWeeks(date, operator * reminder.timeAmount)
      } else if (reminder.timeUnit === 'months') {
        date = addMonths(date, operator * reminder.timeAmount)
      }
    } else if (reminder.timeRelation === 'previous day') {
      date = addDays(date, -1)
      let [hours, minutes] = reminder.time.split(':')
      date = setHours(date, parseInt(hours, 10))
      date = setMinutes(date, parseInt(minutes, 10))
    } else if (reminder.timeRelation === 'same day') {
      let [hours, minutes] = reminder.time.split(':')
      date = setHours(date, parseInt(hours, 10))
      date = setMinutes(date, parseInt(minutes, 10))
    } else if (reminder.timeRelation === 'next day') {
      date = addDays(date, 1)
      let [hours, minutes] = reminder.time.split(':')
      date = setHours(date, parseInt(hours, 10))
      date = setMinutes(date, parseInt(minutes, 10))
    }
    scheduledTime = formatISO(date)
  }

  return scheduledTime
}

export default getReminderScheduledTime
