/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
// import { useNavigate } from 'react-router-dom'
import { useContext } from '@monorepo/context/index.js'
import { useTranslation } from '@multilocale/react/index.js'
import card from '@stiloso/styles/card.js'
// import clickable from '@stiloso/styles/clickable.js'
import singleLine from '@stiloso/styles/singleLine.js'

// TODO ${clickable}; with onClick
const noInternetConnection = css`
  ${card};
  ${singleLine};
  border-radius: 0;
  line-height: 35px;
  height: 35px;
  min-height: 35px;
  text-align: center;
  color: white;
  background: #626262;
  padding: 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const NoInternetConnection = ({ online }) => {
  const { t } = useTranslation()
  // const navigate = useNavigate()

  if (!online) {
    return (
      <div
        // onClick={_ => {
        //   document.location.reload()
        //   // TODO navigate('/documentation/can-i-use-it-offline')
        // }}
        css={noInternetConnection}
      >
        {t('No Internet connection')}
      </div>
    )
  } else {
    return null
  }
}

const NoInternetConnectionWithContext = () => {
  let [context] = useContext()
  const online = context?.connection?.online
  return <NoInternetConnection online={online} />
}

export default NoInternetConnectionWithContext
