/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { Suspense } from 'react'
import { css, jsx } from '@emotion/react'
import { Link, useParams } from 'react-router-dom'
import useLoggedInSession from '@monorepo/session/useLoggedInSession.js'
import { useTranslation } from '@multilocale/react/index.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarButton from '@stiloso/components/AppBarButton.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import ErrorBoundary from '@stiloso/components/ErrorBoundary.js'
import Spinner from '@stiloso/components/Spinner.js'
import card from '@stiloso/styles/card.js'
import clickable from '@stiloso/styles/clickable.js'
import section from '@stiloso/styles/section.js'
import Layout from '../components/Layout.js'
import useClinic from '../hooks/useClinic.js'
import useProcedures from '../hooks/useProcedures.js'

let procedureCard = css([
  clickable,
  card,
  {
    lineHeight: '48px',
    width: '100%',
    marginTop: 16,
    padding: '0 16',
    display: 'flex',
    flexDirection: 'row',
  },
])

export const paths = ['/clinics/:clinicId/procedures']

const ProceduresPage = props => {
  let { clinic, procedures } = props
  useLoggedInSession()
  return (
    <div
      css={{
        margin: '16 0',
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      {procedures?.map(({ _id, name, price }) => (
        <Link
          key={name}
          css={procedureCard}
          to={`/clinics/${clinic._id}/procedures/${_id}`}
        >
          <div css={{ flexGrow: 1 }}>{name}</div>
          <div>{price}</div>
        </Link>
      ))}
    </div>
  )
}

const ProceduresPageConnected = () => {
  useLoggedInSession()
  const { clinicId } = useParams()
  let clinic = useClinic(clinicId)

  let procedures = useProcedures({
    clinicId: clinic._id,
  })
    .filter(_ => _)
    .sort((a, b) => a.name?.localeCompare(b.name))

  return <ProceduresPage clinic={clinic} procedures={procedures} />
}

const ProceduresPageWrapper = () => {
  const { t } = useTranslation()
  const { clinicId } = useParams()
  return (
    <Layout title={t('Procedures')}>
      <div css={section}>
        <AppBar>
          <AppBarTitle>{t('Procedures')}</AppBarTitle>
          <div css={{ flexGrow: 1 }} />
          <AppBarButton
            label={t('New procedure')}
            to={`/clinics/${clinicId}/procedures/new`}
          />
        </AppBar>
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <ProceduresPageConnected />
          </Suspense>
        </ErrorBoundary>
      </div>
    </Layout>
  )
}

export default ProceduresPageWrapper
