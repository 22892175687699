/* Copyright 2013 - 2024 Waiterio LLC */
import uuid from '@monorepo/shared/uuid.js'

function Patient(source) {
  if (source instanceof Patient) {
    return source
  } else {
    Object.keys(source || {}).forEach(key => {
      this[key] = source[key]
    })

    if (!this._id) {
      this._id = uuid()
    }

    if (!this.creationTime) {
      this.creationTime = new Date().toISOString()
    }

    if (!this.lastEditTime) {
      this.lastEditTime = this.creationTime
    }

    if (!this.anamnesis) {
      this.anamnesis = {}
    }

    if (!this.teeth) {
      this.teeth = {}
    }

    return this
  }
}

Patient.prototype.constructor = Patient

Patient.name_ = 'Patient'
Patient.collection = Patient.name_.toLowerCase() + 's'

export default Patient
