/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  flag: '🇸🇻',
  countryName: 'El Salvador',
  phonePrefix: '+503',
  administrativeDivisionsNames: [
    'Ahuachapán',
    'Cabañas',
    'Chalatenango',
    'Cuscatlán',
    'La Libertad',
    'La Paz',
    'La Unión',
    'Morazán',
    'San Miguel',
    'San Salvador',
    'San Vicente',
    'Santa Ana',
    'Sonsonate',
    'Usulután',
  ],
  administrativeDivisionsType: 'department',
  countryCode: 'SV',
  languages: ['es'],
  zipcodePattern: 'CP [1-3][1-7][0-2]\\d',
}
