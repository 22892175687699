/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { Suspense, useEffect, useState } from 'react'
import { css, jsx } from '@emotion/react'
import {
  unstable_useBlocker as useBlocker,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import {
  format,
  formatISO,
  getDayOfYear,
  getMonth,
  getYear,
  parse,
  parseISO,
  setDayOfYear,
  setHours,
  setMinutes,
  setMonth,
  setYear,
  startOfToday,
} from 'date-fns'
import { pipe } from 'ramda'
import useLoggedInSession from '@monorepo/session/useLoggedInSession.js'
import { toastError } from '@monorepo/shared/AndroidToast.js'
import uuid from '@monorepo/shared/uuid.js'
import addOrUpdate from '@monorepo/tractor/addOrUpdate.js'
import remove from '@monorepo/tractor/remove.js'
import Payment from '@dododentist/model/Payment.js'
import { useTranslation } from '@multilocale/react/index.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarButton from '@stiloso/components/AppBarButton.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import ButtonBar from '@stiloso/components/ButtonBar.js'
import ErrorBoundary from '@stiloso/components/ErrorBoundary.js'
import Input from '@stiloso/components/Input.js'
import Spinner from '@stiloso/components/Spinner.js'
import IconDelete from '@stiloso/icons/IconDelete.js'
import colMd6 from '@stiloso/styles/bootstrap/colMd6.js'
import colSm12 from '@stiloso/styles/bootstrap/colSm12.js'
import container from '@stiloso/styles/bootstrap/container.js'
import dLgBlock from '@stiloso/styles/bootstrap/dLgBlock.js'
import dNone from '@stiloso/styles/bootstrap/dNone.js'
import row from '@stiloso/styles/bootstrap/row.js'
import card from '@stiloso/styles/card.js'
import label from '@stiloso/styles/label.js'
import Layout from '../components/Layout.js'
import ProceduresSection from '../components/ProceduresSection.js'
import SaveChangesPrompt from '../dialogs/SaveChangesPrompt.js'
import useAppointments from '../hooks/useAppointments.js'
import useClinic from '../hooks/useClinic.js'
import usePatient from '../hooks/usePatient.js'
import usePayment from '../hooks/usePayment.js'

export const paths = ['/clinics/:clinicId/payments/:paymentId']

const dateInputFormat = 'yyyy-MM-dd'

const PaymentPage = props => {
  let { action, appointments, clinic, payment, patient } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  let queryDateString = searchParams.get('date')

  let startOfDay = startOfToday()
  // console.log('1', startOfDay)
  try {
    if (queryDateString) {
      let queryDate = parse(queryDateString, dateInputFormat, new Date())
      startOfDay = pipe(
        date => setYear(date, getYear(queryDate)),
        date => setMonth(date, getMonth(queryDate)),
        date => setDayOfYear(date, getDayOfYear(queryDate)),
      )(startOfDay)
    }
  } catch (error) {
    // DO NOTHING ON PURPOSE
  }
  // console.log('2', startOfDay)

  let startTimeDefault = pipe(date => setHours(date, 10), formatISO)(startOfDay)
  // console.log(`${startTimeDefault}`)
  let endTimeDefault = pipe(
    date => setHours(date, 10),
    date => setMinutes(date, 30),
    formatISO,
  )(startOfDay)
  // console.log(`${endTimeDefault}`)

  const [edited, setEdited] = useState(false)
  const [isLoading, setLoading] = useState(false)
  let [_id] = useState(payment?._id || uuid())
  const [startTime, setStartTime] = useState(
    payment?.startTime || startTimeDefault,
  )
  const [endTime, setEndTime] = useState(payment?.endTime || endTimeDefault)
  let [note, setNote] = useState(payment?.note || '')
  let [procedures, setProcedures] = useState(payment?.procedures || [])
  let dateInputValue = format(parseISO(startTime), dateInputFormat)
  // console.log({ dateInputValue })

  const changeProcedures = procedures => {
    setEdited(true)
    setProcedures(procedures)
  }

  const changeDate = event => {
    let value = event.target.value
    console.log({ value })
    setEdited(true)
    // console.log('changeDate', event.target.value)
    let parsedDate = parse(value, 'yyyy-MM-dd', new Date())

    try {
      let newStartTime = pipe(
        parseISO,
        // date => {
        //   console.log(date)
        //   return date
        // },
        date => setYear(date, getYear(parsedDate)),
        date => setMonth(date, getMonth(parsedDate)),
        date => setDayOfYear(date, getDayOfYear(parsedDate)),
        formatISO,
      )(startTime)
      // console.log(newStartTime)
      setStartTime(newStartTime)
    } catch (error) {
      // DO NOTHING ON PURPOSE
    }

    try {
      let newEndTime = pipe(
        parseISO,
        date => setYear(date, getYear(parsedDate)),
        date => setMonth(date, getMonth(parsedDate)),
        date => setDayOfYear(date, getDayOfYear(parsedDate)),
        formatISO,
      )(endTime)
      // console.log(newEndTime)
      setEndTime(newEndTime)
    } catch (error) {
      // DO NOTHING ON PURPOSE
    }
  }

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      edited && currentLocation.pathname !== nextLocation.pathname,
  )

  useEffect(() => {
    if (!edited) {
      blocker?.proceed?.()
    }
  }, [edited])

  const addOrUpdatePayment = async event => {
    event?.preventDefault?.()

    try {
      const newPayment = new Payment({
        _id,
        ...payment,
        lastEditTime: new Date().toISOString(),
        organizationId: clinic.organizationId,
        clinicId: clinic._id,
        patientId: patient._id,
        startTime,
        endTime,
        note,
        procedures,
      })

      // console.log(payment)

      setLoading(true)
      await addOrUpdate(newPayment, payment)

      setLoading(false)
      setEdited(false)
      navigate(`/clinics/${clinic._id}/patients/${patient._id}`)
    } catch (error) {
      setLoading(false)
      setEdited(true)
      toastError(t(error))
    }
  }

  let closePrompt = () => {
    blocker.reset()
  }

  let discardChangesPrompt = () => {
    blocker.proceed()
  }

  let deletePayment = async event => {
    event?.preventDefault?.()

    try {
      if (payment) {
        await remove(payment)
      }

      setEdited(false)
      navigate(`/clinics/${clinic._id}/patients/${patient._id}`)
    } catch (error) {
      toastError(t(error))
    }
  }

  let saveChangesPrompt = async () => {
    await addOrUpdatePayment()
    blocker.proceed()
  }

  useEffect(() => {
    if (payment) {
      setStartTime(payment.startTime)
      setEndTime(payment.endTime)
      setNote(payment.note)
      setProcedures(payment.procedures || [])
    } else if (appointments) {
      let procedures = appointments
        .map(({ procedures }) => procedures || [])
        .reduce((acc, val) => acc.concat(val), [])
      setProcedures(procedures)
    }
  }, [payment])

  useEffect(() => {
    if (action) {
      navigate('?action=')
    }

    if (action === 'delete') {
      deletePayment()
    }
  }, [action])

  return (
    <>
      {blocker?.state === 'blocked' && (
        <SaveChangesPrompt
          close={closePrompt}
          discardChanges={discardChangesPrompt}
          saveChanges={saveChangesPrompt}
        />
      )}
      <form onSubmit={addOrUpdatePayment}>
        <div css={container}>
          <div css={row}>
            <div css={[colSm12, colMd6]}>
              <div css={[dNone, dLgBlock]}>
                <AppBar>
                  <AppBarTitle>
                    {payment ? t('Payment') : t('New payment')}
                  </AppBarTitle>
                  <div css={{ flexGrow: 1 }} />
                  {payment && (
                    <AppBarButton
                      appearance="secondary"
                      Icon={IconDelete}
                      label={t('Delete')}
                      onClick={deletePayment}
                    />
                  )}
                </AppBar>
              </div>
              <div css={[card, { marginTop: 16 }]}>
                <Input label={t('Patient')} value={patient?.name} disabled />
                <Input
                  label={t('Date')}
                  type="date"
                  value={dateInputValue}
                  onChange={changeDate}
                />
                <label css={label}>{t('Appointments')}</label>
                {appointments?.map(({ _id, startTime }) => (
                  <div key={_id}>{format(startTime, 'yyyy-MM-dd HH:mm')}</div>
                ))}
              </div>
            </div>
            <div css={[colSm12, colMd6]}>
              <ProceduresSection
                clinicId={clinic._id}
                currency={clinic.currency}
                procedures={procedures}
                changeProcedures={changeProcedures}
              />
            </div>
          </div>
        </div>
        {(edited || !payment) && (
          <ButtonBar appearance="secondary" type="submit" isLoading={isLoading}>
            {payment ? t('Save payment') : t('Add payment')}
          </ButtonBar>
        )}
      </form>
    </>
  )
}

const PaymentPageConnected = () => {
  useLoggedInSession()
  const { clinicId, paymentId } = useParams()
  const [searchParams] = useSearchParams()
  const action = searchParams.get('action')
  const patientId = searchParams.get('patientId')
  let appointmentsIdsParams = searchParams.get('appointmentsIds') || ''
  appointmentsIdsParams = appointmentsIdsParams.split(',')
  const clinic = useClinic(clinicId)
  const payment = usePayment(paymentId)
  const appointmentsIds = [
    ...new Set([...appointmentsIdsParams, ...(payment?.appointmentsIds ?? [])]),
  ]
  let appointments = useAppointments({ patientId })
  appointments = appointments.filter(({ _id }) => appointmentsIds.includes(_id))

  const patient = usePatient(patientId)

  return (
    <PaymentPage
      action={action}
      appointments={appointments}
      clinic={clinic}
      payment={payment}
      patient={patient}
    />
  )
}

const PaymentPageWrapper = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { clinicId, paymentId } = useParams()
  const isNewPayment = paymentId === 'new'
  let title = t(isNewPayment ? 'New payment' : 'Payment')
  const onClickTitle = () => {
    navigate(`/clinics/${clinicId}/patients`)
  }
  const actions = [!isNewPayment && 'delete'].filter(_ => _)
  return (
    <Layout
      actions={actions}
      back
      main={false}
      title={title}
      onClickTitle={onClickTitle}
    >
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <PaymentPageConnected />
        </Suspense>
      </ErrorBoundary>
    </Layout>
  )
}

export default PaymentPageWrapper
