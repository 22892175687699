/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'

const AppBar = ({ children, style }) => (
  <div
    css={[
      {
        color: 'white',
        width: '100%',
        display: 'flex',
        gridArea: 'appBar',
        margin: '16 0',
        alignItems: 'center',
      },
      style,
    ]}
  >
    {children}
  </div>
)

export default AppBar
