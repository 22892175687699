/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { Suspense, useEffect, useState } from 'react'
import { jsx } from '@emotion/react'
import {
  unstable_useBlocker as useBlocker,
  useParams,
  useNavigate,
} from 'react-router-dom'
import useLoggedInSession from '@monorepo/session/useLoggedInSession.js'
import { toastError } from '@monorepo/shared/AndroidToast.js'
import { colors } from '@monorepo/shared/convertStringToColor.js'
import addOrUpdate from '@monorepo/tractor/addOrUpdate.js'
import remove from '@monorepo/tractor/remove.js'
import Role from '@dododentist/model/Role.js'
import User from '@dododentist/model/User.js'
import { useTranslation } from '@multilocale/react/index.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import ButtonBar from '@stiloso/components/ButtonBar.js'
import DeleteDialog from '@stiloso/components/DeleteDialog.js'
import Dropdown from '@stiloso/components/Dropdown.js'
import ErrorBoundary from '@stiloso/components/ErrorBoundary.js'
import ErrorDialog from '@stiloso/components/ErrorDialog.js'
import Input from '@stiloso/components/Input.js'
import Spinner from '@stiloso/components/Spinner.js'
import IconDone from '@stiloso/icons/IconDone.js'
import dLgBlock from '@stiloso/styles/bootstrap/dLgBlock.js'
import dNone from '@stiloso/styles/bootstrap/dNone.js'
import card from '@stiloso/styles/card.js'
import clickable from '@stiloso/styles/clickable.js'
import listItem from '@stiloso/styles/listItem.js'
import section from '@stiloso/styles/section.js'
import Layout from '../components/Layout.js'
import SaveChangesPrompt from '../dialogs/SaveChangesPrompt.js'
import useRoles from '../hooks/useRoles.js'
import useUser from '../hooks/useUser.js'

export const paths = ['/team/:userId']

const ROLES = ['doctor', 'nurse', 'admin', 'secretary', 'assistant', 'manager']

const RolesSection = props => {
  const { roles, userId } = props
  const navigate = useNavigate()
  const { t } = useTranslation()

  let [error, setError] = useState(null)
  let [isLoading, setLoading] = useState(false)
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] =
    useState(false)

  const rolesTypes = roles
    .filter(role => role.userId === userId)
    .map(role => role.type)

  const showDeleteConfirmationDialog = role => {
    setDeleteConfirmationDialog({ role })
  }

  const hideDeleteConfirmationDialog = () => {
    setDeleteConfirmationDialog(false)
  }

  const addRole = async roleType => {
    await addOrUpdate(new Role({ userId, type: roleType }))
  }

  const deleteRole = async roleType => {
    const role = roles.find(
      role => role.userId === userId && role.type === roleType,
    )
    if (rolesTypes.length === 1) {
      showDeleteConfirmationDialog(role)
    } else {
      await remove(role)
    }
  }

  const removeTeamMember = async () => {
    const { role } = deleteConfirmationDialog
    setLoading(true)
    await remove(new Role(role))
      .then(() => {
        setLoading(false)
        hideDeleteConfirmationDialog()
        navigate('/team')
      })
      .catch(error => {
        setLoading(false)
        setError(error)
        toastError(error)
      })
  }

  return (
    <>
      {deleteConfirmationDialog && (
        <DeleteDialog
          title={t('Remove team member')}
          message={t('Do you want to remove this team member?')}
          onDelete={removeTeamMember}
          close={hideDeleteConfirmationDialog}
          loading={isLoading}
        />
      )}
      <AppBar>
        <AppBarTitle>{t('Roles')}</AppBarTitle>
      </AppBar>
      <div
        css={[card, { display: 'flex', flexDirection: 'column', padding: 0 }]}
      >
        {ROLES.map(role => (
          <div key={role} css={listItem}>
            {rolesTypes.includes(role) ? (
              <div
                css={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                onClick={() => deleteRole(role)}
              >
                <div>{t(role)}</div>
                <IconDone />
              </div>
            ) : (
              <div onClick={() => addRole(role)}>{t(role)}</div>
            )}
          </div>
        ))}
      </div>
      {error && <ErrorDialog error={error} close={setError(null)} />}
    </>
  )
}

const TeamMemberPage = props => {
  let { roles, user } = props
  const { t } = useTranslation()

  const [edited, setEdited] = useState(false)
  let [color, setColor] = useState(colors[0])
  let [isLoading, setLoading] = useState(false)

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      edited && currentLocation.pathname !== nextLocation.pathname,
  )

  useEffect(() => {
    if (!edited) {
      blocker?.proceed?.()
    }
  }, [edited])

  const changeColor = color => {
    setEdited(true)
    setColor(color)
  }

  const changeUser = async event => {
    event?.preventDefault?.()

    // console.log('changeUser', avatarUrl)

    const newUser = new User({
      ...user,
      color,
      lastEditTime: new Date().toISOString(),
    })

    setLoading(true)

    await addOrUpdate(newUser, user)
      .then(() => {
        setEdited(false)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        toastError(t(error))
      })
  }

  let closePrompt = () => {
    blocker.reset()
  }

  let discardChangesPrompt = () => {
    blocker.proceed()
  }

  let saveChangesPrompt = async () => {
    await changeUser()
    blocker.proceed()
  }

  useEffect(() => {
    if (user) {
      setColor(user.color)
    }
  }, [user])

  return (
    <>
      <form
        css={{ display: 'flex', flexDirection: 'column' }}
        onSubmit={changeUser}
      >
        <div css={[card, { display: 'flex', flexDirection: 'column' }]}>
          <Input label={t('email')} value={user.email} readOnly />
          <Dropdown
            name="Color"
            labels={colors.map(color => (
              <div css={[clickable, { padding: 12, display: 'flex' }]}>
                <div
                  css={{
                    background: color,
                    width: 24,
                    height: 24,
                    borderRadius: 12,
                    marginInlineEnd: 8,
                  }}
                />
                {color}
              </div>
            ))}
            values={colors}
            currentValue={color}
            onSelect={changeColor}
          />
        </div>
        <RolesSection roles={roles} userId={user?._id} />

        {edited && (
          <ButtonBar
            type="submit"
            css={{ marginTop: 16 }}
            isLoading={isLoading}
          >
            {t('Save')}
          </ButtonBar>
        )}
      </form>
      {blocker?.state === 'blocked' && (
        <SaveChangesPrompt
          close={closePrompt}
          discardChanges={discardChangesPrompt}
          saveChanges={saveChangesPrompt}
        />
      )}
    </>
  )
}

const TeamMemberPageConnected = () => {
  useLoggedInSession()
  const { userId } = useParams()
  const roles = useRoles({ userId })
  const user = useUser(userId)

  return <TeamMemberPage roles={roles} user={user} />
}

const TeamMemberPageWrapper = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const title = t('Team member')
  const onClickTitle = () => {
    navigate('/team')
  }
  return (
    <Layout back main={false} title={title} onClickTitle={onClickTitle}>
      <div css={section}>
        <div css={[dNone, dLgBlock]}>
          <AppBar>
            <AppBarTitle>{title}</AppBarTitle>
          </AppBar>
        </div>
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <TeamMemberPageConnected />
          </Suspense>
        </ErrorBoundary>
      </div>
    </Layout>
  )
}

export default TeamMemberPageWrapper
