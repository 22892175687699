/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Tab from '@stiloso/components/Tab.js'
import Tabs from '@stiloso/components/Tabs.js'
import dLgBlock from '@stiloso/styles/bootstrap/dLgBlock.js'
import dLgNone from '@stiloso/styles/bootstrap/dLgNone.js'
import dNone from '@stiloso/styles/bootstrap/dNone.js'
import clickable from '@stiloso/styles/clickable.js'

export const Holds = React.forwardRef((props, ref) => (
  <div css={{ position: 'relative' }}>
    <div
      ref={ref}
      css={{
        position: 'absolute',
        minHeight: 32,
        top: -48,
      }}
    />
  </div>
))

const SectionsBar = ({ sections }) => (
  <>
    <div css={[dNone, dLgBlock]}>
      <div
        css={{
          background: 'white',
          position: 'fixed',
          top: 0,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          heigh: 32,
          zIndex: 9900,
          borderBottom: '2px solid var(--color-background)',
          '@media (min-width: 992px)': {
            insetInlineStart: '216px',
            width: 'calc(100vw - 232px)',
            borderBottomLeftRadius: 24,
            borderBottomRightRadius: 24,
          },
        }}
      >
        {sections.map(({ name, ref }) => (
          <div
            key={name}
            css={[
              clickable,
              {
                height: 32,
                flexGrow: 1,
                overflowX: 'hidden',
                whiteSpace: 'nowrap',
              },
            ]}
            onClick={() => {
              if (ref?.current) {
                ref.current.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                })
              }
            }}
          >
            <div
              css={{
                padding: '4',
                overflowX: 'hidden',
                textOverflow: 'ellipsis',
                width: '100%',
                textAlign: 'center',
              }}
            >
              {name}
            </div>
          </div>
        ))}
      </div>
      <div css={{ minHeight: 32 }} />
    </div>
    <div css={dLgNone}>
      <div css={{ position: 'fixed', top: 64, width: '100%', zIndex: 9990 }}>
        <Tabs>
          {sections.map(({ name, ref }) => (
            <Tab
              key={name}
              onClick={() => {
                if (ref?.current) {
                  ref.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  })
                }
              }}
              totalTabs={sections.length}
            >
              {name}
            </Tab>
          ))}
        </Tabs>
      </div>
      <div css={{ minHeight: 48 }} />
    </div>
  </>
)

export default SectionsBar
