/* Copyright 2013 - 2024 Waiterio LLC */
import delete_ from './delete_.js'

export default function deleteClass(Class, documentId) {
  return delete_({
    url: `${Class.collection}/${documentId}`,
  }).catch(error => {
    let newError = new Error("Couldn't delete {document}", {
      cause: error,
    })
    newError.variables = { document: Class.name_.toLowerCase() }
    throw newError
  })
}
