/* Copyright 2013 - 2024 Waiterio LLC */
import put from './put.js'

export default function putClass(Class, document) {
  // console.log('putClass', Class, document)
  let url = `${Class.collection}/${document._id}`
  // console.log('url', url)
  return put({
    url,
    body: document,
  }).catch(error => {
    let newError = new Error("Couldn't update {document}", {
      cause: error,
    })
    newError.variables = { document: Class.name_.toLowerCase() }
    throw newError
  })
}
