/* Copyright 2013 - 2024 Waiterio LLC */

const getDaysOfTheWeek = firstDayOfTheWeek => {
  let daysOfTheWeek = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ]

  if (firstDayOfTheWeek === 'Saturday') {
    daysOfTheWeek = [
      'Saturday',
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
    ]
  } else if (firstDayOfTheWeek === 'Sunday') {
    daysOfTheWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
  }
  return daysOfTheWeek
}

export default getDaysOfTheWeek
