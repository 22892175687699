/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  flag: '🇹🇼',
  countryName: 'Taiwan',
  phonePrefix: '+886',
  administrativeDivisionsType: 'county',
  countryCode: 'TW',
  languages: ['zh-Hant'],
  zipcodePattern: '\\d{3}(?:\\d{2,3})?',
}
