/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  flag: '🇯🇵',
  countryName: 'Japan',
  phonePrefix: '+81',
  administrativeDivisionsType: 'prefecture',
  countryCode: 'JP',
  languages: ['ja'],
  zipcodePattern: '\\d{3}-?\\d{4}',
}
