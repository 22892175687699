/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { css, jsx } from '@emotion/react'
import android from '@monorepo/shared/device/android.js'
import ios from '@monorepo/shared/device/ios.js'
import macos from '@monorepo/shared/device/macos.js'
import User from '@dododentist/model/User.js'
import androidPackageName from '@dododentist/shared/androidPackageName.js'
import iOSAppStoreId from '@dododentist/shared/iOSAppStoreId.js'
import macOSAppStoreId from '@dododentist/shared/macOSAppStoreId.js'
import windowsProductId from '@dododentist/shared/windowsProductId.js'
import { useTranslation } from '@multilocale/react/index.js'
import Modal from '@stiloso/components/Modal.js'
import IconStar from '@stiloso/icons/IconStar.js'
import IconStarEmpty from '@stiloso/icons/IconStarEmpty.js'
import clickable from '@stiloso/styles/clickable.js'
import addOrUpdate from '@monorepo/tractor/addOrUpdate.js'
import useUser from '../hooks/useUser.js'
import getUserId from '@monorepo/session/getUserId.js'

const button = css`
  ${clickable};
  border: 0;
  background: var(--color-primary);
  height: 48px;
  width: 100%;
  color: white;
`

const RateAppDialog = () => {
  const { t } = useTranslation()

  let [, setShow] = useState(true)
  let [firstStar, setFirstStar] = useState(false)
  let [secondStar, setSecondStar] = useState(false)
  let [thirdStar, setThirdStar] = useState(false)
  let [fourthStar, setFourthStar] = useState(false)
  let [fifthStar, setFifthStar] = useState(false)

  const user = useUser(getUserId())

  let getWriteReviewUrl = () => {
    let writeReviewUrl = ''

    if (android) {
      writeReviewUrl = `market://details?id=${androidPackageName}`
    } else if (ios) {
      writeReviewUrl = `https://apps.apple.com/app/id${iOSAppStoreId}?action=write-review`
    } else if (macos) {
      writeReviewUrl = `https://apps.apple.com/app/id${macOSAppStoreId}?action=write-review`
    } else {
      writeReviewUrl = `ms-windows-store://review/?ProductId=${windowsProductId}`
    } // else {
    //   TODO get capterra link for non native apps
    //   let capterraUrl = 'https://reviews.capterra.com/new/CAPTERRA_VENDOR_ID_HERE'

    //   if (user.language) {
    //     capterraUrl += `?lang=${user.language}`
    //   }

    //   writeReviewUrl = capterraUrl
    // }

    return writeReviewUrl
  }

  const hideRateAppDialog = () => {
    setShow(false)
  }

  const showRateAppDialog = () => {
    setShow(true)
  }

  useEffect(() => {
    const updateUser = async () => {
      showRateAppDialog()

      let newUser = new User({
        ...user,
        rateAppDialogShowLastTime: new Date().toISOString(),
      })

      return addOrUpdate(newUser, user)
    }

    updateUser()
  }, [])

  const onClickFirstStar = () => {
    setFirstStar(true)
    setSecondStar(false)
    setThirdStar(false)
    setFourthStar(false)
    setFifthStar(false)
  }

  const onClickSecondStar = () => {
    setFirstStar(true)
    setSecondStar(true)
    setThirdStar(false)
    setFourthStar(false)
    setFifthStar(false)
  }

  const onClickThirdStar = () => {
    setFirstStar(true)
    setSecondStar(true)
    setThirdStar(true)
    setFourthStar(false)
    setFifthStar(false)
  }

  const onClickFourthStar = () => {
    setFirstStar(true)
    setSecondStar(true)
    setThirdStar(true)
    setFourthStar(true)
    setFifthStar(false)
  }

  const onClickFifthStar = () => {
    setFirstStar(true)
    setSecondStar(true)
    setThirdStar(true)
    setFourthStar(true)
    setFifthStar(true)
  }

  const onClickRateApp = event => {
    event.preventDefault()
    let newUser = new User({
      ...user,
      ratedApp: true,
    })

    addOrUpdate(newUser, user).then(() => {
      hideRateAppDialog()
      window.open(getWriteReviewUrl(), '_blank')
    })
  }

  const onClickSendFeedback = event => {
    event.preventDefault()

    let newUser = new User({
      ...user,
      ratedApp: true,
    })

    addOrUpdate(newUser, user).then(() => {
      hideRateAppDialog()

      if (window.$globo) {
        window.$globo.push(['showChat'])
        window.$globo.push(['openChat'])
      } else {
        window.open('mailto:info@dododentist.com', '_blank')
      }
    })
  }

  let showSendFeedback =
    (firstStar || secondStar || thirdStar || fourthStar) && !fifthStar

  return (
    <Modal>
      <form>
        <div css={{ padding: '30px 20px 23px 20px' }}>
          {!(
            firstStar ||
            secondStar ||
            thirdStar ||
            fourthStar ||
            fifthStar
          ) && (
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 20,
                paddingBottom: 15,
              }}
            >
              {t('How much do you like this app?')}
            </div>
          )}
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              css={[clickable, { display: 'inline-flex' }]}
              onClick={onClickFirstStar}
            >
              {firstStar ? (
                <IconStar css={{ padding: 12 }} />
              ) : (
                <IconStarEmpty css={{ padding: 12 }} />
              )}
            </div>
            <div
              css={[clickable, { display: 'inline-flex' }]}
              onClick={onClickSecondStar}
            >
              {secondStar ? (
                <IconStar css={{ padding: 12 }} />
              ) : (
                <IconStarEmpty css={{ padding: 12 }} />
              )}
            </div>
            <div
              css={[clickable, { display: 'inline-flex' }]}
              onClick={onClickThirdStar}
            >
              {thirdStar ? (
                <IconStar css={{ padding: 12 }} />
              ) : (
                <IconStarEmpty css={{ padding: 12 }} />
              )}
            </div>
            <div
              css={[clickable, { display: 'inline-flex' }]}
              onClick={onClickFourthStar}
            >
              {fourthStar ? (
                <IconStar css={{ padding: 12 }} />
              ) : (
                <IconStarEmpty css={{ padding: 12 }} />
              )}
            </div>
            <div
              css={[clickable, { display: 'inline-flex' }]}
              onClick={onClickFifthStar}
            >
              {fifthStar ? (
                <IconStar css={{ padding: 12 }} />
              ) : (
                <IconStarEmpty css={{ padding: 12 }} />
              )}
            </div>
          </div>
        </div>
        {fifthStar && (
          <button type="submit" onClick={onClickRateApp} css={button}>
            {t('Write review')}
          </button>
        )}
        {showSendFeedback && (
          <button type="submit" onClick={onClickSendFeedback} css={button}>
            {t('Send feedback')}
          </button>
        )}
      </form>
    </Modal>
  )
}

export default RateAppDialog
