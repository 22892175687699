/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  flag: '🇮🇪',
  countryName: 'Ireland',
  phonePrefix: '+353',
  administrativeDivisionsType: 'county',
  countryCode: 'IE',
  languages: ['en'],
  streetNumberBeforeStreetName: true,
  zipcodePattern: '[\\dA-Z]{3} ?[\\dA-Z]{4}',
}
