/* Copyright 2013 - 2024 Waiterio LLC */
import localForage from 'localforage'
import { produce } from '@monorepo/context/index.js'
import PRODUCT from '@monorepo/env/PRODUCT.js'

function burn(Class, documentId) {
  const { collection } = Class
  return localForage
    .removeItem(`${PRODUCT}.${collection}.${documentId}`)
    .then(() => {
      produce(draft => {
        delete draft.data[collection][documentId]
      })

      return documentId
    })
}

export default burn
