/* Copyright 2013 - 2024 Waiterio LLC */
import Pusher from 'pusher-js'
import IS_DEVELOPMENT from '@monorepo/env/IS_DEVELOPMENT.js'

let pusher
function getPusher(pusherAppKey) {
  if (!pusher) {
    const forceTLS = !!IS_DEVELOPMENT
    pusher = new Pusher(pusherAppKey, { cluster: 'mt1', forceTLS })

    // if (IS_DEVELOPMENT) {
    //   Pusher.log = function PusherLog(message) {
    //     if (window.console && window.console.log) {
    //       window.console.log(message)
    //     }
    //   }
    // }
  }
  return pusher
}

export default getPusher
