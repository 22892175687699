/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */

/* eslint-disable max-len */
import React, { Suspense } from 'react'
import { css, jsx } from '@emotion/react'
import { Link, useNavigate } from 'react-router-dom'
import { format } from 'date-fns'
import useLoggedInSession from '@monorepo/session/useLoggedInSession.js'
import formatPrice from '@monorepo/shared/formatPrice.js'
import { useTranslation } from '@multilocale/react/index.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarButton from '@stiloso/components/AppBarButton.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import ErrorBoundary from '@stiloso/components/ErrorBoundary.js'
import Spinner from '@stiloso/components/Spinner.js'
import card from '@stiloso/styles/card.js'
import clickable from '@stiloso/styles/clickable.js'
import getTotalPriceForProcedures from '../getTotalPriceForProcedures.js'
import usePayments from '../hooks/usePayments.js'

let paymentCard = css([
  clickable,
  card,
  {
    lineHeight: '48px',
    width: '100%',
    padding: '0 16px',
    display: 'flex',
  },
])

const PaymentsSection = ({
  addOrUpdate,
  appointmentsIds,
  clinicId,
  currency,
  payments,
  patientId,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <>
      <AppBar>
        <AppBarTitle>{t('Payments')}</AppBarTitle>
        <div css={{ flexGrow: 1 }} />
        <AppBarButton
          appearance="secondary"
          label={t('Add payment')}
          onClick={async () => {
            let document = await addOrUpdate()
            let patientId_ =
              patientId || document?.patientId || document._id || ''
            let url = `/clinics/${clinicId}/payments/new?patientId=${patientId_}`
            if (appointmentsIds) {
              url += `&appointmentsIds=${appointmentsIds.join(',')}`
            }
            navigate(url)
          }}
        />
      </AppBar>
      {payments.length > 0 && (
        <div
          css={{
            marginBottom: 16,
            display: 'flex',
            flexWrap: 'wrap',
            gap: 8,
          }}
        >
          {patientId &&
            payments?.map(({ _id, startTime, patientId, procedures }) => {
              let url = `/clinics/${clinicId}/payments/${_id}?patientId=${patientId}`
              if (appointmentsIds) {
                url += `&appointmentsIds=${appointmentsIds.join(',')}`
              }
              return (
                <Link key={_id} css={paymentCard} to={url}>
                  <span>{format(startTime, 'yyyy-MM-dd HH:mm')}</span>
                  <span css={{ flexGrow: 1 }} />
                  <span>
                    {formatPrice(
                      getTotalPriceForProcedures(procedures),
                      currency,
                      true,
                    )}
                  </span>
                </Link>
              )
            })}
        </div>
      )}
    </>
  )
}

const PaymentsSectionConnected = props => {
  useLoggedInSession()
  const { clinicId, patientId } = props
  const payments = usePayments({ clinicId, patientId })

  return <PaymentsSection {...props} payments={payments} />
}

const PaymentsSectionWrapper = props => (
  <ErrorBoundary>
    <Suspense fallback={<Spinner />}>
      <PaymentsSectionConnected {...props} />
    </Suspense>
  </ErrorBoundary>
)

export default PaymentsSectionWrapper
