/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useState } from 'react'
import { css, jsx } from '@emotion/react'
import { Link, useSearchParams } from 'react-router-dom'
import getBrowserLanguage from '@monorepo/shared/getBrowserLanguage.js'
import { useTranslation } from '@multilocale/react/index.js'
import useCurrentCountry from '@multilocale/react/useCurrentCountry.js'
import Button from '@stiloso/components/Button.js'
import IllustrationManWorkingOnComputer from '@stiloso/components/IllustrationManWorkingOnComputer.js'
import Input from '@stiloso/components/Input.js'
import LanguagePicker from '@stiloso/components/LanguagePicker.js'
import PhoneInput from '@stiloso/components/PhoneInput.js'
import dLgFlex from '@stiloso/styles/bootstrap/dLgFlex.js'
import dNone from '@stiloso/styles/bootstrap/dNone.js'
import label from '@stiloso/styles/label.js'
import link from '@stiloso/styles/link.js'

const SignupPage = ({
  email: emailPrefilled,
  password: passwordPrefilled,
  brand,
  illustration,
  signup,
}) => {
  const [searchParams] = useSearchParams()
  const languageParams = searchParams.get('language')
  const emailParams = searchParams.get('email')
  const passwordParams = searchParams.get('password')
  let browserLanguage = getBrowserLanguage()
  const [language, setLanguage] = useState(languageParams || browserLanguage)
  const { t } = useTranslation(language)
  let [email, setEmail] = useState(emailPrefilled || emailParams || '')
  passwordPrefilled =
    passwordPrefilled || passwordParams
      ? atob(passwordPrefilled || passwordParams)
      : null
  let [password, setPassword] = useState(passwordPrefilled || '')
  let [phone, setPhone] = useState()
  let [error, setError] = useState()
  let [loading, setLoading] = useState(false)
  const currentCountry = useCurrentCountry()

  if (!phone?.length > 0) {
    phone = currentCountry?.phonePrefix
  }

  const changeEmail = event => {
    setEmail(event.target.value)
  }

  const changePassword = event => {
    setPassword(event.target.value)
  }

  const changePhone = phone => {
    setPhone(phone)
  }

  const showError = error => {
    console.error(error)

    setError(error?.message)
  }

  const onSubmit = async event => {
    try {
      event.preventDefault()

      setLoading(true)
      await signup({
        email,
        password,
        phone,
        language,
        country: currentCountry?.countryCode,
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      showError(error)
    }
  }

  illustration ||= (
    <IllustrationManWorkingOnComputer
      css={{
        width: '100%',
        height: 'auto',
        objectFit: 'cover',
      }}
    />
  )

  return (
    <div
      css={{
        background: 'white',
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <div
        css={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div
          css={{
            margin: 'auto',
            padding: '0px 16px',
            lineHeight: '48px',
            maxWidth: 480,
            width: '100%',
          }}
        >
          <Link to="/">{brand}</Link>
        </div>
        <div
          css={{
            margin: 'auto',
            padding: '0px 16px',
            maxWidth: 480,
            width: '100%',
          }}
        >
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              css={{
                fontSize: 24,
                fontWeight: '700',
                textTransform: 'capitalize',
                lineHeight: '24px',
                color: 'var(--color-base)',
              }}
            >
              {t('Create your account')}
            </div>
            <div css={{ flexGrow: 1 }} />
            <LanguagePicker
              selectedLanguage={language}
              onSelectLanguage={setLanguage}
            />
          </div>
          <form onSubmit={onSubmit}>
            <Input
              type="email"
              name="email"
              label={t('email')}
              autoComplete="email"
              autoCapitalize="none"
              autoCorrect="off"
              value={email || ''}
              onChange={changeEmail}
              required
              autoFocus
            />
            <PhoneInput onChange={changePhone} phone={phone} required />
            <Input
              type="password"
              name="password"
              label={t('password')}
              autoComplete="current-password"
              autoCapitalize="none"
              autoCorrect="off"
              value={password || ''}
              onChange={changePassword}
              required
            />
            <Button
              type="submit"
              css={{ marginTop: 16 }}
              isLoading={loading}
              fullWidth
            >
              {t('Sign up')}
            </Button>
          </form>
          <div
            css={{
              display: 'flex',
              fontSize: 14,
              lineHeight: '24px',
              color: 'var(--color-base)',
              width: '100%',
              marginTop: 12,
              textAlign: 'center',
            }}
          >
            <Link
              to={`/forgotpassword?email=${email}&language=${language}`}
              css={[link, { padding: '12px 12px 12px 0px' }]}
            >
              {t('Forgot password')}
            </Link>
            <div css={{ flexGrow: 1 }} />
            <Link
              to={`/login?email=${email}&language=${language}`}
              css={[link, { padding: '12px 0px 12px 12px' }]}
            >
              {t('Login')}
            </Link>
          </div>
          {error && (
            <div
              css={{
                textAlign: 'center',
                lineHeight: '24px',
                marginTop: 32,
                color: 'var(--color-red)',
              }}
            >
              {t(error)}
            </div>
          )}
        </div>
        <div css={{ margin: 'auto' }} />
      </div>
      <div css={[dNone, dLgFlex, { flex: 1, display: 'flex' }]}>
        {illustration}
      </div>
    </div>
  )
}

export default SignupPage
