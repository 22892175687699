/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { useParams } from 'react-router-dom'
import { useTranslation } from '@multilocale/react/index.js'
import IconAgenda from '@stiloso/icons/IconAgenda.js'
import IconAlarm from '@stiloso/icons/IconAlarm.js'
import IconCalendar from '@stiloso/icons/IconCalendar.js'
import IconFolders from '@stiloso/icons/IconFolders.js'
import IconSearchPerson from '@stiloso/icons/IconSearchPerson.js'
import IconSettings from '@stiloso/icons/IconSettings.js'
import IconTooth from '@stiloso/icons/IconTooth.js'
import IconUser from '@stiloso/icons/IconUser.js'
import IconUsers from '@stiloso/icons/IconUsers.js'
import dLgBlock from '@stiloso/styles/bootstrap/dLgBlock.js'
import dLgNone from '@stiloso/styles/bootstrap/dLgNone.js'
import dNone from '@stiloso/styles/bootstrap/dNone.js'
import LayoutDesktop from './LayoutDesktop.js'
import LayoutSmartphone from './LayoutSmartphone.js'
import useClinics from '../hooks/useClinics.js'

const useMainPages = title => {
  const { t } = useTranslation()
  let clinics = useClinics()
  clinics ||= []

  let pathname = ''

  if (typeof window !== 'undefined') {
    pathname = window.location.pathname
  }

  let { clinicId } = useParams()

  clinics = clinics.sort((a, b) => (a.creationTime > b.creationTime ? 1 : -1))

  clinicId = clinicId || clinics?.[0]?._id

  let pages = [
    clinics.length > 1 && {
      label: 'Clinics',
      to: '/clinics',
      Icon: IconFolders,
    },
    {
      label: 'Calendar',
      to: '/clinics/{clinicId}/calendar',
      Icon: IconCalendar,
    },
    {
      label: 'Agenda',
      to: '/clinics/{clinicId}/agenda',
      Icon: IconAgenda,
    },
    {
      label: 'Appointments',
      to: '/clinics/{clinicId}/appointments',
      Icon: IconAlarm,
    },
    {
      label: 'Patients',
      to: '/clinics/{clinicId}/patients',
      Icon: IconSearchPerson,
    },
    {
      label: 'Procedures',
      to: '/clinics/{clinicId}/procedures',
      Icon: IconTooth,
    },
    {
      label: 'Team',
      to: '/team',
      Icon: IconUsers,
    },
    {
      label: 'Settings',
      to: '/clinics/{clinicId}',
      Icon: IconSettings,
    },
    {
      label: 'Account',
      to: '/account',
      Icon: IconUser,
    },
  ]
    .filter(_ => _)
    .map(page => ({
      ...page,
      label: t(page.label),
      to: page.to.replace('{clinicId}', clinicId),
      selected:
        pathname.endsWith(page.to.replace('{clinicId}', clinicId)) ||
        title === t(page.label),
    }))

  return pages
}

const Layout = props => {
  let { children, main, title } = props
  const pages = useMainPages(title)
  title += ' - Dodo Dentist'

  if (typeof main === 'undefined') {
    main = true
  }

  let subprops = {
    ...props,
    children: undefined,
    main,
    pages,
  }

  return (
    <>
      <title>{title}</title>
      <div css={dLgNone}>
        <LayoutSmartphone {...subprops}>{children}</LayoutSmartphone>
      </div>
      <div css={[dNone, dLgBlock]}>
        <LayoutDesktop {...subprops}>{children}</LayoutDesktop>
      </div>
    </>
  )
}

export default Layout
