/* Copyright 2013 - 2024 Waiterio LLC */
import getTextDirectionFromLanguageCode from '@monorepo/shared/getTextDirectionFromLanguageCode.js'
import { getDefaultLanguage } from '@multilocale/react/index.js'

const getDirection = () => {
  const language = getDefaultLanguage()
  const direction = getTextDirectionFromLanguageCode(language)

  return direction
}

export default getDirection
