/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  flag: '🇲🇾',
  countryName: 'Malaysia',
  phonePrefix: '+60',
  administrativeDivisionsType: 'state',
  countryCode: 'MY',
  languages: ['ms'],
  zipcodePattern: '\\d{5}',
}
