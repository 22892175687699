/* Copyright 2013 - 2024 Waiterio LLC */

function camelCaseToCapitalized(string) {
  string = string
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space before uppercase letters
    .toLowerCase() // Convert the entire string to lowercase

  string = string.charAt(0).toUpperCase() + string.slice(1)

  return string
}

export default camelCaseToCapitalized
