/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import getClassMany from '@monorepo/client/getClassMany.js'
import subscribesPusher from '@monorepo/services/subscribesPusher.js'
import storeNewSession from '@monorepo/session/storeNewSession.js'
import fetchAllData from '@monorepo/tractor/fetchAllData.js'
import classes from '@dododentist/model/classes.js'
import Clinic from '@dododentist/model/Clinic.js'
import IconBrand from '@dododentist/shared/IconBrand.js'
import IllustrationDentist from '@dododentist/shared/IllustrationDentist.js'
import pusherAppKey from '@dododentist/shared/pusherAppKey.js'
import LoginPage from '@stiloso/pages/LoginPage.js'
import identify from '../identify.js'
import postLogin from '../postLogin.js'

export const paths = ['/login']

const LoginPageWrapper = () => {
  const navigate = useNavigate()
  const login = async ({ email, password }) => {
    const loginResponse = await postLogin(email, password)
    await storeNewSession(loginResponse)
    await fetchAllData(classes)
    await identify()

    subscribesPusher({ classes, pusherAppKey })

    const clinics = await getClassMany(Clinic)

    if (clinics.length === 1) {
      navigate(`/clinics/${clinics[0]._id}/appointments`)
    } else {
      navigate('/clinics')
    }
  }

  let brand = <IconBrand width={36} height={36} fontSize={36} />

  return (
    <LoginPage
      brand={brand}
      login={login}
      illustration={<IllustrationDentist />}
    />
  )
}

export default LoginPageWrapper
