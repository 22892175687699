/* Copyright 2013 - 2024 Waiterio LLC */
import localForage from 'localforage'
import { produce } from '@monorepo/context/index.js'
import PRODUCT from '@monorepo/env/PRODUCT.js'

function plant(Class, document) {
  const { collection } = Class
  return localForage
    .setItem(`${PRODUCT}.${collection}.${document._id}`, document)
    .then(document => {
      document = new Class(document)

      produce(draft => {
        if (!draft.data[collection]) {
          draft.data[collection] = {}
        }

        draft.data[collection][document._id] = document
      })

      return document
    })
}

export default plant
