/* Copyright 2013 - 2024 Waiterio LLC */
import c from '@monorepo/shared/convertStringToColor.js'

const conditions = {
  Caries: c('Caries'), // Carie
  Filling: c('Filling'), // Otturazione
  Missing: c('Missing'), // Mancante
  'Root Canal Treatment': c('Root Canal Treatment'), // Devitalizzazione
  'Crown fracture': c('Crown fracture'), // Frattura coronale
  'Tender to Percussion': c('Tenderness to Percussion'), // Dolente alla percussione
  Inlay: c('Inlay'), // Intarsio
  Reconstruction: c('Reconstruction'), // Ricostruzione
  'Single crown': c('Single crown'), // Corona singola
  Implant: c('Implant'), // Impianto
  Bridge: c('Bridge'), // Ponte
  'Removable prosthesis': c('Removable prosthesis'), // Protesi mobile
  'Post and core crown': c('Post and core crown'), // Perno moncone
  Overdenture: c('Overdenture'), // Elemento di scheletrato/overdenture
  'Abutment tooth': c('Abutment tooth'), // Dente pilastro
  'Root residue': c('Root residue'), // Residuo radicolare
  'Non-vital': c('Non-vital'), // Elemento non vitale
  'Mobility +': c('Mobility +'), // Mobilità grado +
  'Mobility 1': c('Mobility 1'), // Mobilità grado 1
  'Mobility 2': c('Mobility 2'), // Mobilità grado 2
  'Mobility 3': c('Mobility 3'), // Mobilità grado 3
  'Gingival recession Miller I class': c('Gingival recession Miller I class '), // Recessione gengivale classe di Miller I
  'Gingival recession Miller II class': c('Gingival recession Miller II class'), // Recessione gengivale classe di Miller II
}

export default conditions
