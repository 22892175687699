/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint-disable no-var, import/order */

import IS_BROWSER from './IS_BROWSER.js'
import IS_NODE from './IS_NODE.js'

let version

if (IS_BROWSER) {
  version = window.MONOREPO_VERSION
} else if (IS_NODE && process.env.MONOREPO_VERSION) {
  version = process.env.MONOREPO_VERSION
}

export default version
