/* Copyright 2013 - 2024 Waiterio LLC */
import getApiUrl from '@dododentist/shared/getApiUrl.js'
import fetch from '@monorepo/fetch/index.js'

export default body => {
  const { email, password } = body
  delete body.email
  delete body.password
  return fetch({
    url: getApiUrl() + '/api/signup',
    method: 'POST',
    body,
    headers: {
      Authorization: `Basic ${btoa(email + ':' + password)}`,
    },
  })
}
