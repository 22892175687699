/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { Suspense, useEffect, useRef, useState } from 'react'
import { css, jsx } from '@emotion/react'
import {
  unstable_useBlocker as useBlocker,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import {
  addMinutes,
  format,
  formatISO,
  getDayOfYear,
  getHours,
  getMinutes,
  getMonth,
  getYear,
  isBefore,
  parse,
  parseISO,
  setDayOfYear,
  setHours,
  setMinutes,
  setMonth,
  setYear,
  startOfToday,
} from 'date-fns'
import { pipe } from 'ramda'
import countries from '@monorepo/countries/index.js'
import useLoggedInSession from '@monorepo/session/useLoggedInSession.js'
import { toastError } from '@monorepo/shared/AndroidToast.js'
import generatePermutations from '@monorepo/shared/generatePermutations.js'
import preferCapitalizedEachWord from '@monorepo/shared/preferCapitalizedEachWord.js'
import uuid from '@monorepo/shared/uuid.js'
import addOrUpdate from '@monorepo/tractor/addOrUpdate.js'
import remove from '@monorepo/tractor/remove.js'
import Appointment from '@dododentist/model/Appointment.js'
import Patient from '@dododentist/model/Patient.js'
import { useTranslation } from '@multilocale/react/index.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarButton from '@stiloso/components/AppBarButton.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import ButtonBar from '@stiloso/components/ButtonBar.js'
import Dropdown from '@stiloso/components/Dropdown.js'
import ErrorBoundary from '@stiloso/components/ErrorBoundary.js'
import Input from '@stiloso/components/Input.js'
import PhoneInput from '@stiloso/components/PhoneInput.js'
import Spinner from '@stiloso/components/Spinner.js'
import IconDelete from '@stiloso/icons/IconDelete.js'
import colMd6 from '@stiloso/styles/bootstrap/colMd6.js'
import colSm12 from '@stiloso/styles/bootstrap/colSm12.js'
import container from '@stiloso/styles/bootstrap/container.js'
import dLgBlock from '@stiloso/styles/bootstrap/dLgBlock.js'
import dNone from '@stiloso/styles/bootstrap/dNone.js'
import row from '@stiloso/styles/bootstrap/row.js'
import card from '@stiloso/styles/card.js'
import clickable from '@stiloso/styles/clickable.js'
import AnamnesisSection from '../components/AnamnesisSection.js'
import AppointmentRemindersSection from '../components/AppointmentRemindersSection.js'
import DentalHistorySection from '../components/DentalHistorySection.js'
import ImagesSection from '../components/ImagesSection.js'
import InvoicesSection from '../components/InvoicesSection.js'
import Layout from '../components/Layout.js'
import PaymentsSection from '../components/PaymentsSection.js'
import ProceduresSection from '../components/ProceduresSection.js'
import SectionsBar, { Holds } from '../components/SectionsBar.js'
import TreatmentsSection from '../components/TreatmentsSection.js'
import SaveChangesPrompt from '../dialogs/SaveChangesPrompt.js'
import useAppointment from '../hooks/useAppointment.js'
import useClinic from '../hooks/useClinic.js'
import usePatient from '../hooks/usePatient.js'
import usePatients from '../hooks/usePatients.js'
import useReminders from '../hooks/useReminders.js'
import useSeats from '../hooks/useSeats.js'
import useUsers from '../hooks/useUsers.js'

export const paths = ['/clinics/:clinicId/appointments/:appointmentId']

const dateInputFormat = 'yyyy-MM-dd'
const timeInputFormat = 'HH:mm'
const defaultAppointmentLengthMinutes = 30

const DropdownPatientName = ({
  clinicId,
  patientName,
  patientId,
  onSelectPatient,
}) => {
  let patients = usePatients({
    clinicId,
  })

  let [filteredPatients, setFilteredPatients] = useState([])

  patientName = patientName?.trim?.()
  patientName = patientName?.toLowerCase?.()

  let onSelectPatient_ = patient => {
    onSelectPatient(patient)
    setFilteredPatients([])
  }

  useEffect(() => {
    if (!patientId) {
      let filteredPatients = []

      if (patientName?.length >= 2) {
        filteredPatients = patients.filter(patient => {
          let permutations = generatePermutations(
            patient.name.toLowerCase().split(' '),
          ).map(permutation => permutation.join(' '))
          return permutations.some(permutation =>
            permutation.includes(patientName),
          )
        })
      }

      setFilteredPatients(filteredPatients)
    }
  }, [patientName])

  useEffect(() => {
    const handleClickOutside = event => {
      if (patients.length) {
        setFilteredPatients([])
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    patients && (
      <div css={{ position: 'relative' }}>
        <div
          css={{
            position: 'absolute',
            background: 'white',
            width: '100%',
            zIndex: 99999,
          }}
        >
          {filteredPatients.map(patient => (
            <div
              key={patient._id}
              css={[
                clickable,
                {
                  border: '1px solid #D9E0ED',
                  padding: '12px 8px',
                },
              ]}
              onClick={() => onSelectPatient_(patient)}
            >
              {patient.name}&nbsp;&nbsp;{patient.phone || patient.email}
            </div>
          ))}
        </div>
      </div>
    )
  )
}

const DropdownPatientPhone = ({
  changePhone,
  clinicId,
  patientPhone,
  patientId,
  onSelectPatient,
}) => {
  let patients = usePatients({
    clinicId,
  })

  let [filteredPatients, setFilteredPatients] = useState([])

  let onSelectPatient_ = patient => {
    onSelectPatient(patient)
    setFilteredPatients([])
  }

  useEffect(() => {
    let localPhone = patientPhone || ''
    if (localPhone.includes('+1')) {
      localPhone = localPhone.slice(2)
    } else if (localPhone.includes('+')) {
      localPhone = localPhone.slice(3)
    }
    if (!patientId && localPhone?.length >= 2) {
      setFilteredPatients(
        patients.filter(patient =>
          patient?.phone?.toLowerCase?.()?.includes(patientPhone),
        ),
      )
    }
  }, [patientPhone])

  useEffect(() => {
    const handleClickOutside = event => {
      if (patients.length) {
        setFilteredPatients([])
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <>
      <PhoneInput phone={patientPhone} onChange={changePhone} />
      {patients && (
        <div css={{ position: 'relative' }}>
          <div
            css={{
              position: 'absolute',
              background: 'white',
              width: '100%',
            }}
          >
            {filteredPatients.map(patient => (
              <div
                key={patient._id}
                css={[
                  clickable,
                  { border: '1px solid #D9E0ED', padding: '12px 8px' },
                ]}
                onClick={() => onSelectPatient_(patient)}
              >
                {patient.phone}&nbsp;&nbsp;{patient.name}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

const DropdownPatientEmail = ({
  clinicId,
  patientEmail,
  patientId,
  onSelectPatient,
}) => {
  let patients = usePatients({
    clinicId,
  })

  let [filteredPatients, setFilteredPatients] = useState([])
  patientEmail = patientEmail?.toLowerCase()

  let onSelectPatient_ = patient => {
    onSelectPatient(patient)
    setFilteredPatients([])
  }

  useEffect(() => {
    if (!patientId && patientEmail?.length >= 2) {
      setFilteredPatients(
        patients.filter(patient =>
          patient.email?.toLowerCase().includes(patientEmail),
        ),
      )
    }
  }, [patientEmail])

  useEffect(() => {
    const handleClickOutside = event => {
      if (patients.length) {
        setFilteredPatients([])
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    patients && (
      <div css={{ position: 'relative' }}>
        <div
          css={{
            position: 'absolute',
            background: 'white',
            width: '100%',
          }}
        >
          {filteredPatients.map(patient => (
            <div
              key={patient._id}
              css={[
                clickable,
                {
                  border: '1px solid #D9E0ED',
                  padding: '12px 8px',
                },
              ]}
              onClick={() => onSelectPatient_(patient)}
            >
              {patient.name}&nbsp;&nbsp;{patient.email}
            </div>
          ))}
        </div>
      </div>
    )
  )
}

const AppointmentPage = props => {
  useLoggedInSession()
  let {
    action,
    appointment,
    clinic,
    reminders: remindersDefault,
    patient,
    seats,
    users,
  } = props
  let location = useLocation()
  let urlSearchParams = new URLSearchParams(location.search)
  let queryDateString = urlSearchParams.get('date')
  let startTimeString = urlSearchParams.get('start')

  const navigate = useNavigate()
  const { t } = useTranslation()

  let appointmentRef = useRef(null)
  let proceduresRef = useRef(null)
  let paymentsRef = useRef(null)
  let invoicesRef = useRef(null)
  let patientRef = useRef(null)
  let dentalHistoryRef = useRef(null)
  let imagesRef = useRef(null)
  let treatmentsRef = useRef(null)
  let anamnesisRef = useRef(null)

  let sections = [
    { name: t('Appointment'), ref: appointmentRef },
    { name: t('Procedures'), ref: proceduresRef },
    { name: t('Payments'), ref: paymentsRef },
    // { name: t('Invoices'), ref: invoicesRef },
    { name: t('Patient'), ref: patientRef },
    // { name: t('Dental history'), ref: dentalHistoryRef },
    { name: t('Images'), ref: imagesRef },
    { name: t('Treatments'), ref: treatmentsRef },
    { name: t('Anamnesis'), ref: anamnesisRef },
  ]

  let [patientId, setPatientId] = useState(patient?._id)

  let startOfDay = startOfToday()

  try {
    if (queryDateString) {
      let queryDate = parse(queryDateString, dateInputFormat, new Date())
      startOfDay = pipe(
        date => setYear(date, getYear(queryDate)),
        date => setMonth(date, getMonth(queryDate)),
        date => setDayOfYear(date, getDayOfYear(queryDate)),
      )(startOfDay)
    }
  } catch (error) {
    // DO NOTHING ON PURPOSE
  }

  let startTimeDefault = pipe(
    date => setHours(date, 10),
    date => setMinutes(date, 0),
  )(startOfDay)
  try {
    if (startTimeString) {
      let [hours, minutes] = startTimeString.split(':')
      startTimeDefault = pipe(
        date => setHours(date, hours),
        date => setMinutes(date, minutes),
      )(startTimeDefault)
    }
  } catch (error) {
    // DO NOTHING ON PURPOSE
  }

  let endTimeDefault = addMinutes(startTimeDefault, 30)

  const [edited, setEdited] = useState(false)
  const [isLoading, setLoading] = useState(false)
  let [_id] = useState(appointment?._id || uuid())
  let [patientName, setPatientName] = useState(patient?.name || '')
  let [patientPhone, setPatientPhone] = useState(patient?.phone || '')
  let [patientEmail, setPatientEmail] = useState(patient?.email || '')
  let [patientAnamnesis, setPatientAnamnesis] = useState(
    patient?.anamnesis || {},
  )
  let [patientTeeth, setPatientTeeth] = useState(patient?.teeth || {})
  const [startTime, setStartTime] = useState(
    appointment?.startTime || formatISO(startTimeDefault),
  )
  const [endTime, setEndTime] = useState(
    appointment?.endTime || formatISO(endTimeDefault),
  )

  let [seatId, setSeatId] = useState(appointment?.seatId || null)
  let [note, setNote] = useState(appointment?.note || '')
  let [procedures, setProcedures] = useState(appointment?.procedures || [])
  let [reminders, setReminders] = useState(remindersDefault)
  let [usersIds, setUsersIds] = useState(appointment?.usersIds || [])
  let dateInputValue = format(parseISO(startTime), dateInputFormat)
  // console.log({ dateInputValue })
  let startTimeInputValue = format(parseISO(startTime), timeInputFormat)
  // console.log({ startTimeInputValue })
  let endTimeInputValue = format(parseISO(endTime), timeInputFormat)
  // console.log({ endTimeInputValue })

  const changePatientName = event => {
    let newPatientName = event.target.value
    setEdited(true)
    setPatientName(newPatientName)

    if (newPatientName.length === 0) {
      setPatientId(null)
    }
  }

  const changePatientAnamnesis = anamnesis => {
    setEdited(true)
    setPatientAnamnesis(anamnesis)
  }

  const changePatientTeeth = teeth => {
    setEdited(true)
    setPatientTeeth(teeth)
  }

  const onSelectPatient = patient => {
    setEdited(true)
    setPatientId(patient._id || '')
    setPatientName(patient.name || '')
    setPatientEmail(patient.email || '')
    setPatientPhone(patient.phone || '')
  }

  const changePatientPhone = phone => {
    setEdited(true)
    setPatientPhone(phone)
  }

  const changePatientEmail = event => {
    setEdited(true)
    setPatientEmail(event.target.value)
  }

  const changeProcedures = procedures => {
    setEdited(true)
    setProcedures(procedures)
  }

  const changeDate = event => {
    let value = event.target.value
    setEdited(true)
    // console.log('changeDate', event.target.value)
    let parsedDate = parse(value, 'yyyy-MM-dd', new Date())

    try {
      let newStartTime = pipe(
        parseISO,
        // date => {
        //   console.log(date)
        //   return date
        // },
        date => setYear(date, getYear(parsedDate)),
        date => setMonth(date, getMonth(parsedDate)),
        date => setDayOfYear(date, getDayOfYear(parsedDate)),
        formatISO,
      )(startTime)
      // console.log(newStartTime)
      setStartTime(newStartTime)
    } catch (error) {
      // DO NOTHING ON PURPOSE
    }

    try {
      let newEndTime = pipe(
        parseISO,
        date => setYear(date, getYear(parsedDate)),
        date => setMonth(date, getMonth(parsedDate)),
        date => setDayOfYear(date, getDayOfYear(parsedDate)),
        formatISO,
      )(endTime)
      // console.log(newEndTime)
      setEndTime(newEndTime)
    } catch (error) {
      // DO NOTHING ON PURPOSE
    }
  }

  const changeStartTime = event => {
    try {
      setEdited(true)
      // console.log('changeEndTime', event.target.value)
      let parsedTime = parse(event.target.value, timeInputFormat, new Date())
      // console.log(parsedTime)
      let newStartTime = pipe(
        parseISO,
        date => setHours(date, getHours(parsedTime)),
        date => setMinutes(date, getMinutes(parsedTime)),
        formatISO,
      )(startTime)
      // console.log(newStartTime)
      setStartTime(newStartTime)

      if (isBefore(new Date(endTime), new Date(newStartTime))) {
        let newEndTime = pipe(
          parseISO,
          date => setHours(date, getHours(newStartTime)),
          date => setMinutes(date, getMinutes(newStartTime)),
          date => addMinutes(date, defaultAppointmentLengthMinutes),
          formatISO,
        )(newStartTime)

        setEndTime(newEndTime)
      }
    } catch (error) {
      // DO NOTHING ON PURPOSE
    }
  }

  const changeEndTime = event => {
    try {
      setEdited(true)
      // console.log('changeEndTime', event.target.value)
      let parsedTime = parse(event.target.value, timeInputFormat, new Date())
      // console.log(parsedTime)
      let newEndTime = pipe(
        parseISO,
        date => setHours(date, getHours(parsedTime)),
        date => setMinutes(date, getMinutes(parsedTime)),
        formatISO,
      )(endTime)
      // console.log(newEndTime)
      setEndTime(newEndTime)
    } catch (error) {
      // DO NOTHING ON PURPOSE
    }
  }

  const changeNote = event => {
    setEdited(true)
    setNote(event.target.value)
  }

  const changeSeatId = seatId => {
    setEdited(true)
    setSeatId(seatId)
  }

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      edited && currentLocation.pathname !== nextLocation.pathname,
  )

  useEffect(() => {
    if (!edited) {
      blocker?.proceed?.()
    }
  }, [edited])

  const addOrUpdateAppointment = async event => {
    event?.preventDefault?.()

    try {
      let creationTime = new Date().toISOString()
      let lastEditTime = creationTime
      patientName = patientName?.trim() ?? ''
      patientName = preferCapitalizedEachWord(patientName)
      patientPhone = patientPhone?.replace(/[ \\(\\)\\-]/g, '')
      patientPhone = patientPhone?.length > 4 ? patientPhone : null

      let newPatient = new Patient({
        _id: patientId,
        ...patient,
        organizationId: clinic.organizationId,
        clinicId: clinic._id,
        name: patientName,
        phone: patientPhone,
        email: patientEmail,
        anamnesis: patientAnamnesis,
        teeth: patientTeeth,
      })

      const newAppointment = new Appointment({
        _id,
        creationTime,
        lastEditTime,
        organizationId: clinic.organizationId,
        clinicId: clinic._id,
        patientId: patientId || newPatient._id,
        startTime,
        endTime,
        note,
        procedures,
        reminders,
        seatId,
        usersIds,
      })

      if (edited) {
        setLoading(true)

        await Promise.all([
          addOrUpdate(newAppointment, appointment),
          addOrUpdate(newPatient, patient),
        ])

        setLoading(false)
        setEdited(false)
        navigate(`/clinics/${clinic._id}/appointments`)
      }

      return newAppointment
    } catch (error) {
      setLoading(false)
      setEdited(true)
      toastError(t(error))
    }
  }

  let closePrompt = () => {
    blocker.reset()
  }

  let discardChangesPrompt = () => {
    blocker.proceed()
  }

  let deleteAppointment = async event => {
    event?.preventDefault?.()

    try {
      if (appointment) {
        await remove(appointment)
      }

      setEdited(false)
      navigate(`/clinics/${clinic._id}/appointments`)
    } catch (error) {
      toastError(t(error))
    }
  }

  let saveChangesPrompt = async () => {
    await addOrUpdateAppointment()
    blocker.proceed()
  }

  useEffect(() => {
    if (appointment) {
      setStartTime(appointment.startTime)
      setEndTime(appointment.endTime)
      setSeatId(appointment.seatId)
      setNote(appointment.note)
      setProcedures(appointment.procedures || [])
      setReminders(appointment.reminders || [])
    }
  }, [appointment])

  useEffect(() => {
    if (!appointment?.seatId && seats?.length === 1) {
      setSeatId(seats[0]._id)
    }
  }, [appointment, seats])

  useEffect(() => {
    if (!appointment?.usersIds && users?.length === 1) {
      setUsersIds([users[0]._id])
    }
  }, [appointment, users])

  useEffect(() => {
    if (patient) {
      setPatientName(patient.name)
      setPatientPhone(patient.phone)
      setPatientEmail(patient.email)
      setPatientAnamnesis(patient.anamnesis)
      setPatientTeeth(patient.teeth)
    }
  }, [patient])

  useEffect(() => {
    if (clinic?.countryCode) {
      let patientPhone = patient?.phone || ''

      if (!patientPhone.includes('+') && clinic.countryCode) {
        let prefix = countries[clinic.countryCode]?.phonePrefix || ''
        patientPhone = prefix + patientPhone
        setPatientPhone(patientPhone)
      }

      setPatientPhone(patientPhone)
    }
  }, [clinic?.countryCode])

  useEffect(() => {
    if (action) {
      navigate('?action=')
    }

    if (action === 'delete') {
      deleteAppointment()
    }
  }, [action])

  return (
    <form onSubmit={addOrUpdateAppointment}>
      <SectionsBar sections={sections} />
      <div css={container}>
        <div css={row}>
          <div css={[colSm12, colMd6]}>
            <Holds ref={appointmentRef} />
            <div css={[dNone, dLgBlock]}>
              <AppBar>
                <AppBarTitle>
                  {appointment ? t('Appointment') : t('New appointment')}
                </AppBarTitle>
                <div css={{ flexGrow: 1 }} />
                {appointment && (
                  <AppBarButton
                    appearance="secondary"
                    Icon={IconDelete}
                    label={t('Delete')}
                    onClick={deleteAppointment}
                  />
                )}
              </AppBar>
            </div>
            <div css={[card, { marginTop: 16 }]}>
              <Input
                label={t('Date')}
                type="date"
                value={dateInputValue}
                onChange={changeDate}
              />
              <Input
                label={t('Start time')}
                type="time"
                value={startTimeInputValue}
                onChange={changeStartTime}
              />
              <Input
                label={t('End time')}
                type="time"
                value={endTimeInputValue}
                onChange={changeEndTime}
              />
              {seats?.length > 1 && (
                <Dropdown
                  name="Seat"
                  labels={seats.map(seat => seat.name || seat._id)}
                  values={seats.map(seat => seat._id)}
                  currentValue={seatId}
                  onSelect={changeSeatId}
                />
              )}
              <Input
                label={t('Note')}
                value={note}
                onChange={changeNote}
                autoComplete="off"
              />
            </div>
            <Holds ref={proceduresRef} />
            <ProceduresSection
              clinicId={clinic._id}
              currency={clinic.currency}
              procedures={procedures}
              changeProcedures={changeProcedures}
            />
            <Holds ref={paymentsRef} />
            <PaymentsSection
              appointmentsIds={[appointment?._id]}
              clinicId={clinic._id}
              currency={clinic.currency}
              patientId={patient?._id}
              addOrUpdate={addOrUpdateAppointment}
            />
            <Holds ref={invoicesRef} />
            <InvoicesSection
              appointmentsIds={[appointment?._id]}
              clinicId={clinic._id}
              currency={clinic.currency}
              patientId={patient?._id}
              addOrUpdate={addOrUpdateAppointment}
            />
            <AppointmentRemindersSection
              appointment={{
                startTime,
                endTime,
              }}
              reminders={reminders}
            />
          </div>
          <div css={[colSm12, colMd6]}>
            <Holds ref={patientRef} />
            <AppBar>
              <AppBarTitle>
                {patientId ? t('Patient') : t('New patient')}
              </AppBarTitle>
              <div css={{ flexGrow: 1 }} />
            </AppBar>
            <div css={card}>
              <Input
                label={t('Name')}
                autoFocus
                autoComplete="off"
                value={patientName}
                onChange={changePatientName}
              />
              <DropdownPatientName
                clinicId={clinic?._id}
                onSelectPatient={onSelectPatient}
                patientId={patientId}
                patientName={patientName}
              />
              <DropdownPatientPhone
                changePhone={changePatientPhone}
                clinicId={clinic?._id}
                defaultCountryCode={clinic?.countryCode}
                onSelectPatient={onSelectPatient}
                patientId={patientId}
                patientPhone={patientPhone}
              />
              <Input
                label={t('email')}
                autoComplete="off"
                value={patientEmail}
                onChange={changePatientEmail}
              />
              <DropdownPatientEmail
                changeEmail={changePatientEmail}
                clinicId={clinic?._id}
                onSelectPatient={onSelectPatient}
                patientId={patientId}
                patientEmail={patientEmail}
              />
            </div>
            <Holds ref={dentalHistoryRef} />
            <DentalHistorySection
              changeTeeth={changePatientTeeth}
              teeth={patientTeeth}
            />
            <Holds ref={imagesRef} />
            <ImagesSection
              clinicId={clinic._id}
              patientId={patient?._id}
              addOrUpdate={addOrUpdateAppointment}
            />
            <Holds ref={treatmentsRef} />
            <TreatmentsSection
              clinicId={clinic._id}
              patientId={patient?._id}
              addOrUpdate={addOrUpdateAppointment}
            />
            <Holds ref={anamnesisRef} />
            <AnamnesisSection
              anamnesis={patientAnamnesis}
              setAnamnesis={changePatientAnamnesis}
              clinicId={clinic._id}
              patientId={patient?._id}
            />
          </div>
        </div>
      </div>
      {(edited || !appointment) && (
        <ButtonBar appearance="secondary" type="submit" isLoading={isLoading}>
          {appointment ? t('Save appointment') : t('Add appointment')}
        </ButtonBar>
      )}
      {blocker?.state === 'blocked' && (
        <SaveChangesPrompt
          close={closePrompt}
          discardChanges={discardChangesPrompt}
          saveChanges={saveChangesPrompt}
        />
      )}
    </form>
  )
}

const AppointmentPageConnected = () => {
  useLoggedInSession()
  const { clinicId, appointmentId } = useParams()
  const [searchParams] = useSearchParams()
  const action = searchParams.get('action')
  let location = useLocation()
  let urlSearchParams = new URLSearchParams(location.search)

  const clinic = useClinic(clinicId)
  const reminders = useReminders({ class_: 'Appointment', clinicId })
  const appointment = useAppointment(appointmentId)
  const seats = useSeats({ clinicId })
  const users = useUsers()

  let patientId = appointment?.patientId || urlSearchParams.get('patientId')

  const patient = usePatient(patientId)

  return (
    <AppointmentPage
      action={action}
      appointment={appointment}
      clinic={clinic}
      reminders={reminders}
      patient={patient}
      seats={seats}
      users={users}
    />
  )
}

const AppointmentPageWrapper = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { appointmentId, clinicId } = useParams()
  const isNewAppointment = appointmentId === 'new'
  const title = t(isNewAppointment ? 'New appointment' : 'Appointment')
  const onClickTitle = () => {
    navigate(`/clinics/${clinicId}/appointments`)
  }
  const actions = [!isNewAppointment && 'delete'].filter(_ => _)
  return (
    <Layout
      actions={actions}
      back
      main={false}
      onClickTitle={onClickTitle}
      title={title}
    >
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <AppointmentPageConnected />
        </Suspense>
      </ErrorBoundary>
    </Layout>
  )
}

export default AppointmentPageWrapper
