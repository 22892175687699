/* Copyright 2013 - 2024 Waiterio LLC */
function AndroidToast(options) {
  let position
  this.timeout_id = null
  this.duration = 3000
  this.content = ''
  this.position = 'bottom'

  if (!options || typeof options !== 'object') {
    return false
  }

  if (options.duration) {
    this.duration = parseFloat(options.duration)
  }
  if (options.content) {
    this.content = options.content
  }

  if (options.position) {
    position = options.position.toLowerCase()
    if (position === 'top' || position === 'bottom') {
      this.position = position
    } else {
      this.position = 'bottom'
    }
  }
  this.show()
}

AndroidToast.prototype.show = function show() {
  if (!this.content) {
    return false
  }
  clearTimeout(this.timeout_id)

  const body = document.getElementsByTagName('body')[0]

  const previousToast = document.getElementById('android_toast_container')
  if (previousToast) {
    body.removeChild(previousToast)
  }

  let classes = 'android_toast_fadein'
  if (this.position === 'top') {
    classes = 'android_toast_fadein android_toast_top'
  }

  const toastContainer = document.createElement('div')
  toastContainer.setAttribute('id', 'android_toast_container')
  toastContainer.setAttribute('class', classes)
  body.appendChild(toastContainer)

  const toast = document.createElement('div')
  toast.setAttribute('id', 'android_toast')
  toast.innerHTML = this.content
  toastContainer.appendChild(toast)

  this.timeout_id = setTimeout(this.hide, this.duration)
  return true
}

AndroidToast.prototype.hide = function hide() {
  const toastContainer = document.getElementById('android_toast_container')

  if (!toastContainer) {
    return false
  }

  clearTimeout(this.timeout_id)

  toastContainer.className += ' android_toast_fadeout'

  function removeToast() {
    const toastContainer = document.getElementById('android_toast_container')
    if (!toastContainer) {
      return false
    }
    toastContainer.parentNode.removeChild(toastContainer)
  }

  toastContainer.addEventListener('webkitAnimationEnd', removeToast)
  toastContainer.addEventListener('animationEnd', removeToast)
  toastContainer.addEventListener('msAnimationEnd', removeToast)
  toastContainer.addEventListener('oAnimationEnd', removeToast)

  return true
}

export const toast = message => {
  let content = message

  content = content && content.replace(new RegExp('\\n', 'g'), '<br>')

  return new AndroidToast({ content })
}

export const toastError = error => {
  console.error(error)
  let message = error?.toString?.()
  message = message?.length ? message : 'An error occurred'
  message += error?.cause?.message ? `: ${error.cause.message}` : ''

  return toast(message)
}

export default AndroidToast
