/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import IconAdd from '@stiloso/icons/IconAdd.js'
import Button from './Button.js'

const AppBarButton = ({ disabled, label, to, onClick, appearance, Icon }) => {
  Icon = Icon || IconAdd
  let iconColor = appearance === 'secondary' ? 'var(--color-base)' : 'white'

  return (
    <Button
      appearance={appearance}
      to={!disabled ? to : null}
      onClick={!disabled ? onClick : null}
      css={{
        margin: 'auto',
      }}
    >
      <Icon
        css={{
          fill: iconColor,
          height: 24,
          width: 24,
          marginInlineEnd: 4,
          verticalAlign: 'middle',
        }}
      />
      {label}
    </Button>
  )
}

export default AppBarButton
