/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import getUserId from '@monorepo/session/getUserId.js'
import { useTranslation } from '@multilocale/react/index.js'
import Drawer from '@stiloso/components/Drawer.js'
import DrawerButton from '@stiloso/components/DrawerButton.js'
import DrawerTitle from '@stiloso/components/DrawerTitle.js'
import IconPowerOff from '@stiloso/icons/IconPowerOff.js'
import IconUser from '@stiloso/icons/IconUser.js'
import NoInternetConnection from './NoInternetConnection.js'
import useUser from '../hooks/useUser.js'

const LayoutDesktop = ({ children, pages }) => {
  const { t } = useTranslation()
  let user = useUser(getUserId())
  user ||= {}

  let fullName = ''

  if (user.firstName && user.lastName) {
    fullName = `${user.firstName} ${user.lastName}`
  }

  let accountPage = pages.find(({ to }) => to === '/account')
  pages = pages.filter(({ to }) => to !== '/account')

  return (
    <div
      css={{
        display: 'grid',
        height: '100%',
        gridTemplateColumns: '200px auto',
        gridTemplateAreas: `
          'drawer children'
        `,
      }}
    >
      <Drawer>
        <DrawerTitle>{t('Dodo Dentist')}</DrawerTitle>
        {pages.map(props => (
          <DrawerButton key={props.to} {...props} />
        ))}
        <div css={{ flexGrow: 1 }} />
        <DrawerButton
          key="/account"
          label={fullName || t('Account')}
          to="/account"
          Icon={IconUser}
          avatarUrl={user.avatarUrl}
          selected={accountPage?.selected}
        />
        <DrawerButton
          key="/logout"
          label={t('Logout')}
          to="/logout"
          Icon={IconPowerOff}
        />
      </Drawer>
      <div
        css={{
          gridArea: 'children',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          background: 'var(--color-background)',
          overflowX: 'hidden',
        }}
      >
        <NoInternetConnection />
        {children}
      </div>
    </div>
  )
}

export default LayoutDesktop
