/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  flag: '🇳🇬',
  countryName: 'Nigeria',
  phonePrefix: '+234',
  administrativeDivisionsType: 'state',
  countryCode: 'NG',
  languages: ['en'],
  zipcodePattern: '\\d{6}',
}
