/* Copyright 2013 - 2024 Waiterio LLC */
import getAccessTokenPayload from './getAccessTokenPayload.js'

export default function getUserId() {
  let organizationId
  let payload = getAccessTokenPayload()

  if (payload) {
    organizationId = payload?.metadata?.roles?.[0]?.organizationId
  }

  return organizationId
}
