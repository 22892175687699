/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { Suspense } from 'react'
import { css, jsx } from '@emotion/react'
import { Link, useParams } from 'react-router-dom'
import { format, formatISO, startOfToday } from 'date-fns'
import getUserId from '@monorepo/session/getUserId.js'
import useLoggedInSession from '@monorepo/session/useLoggedInSession.js'
import { useTranslation } from '@multilocale/react/index.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarButton from '@stiloso/components/AppBarButton.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import ErrorBoundary from '@stiloso/components/ErrorBoundary.js'
import Spinner from '@stiloso/components/Spinner.js'
import card from '@stiloso/styles/card.js'
import clickable from '@stiloso/styles/clickable.js'
import section from '@stiloso/styles/section.js'
import Layout from '../components/Layout.js'
import useAppointments from '../hooks/useAppointments.js'
import useClinic from '../hooks/useClinic.js'
import usePatients from '../hooks/usePatients.js'
import useUser from '../hooks/useUser.js'

let appointmentCard = css([
  clickable,
  card,
  {
    lineHeight: '48px',
    width: '100%',
    marginTop: 16,
    padding: '0 16',
    display: 'flex',
    gap: 8,
  },
])

export const paths = ['/clinics/:clinicId/appointments']

const AppointmentsPage = props => {
  let { clinicId, days } = props

  return (
    <div
      css={{
        margin: '16 0',
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      {days?.map(({ label, appointments }, i) => (
        <div key={label} css={{ width: '100%' }}>
          <div css={{ paddingTop: i > 0 ? 16 : 0 }}>{label}</div>
          {appointments?.map(
            ({ _id, startTime, patient, note, procedures }) => (
              <Link
                key={_id}
                css={appointmentCard}
                to={`/clinics/${clinicId}/appointments/${_id}`}
              >
                <span css={{ flexGrow: 1 }}>
                  {format(startTime, 'HH:mm')}&nbsp;&nbsp;{patient?.name || ''}
                </span>
                {note && <span>{note}</span>}
                {procedures?.map(({ _id, name }) => (
                  <span key={_id}>{name}</span>
                ))}
              </Link>
            ),
          )}
        </div>
      ))}
    </div>
  )
}

const AppointmentsPageConnected = () => {
  useLoggedInSession()
  const { t } = useTranslation()
  const { clinicId } = useParams()
  let startTime = formatISO(startOfToday())
  let clinic = useClinic(clinicId)
  let appointments = useAppointments({
    clinicId: clinic._id,
  })

  // console.log('appointments', appointments)

  appointments = appointments?.filter(
    appointment => appointment.startTime >= startTime,
  )

  let user = useUser(getUserId())

  // console.log(patientsIds)

  let patients = usePatients({
    clinicId: clinic._id,
  })

  // console.log({ patients })

  let patientsIds = Object.keys(
    appointments?.reduce((patientsIds, appointment) => {
      if (appointment.patientId) {
        patientsIds[appointment.patientId] = true
      }

      return patientsIds
    }, {}) ?? {},
  )

  patients = patients?.filter(patient => patientsIds.includes(patient._id))

  let ids2patients = patients?.reduce((ids2patients, patient) => {
    ids2patients[patient._id] = patient

    return ids2patients
  }, {})

  appointments = appointments?.sort((a, b) =>
    a.startTime > b.startTime ? 1 : -1,
  )

  appointments = appointments?.map(appointment => ({
    ...appointment,
    patient: ids2patients[appointment.patientId],
  }))

  // console.log({ appointments })

  let days2appointments =
    appointments?.reduce((days2appointments, appointment) => {
      let day = appointment.startTime.split('T')[0]

      if (!days2appointments[day]) {
        days2appointments[day] = []
      }

      days2appointments[day].push(appointment)

      return days2appointments
    }, {}) ?? {}

  let today = new Date().toISOString().split('T')[0]
  let tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
    .toISOString()
    .split('T')[0]

  let days = Object.keys(days2appointments)
    .sort()
    .map(day => {
      let label = new Date(day).toLocaleDateString(user?.language || 'en', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
      })

      if (day === today) {
        label = t('Today') + ', ' + label
      } else if (day === tomorrow) {
        label = t('Tomorrow') + ', ' + label
      }

      return { label, appointments: days2appointments[day] }
    })
  // console.log(days)

  let title = t('Appointments')
  title = appointments.length > 1 ? `${title} (${appointments.length})` : title

  return (
    <div css={section}>
      <AppBar>
        <AppBarTitle>{title}</AppBarTitle>
        <div css={{ flexGrow: 1 }} />
        <AppBarButton
          label={t('New appointment')}
          to={`/clinics/${clinicId}/appointments/new`}
        />
      </AppBar>
      <AppointmentsPage clinicId={clinicId} days={days} />
    </div>
  )
}

const AppointmentsPageWrapper = () => {
  const { t } = useTranslation()

  return (
    <Layout title={t('Appointments')}>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <AppointmentsPageConnected />
        </Suspense>
      </ErrorBoundary>
    </Layout>
  )
}

export default AppointmentsPageWrapper
