/* Copyright 2013 - 2024 Waiterio LLC */
import getClass from '@monorepo/client/getClass.js'
import getUserId from '@monorepo/session/getUserId.js'
import nativeVersion from '@monorepo/shared/device/nativeVersion.js'
import preferCapitalizedEachWord from '@monorepo/shared/preferCapitalizedEachWord.js'
import User from '@dododentist/model/User.js'
import domainProduction from '@dododentist/shared/domainProduction.js'
import webappVersion from './version.js'

export default function identify() {
  const userId = getUserId()

  if (typeof window !== 'undefined' && userId) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'login',
      userId,
    })

    getClass(User, userId).then(user => {
      window.$globo = window.$globo || []
      window.$globo.push(['showChat'])
      window.$globo.push(['setUserId', userId])

      if (user.email) {
        window.$globo.push(['setUserEmail', user.email])

        let nickname = (user?.firstName || '') + ' ' + (user?.lastName || '')
        nickname = nickname.trim()
        if (!nickname) {
          nickname = user.email
          nickname = nickname.split('@')[0] // eslint-disable-line prefer-destructuring
          nickname = nickname.replaceAll('.', ' ')
          nickname = preferCapitalizedEachWord(nickname)
        }

        nickname = nickname || user.email

        nickname = nickname.trim()

        window.$globo.push(['setUserNickname', nickname])
        window.$globo.push([
          'setMetadata',
          'analytics',
          `https://analytics.${domainProduction}/users/${userId}`,
        ])

        window.$globo.push([
          'setMetadata',
          'posthog',
          `https://app.posthog.com/person/${userId}#activeTab=sessionRecordings`,
        ])

        window.posthog?.identify(userId, {
          email: user.email,
          name: nickname,
          analytics: `https://analytics.${domainProduction}/users/${userId}`,
          nativeVersion,
          webappVersion,
        })

        window.metricsmage?.identify(userId, {
          email: user.email,
          name: nickname,
          analytics: `https://analytics.${domainProduction}/users/${userId}`,
          nativeVersion,
          webappVersion,
        })
      }
    })
  }
}
