/* Copyright 2013 - 2024 Waiterio LLC */
import post from '@monorepo/client/post.js'

const sendTestReminder = async body =>
  post({
    url: 'croncool/test-reminder',
    body,
  })

export default sendTestReminder
